<template>
  <div class="wrapper">
    <Sidebar  />

    <div id="content">
      <NavBar />
      <div >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/layout/NavBar.vue'
import Sidebar from './components/layout/Sidebar.vue'
import {CheckSession} from '@/actions/userActions.js'
import { useCookies } from "vue3-cookies"
import 'dotenv/config'

export default {
  name: 'App',
  components: {
    NavBar,
    Sidebar
  },
  setup(){
    const { cookies } = useCookies()
    if(cookies.get('_auzer')){
      CheckSession().then((res)=>{
        if(!res){
          cookies.set('_redirreq', window.location.pathname ,'15min','/','',true,'Strict')
          cookies.remove('_auzer')
          window.location.href = process.env.VUE_APP_REST_MAIN_API + "/api/auth/sso?origin="+window.location.origin
        }
      })
    }
  }
}
</script>

<style>
  /* @import "https://test-my-bucket-ph.s3.ap-east-1.amazonaws.com/custom.css"; */
</style>