<template>
    <div class="relative">
        <div class="card shadow-sm card-hover card-round" @click="GoToProject(project._id)">
            <div class="card-body p-3 ps-4 pe-4">
                <div class="row">
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <div class="d-flex align-items-center">
                            <img class="me-3 img-semi-circle" height="48"   src="https://www.mmoseronelink.com/wp-content/uploads/2019/03/mmoser-icon.png" alt="Card image cap">
                            <div class="pt-2 ">
                                <div><span class="fw-bold">{{project.projectNumber}}  </span></div>
                                <div><span class="truncate-text" :title="project.projectName">{{project.projectName}}  </span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <div class="d-flex justify-content-end">
                            <div class="relative">
                                <DoughnutChart :chartData="doughnutData" :options="options" :width="60"  ></DoughnutChart>
                                <div class="absolute-center text-center">
                                    <p class="fw-bold pt-3"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="absolute-top-right text-center">
            <span class="badge p-1 ps-2 pe-2 bg-warning to-circle">1</span>
        </div> -->
    </div>
</template>
<script>
import dayJs from 'dayjs'
import { stageIcons,statusIcons } from '../helpers/utilities.js'
import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"

Chart.register(...registerables)
export default {
    name : 'ProjectItem',
    components : {
        DoughnutChart
    },
    props :{
        project : Object,
        OpenUpdate : Function,
        UpdateProjectStatus : Function
    },
    data(){
        return {
            createdAt : '',
            stageIcon : {},
            statusIcon : {},
            percent : 0,
            doughnutData : {
                labels: ['Progress', 'Remaining'],
                datasets: [
                    {
                        data: [10, 90],
                        backgroundColor: ['#0265AA' , '#C0D8EA'], 
                    },
                ],
            },
            options : {
                plugins : {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                layout: {
                    padding: 0
                },
                cutout : '70%'
            }
        }
    },
    mounted : function(){
        const self = this
        self.$nextTick(function () {
            self.changeValues(self.project)
        })

        const maxStage = stageIcons.length
        const stage = self.project.stage
        const percent = Math.round((stage/maxStage )*100)
        self.percent = percent
        if(percent!=100){
            self.doughnutData.datasets =  [
                {
                    data: [percent, 100 - percent],
                    backgroundColor: ['#0265AA' , '#C0D8EA'], 
                },
            ]
        }else{
            self.doughnutData.datasets =  [
                {
                    data: [100],
                    backgroundColor: ['#629A00'],
                },
            ]
        }
        
    },
    methods : {
        changeValues(project){
            this.createdAt = dayJs(project.createdAt).format("MMMM DD, YYYY")
            this.stageIcon = stageIcons[project.stage -1]
            this.statusIcon = statusIcons[project.status]
        },
        GoToProject(id){
            this.$router.push(`/project/${id}`)
        }
        
    }
}
</script>
<style scoped>

.image-fix {
    object-fit: cover;
    height: 150px;
    background-position: 50% 50%;
}
.w-full{
    width: 100% !important;
}
.card-hover:hover { 
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.card-hover{
    cursor: pointer;
    overflow-wrap: break-word;
}
.truncate-text {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relative {
  position: relative;
}

.absolute-center {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-top-right {
  position:absolute;
  top: 0;
  left: 99%;
  transform: translate(-50%, -50%);
}

.relative.absolute-center p {
  font-size: 8px;
}

.to-circle { 
    border-radius: 40%;
}

</style>
