<template>
  <div class="text-center">
    <img src="/favicon.png" class="">
    <h3>You have signed out, Thank you for using Commercial Management App</h3>
    <button class="btn btn-outline-primary" @click="LogIn">
        Log In
    </button>
  </div>
</template>


<script>

import { useCookies } from "vue3-cookies"
export default {
    name : 'SignOut',
    mounted (){
        const { cookies } = useCookies()
        if(this.signoutParam=='yes'){
            cookies.remove('_auzer')
        }
    },
    data(){
        return {
            signoutParam : this.$route.query.signout
        }
    },
    methods : {
        LogIn(){
            this.$router.push({name : 'Home'})
        }
    }
}
</script>
