<template>
    <input type="search"
    @keyup="KeyUp"
    @keydown="KeyUp"
    :id="elemId" 
    class="form-control"
    :placeholder="placeholder" 
    autocomplete="off"
    autocorrect="off"
    :disabled="disabled" 
    spellcheck="false"/>

</template>

<script>
import datepicker from 'js-datepicker/dist/datepicker.min.js'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    mounted(){
        let currentDate = this.modelValue ? new Date(this.modelValue) : undefined
        this.datePickerComponent = datepicker(`#${this.elemId}`,
        {
            dateSelected: currentDate,
            formatter: (input, date) => {
                input.value = ReformatDate(date)
            },
            onSelect : (instance,date) =>{
                this.$emit('update:modelValue', ReformatDate(date, 'YYYY-MM-DD'))
            },
            noWeekends: true,
            customDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        })
    },
    methods : {
        KeyUp(e){
            e.preventDefault()
            return
        }
    },
    watch : {
        modelValue(val){
            if(!val){
                this.datePickerComponent.setDate()
                this.$emit('update:modelValue', '')
            }else{
                this.datePickerComponent.setDate(new Date(val))
                this.datePickerComponent.navigate(new Date(val))
                this.$emit('update:modelValue', val)
            }
            
        }
    },
    props: {
        modelValue : {
            type : String,
        },
        elemId : {
            type : String,
        },
        placeholder : {
            type : String,
            default : 'Please Select a Date'
        },
        disabled : {
            type : Boolean,
            default : false
        },
    },
    data (){
        return {
            dateValue : '',
            datePickerComponent : null
        }
    }
}
</script>