<template>
    <div  class="p-2">
        <button class="btn btn-primary me-5">primary</button>
        <button class="btn btn-warning me-5">warning</button>
        <button class="btn btn-danger me-5">danger</button>
        <button class="btn btn-info me-5">info</button>
        <button class="btn btn-success me-5">success</button>
        <button class="btn btn-light me-5">light</button>
    </div>
    <div  class="p-2">
        <button class="btn btn-outline-primary me-5">primary</button>
        <button class="btn btn-outline-warning me-5">warning</button>
        <button class="btn btn-outline-danger me-5">danger</button>
        <button class="btn btn-outline-info me-5">info</button>
        <button class="btn btn-outline-success me-5">success</button>
        <button class="btn btn-outline-light me-5">light</button>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <input type="text" class="form-control">
                </div>
                <div class="col-md-12 pt-3">
                    <select class="form-select">
                        <option>test option 1</option>
                        <option>test option 2</option>
                        <option>test option 3</option>
                    </select>
                </div>
                <div class="col-md-12 pt-3">
                    <div class="badge bg-primary me-3">Primary</div>
                    <div class="badge bg-warning me-3">warning</div>
                    <div class="badge bg-danger me-3">danger</div>
                    <div class="badge bg-info me-3">info</div>
                    <div class="badge bg-success me-3">success</div>
                </div>
                <div class="col-md-12 pt-3">
                    <div class="alert alert-primary d-flex align-items-center" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a primary alert
                        </div>
                    </div>
                    <div class="alert alert-warning d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a warning alert
                        </div>
                    </div>
                    <div class="alert alert-danger d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a danger alert
                        </div>
                    </div>
                    <div class="alert alert-info d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a info alert
                        </div>
                    </div>
                    <div class="alert alert-success d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a success alert
                        </div>
                    </div>
                </div>
                <div class="col-md-12 pt-3">
                    <div class="alert alert-outline-primary d-flex align-items-center" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a primary alert
                        </div>
                    </div>
                    <div class="alert alert-outline-warning d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a warning alert
                        </div>
                    </div>
                    <div class="alert alert-outline-danger d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a danger alert
                        </div>
                    </div>
                    <div class="alert alert-outline-info d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a info alert
                        </div>
                    </div>
                    <div class="alert alert-outline-success d-flex align-items-center pt-3" role="alert">
                        <i class="fas fa-info-circle fa-2x me-2"></i>  
                        <div>
                            This is a success alert
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive p-3">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Col 1</th>
                        <th>Col 2</th>
                        <th>Col 3</th>
                        <th>Col 4</th>
                        <th>Col 5</th>
                        <th>Col 6</th>
                        <th>Col 7</th>
                        <th>Col 8</th>
                        <th>Col 9</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive p-3">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Col 1</th>
                        <th>Col 2</th>
                        <th>Col 3</th>
                        <th>Col 4</th>
                        <th>Col 5</th>
                        <th>Col 6</th>
                        <th>Col 7</th>
                        <th>Col 8</th>
                        <th>Col 9</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive p-3">
            <table class="table table-striped table-bordered table-header-solid">
                <thead>
                    <tr>
                        <th>Col 1</th>
                        <th>Col 2</th>
                        <th>Col 3</th>
                        <th>Col 4</th>
                        <th>Col 5</th>
                        <th>Col 6</th>
                        <th>Col 7</th>
                        <th>Col 8</th>
                        <th>Col 9</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                    <tr>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                        <td>row 1 col 1</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="m-3">
            <div class="card border-primary mb-1">
                <div class="card-header bg-primary text-white">
                    <h4>Header - border primary</h4>
                </div>
                <div class="card-body">
                    <p>this s the body asdasdasdasdasdasdasdasdasdasdasdsd</p>
                </div>
            </div>
            <div class="card border-warning mb-1">
                <div class="card-header">
                    <h4>Header - border warning</h4>
                </div>
                <div class="card-body">
                    <p>this s the body asdasdasdasdasdasdasdasdasdasdasdsd</p>
                </div>
            </div>
            <div class="card border-danger mb-1">
                <div class="card-header">
                    <h4>Header - border danger</h4>
                </div>
                <div class="card-body">
                    <p>this s the body asdasdasdasdasdasdasdasdasdasdasdsd</p>
                </div>
            </div>
            <div class="card border-info mb-1">
                <div class="card-header">
                    <h4>Header - border info</h4>
                </div>
                <div class="card-body">
                    <p>this s the body asdasdasdasdasdasdasdasdasdasdasdsd</p>
                </div>
            </div>
            <div class="card border-success mb-1">
                <div class="card-header">
                    <h4>Header - border success</h4>
                </div>
                <div class="card-body">
                    <p>this s the body asdasdasdasdasdasdasdasdasdasdasdsd</p>
                </div>
            </div>

        </div>
    </div>

    
    
</template>

<script>
export default {
    name : 'Design'
}
</script>

