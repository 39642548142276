import {mapGetters, mapActions} from 'vuex'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import StepItem2 from '@/components/layout/StepItem2.vue'
import Pagination from '@/components/layout/Pagination.vue'
import { LinkList, ReformatDate,ProcessStageTypes, ModuleActionList } from '@/helpers/utilities.js'
import {GetProjectProcessList ,CreateProjectProcess, UpdateProjectProcess} from '@/actions/projectActions.js'
import 'dotenv/config'
export default {
    name : 'ProcessList',
    components : {
        Loader,
        StepItem2,
        Pagination,
        BreadcrumbsProject,
        Modal,
    },
    data(){
        return{
            loaderProject: true,
            processList : {
                docs: [],
                totalPages : 0,
                totalDocs : 0
            },
            projectId : this.$route.params.id, 
            paginationParams : {
                id : this.$route.params.id, 
                page : 1
            },
            newProcess :{
                id : this.$route.params.id,
                type : '',
                preDefined : 1,
            },
            updateProcess :{
                id : this.$route.params.id,
            },
            linkProcess :{
                id : this.$route.params.id,
            },
            saveClicked : false,
            updateClicked : false,
            ModuleActionList : ModuleActionList
        }
    },
    methods :{
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
        PageChange(page){
            const self = this
            self.paginationParams.page = page
            GetProjectProcessList(self.paginationParams).then((res)=>{
                self.processList = res
            }).catch()
        },
        FormatDate(date){
            return ReformatDate(date)
        },
        GetProjectType(type){
            return ProcessStageTypes[type - 1]
        },
        OpenModalCreate(){
            this.newProcess.processName = ''
            this.newProcess.type = ''
        },
        OpenModalUpdate(data){
            this.updateProcess.processName = data.processName
            this.updateProcess.status = data.status
            this.updateProcess.processId = data._id
        },
        OpenModalLink(data){
            this.linkProcess.processName = data.processName
            this.linkProcess.processId = data._id
        },
        CreateProcessRun(e){
            const btn = e.target
            const self = this

            self.saveClicked = true

            if(!self.newProcess.processName){
                return
            }

            if(!self.newProcess.type){
                return
            }

            self.saveClicked = false
            btn.disabled = true
            CreateProjectProcess(self.newProcess).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeProcessModal.click()
                    self.loaderProject = true
                    GetProjectProcessList(self.paginationParams).then((res)=>{
                        self.loaderProject = false
                        self.processList = res
                    }).catch()
                }else{
                    self.$swal('Create Process',res,'warning')
                }
            }).catch()
        },
        UpdateProcessRun(e){
            const btn = e.target
            const self = this

            self.updateClicked = true

            if(!self.updateProcess.processName){
                return
            }

            if(self.updateProcess.status==undefined){
                return
            }

            self.updateClicked = false
            btn.disabled = true
            UpdateProjectProcess(self.updateProcess).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    const getDocs = self.processList.docs
                    const getIndex = getDocs.findIndex(u=>u._id==self.updateProcess.processId)
                    if(getIndex!=-1){
                        self.processList.docs[getIndex].processName = self.updateProcess.processName
                        self.processList.docs[getIndex].status = JSON.parse(self.updateProcess.status)
                    }
                    self.$refs.closeUpdateProcessModal.click()
                    btn.disabled = false
                }else{
                    self.$swal('Update Process',res,'warning')
                }
            }).catch()
        }
    },
    computed : mapGetters(['projectInfo','permission']),
    mounted :async function () {
        const self = this
        self.ChangeActiveLink(LinkList.PROJECT_PROCESS)
        const projectId = self.$route.params.id
        self.loaderProject = true
        await self.GetProjectInfo(projectId).catch()
        const data = await GetProjectProcessList({id : projectId, page : 1}).catch()
        if(data==401){
            self.$router.push('/unauthorized')
            return
        }
        self.processList = data
        self.loaderProject = false
        
    }
}