<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : $t('Projects'),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id  + '/actionables',
                title : $t('Actionables'),
                isRouterLink: true
            },
        ]"
        ></BreadcrumbsProject>

        <div class="container">
            <div class="text-center pt-3">
                <h5 class="fw-bold">{{$t('Actionables')}}</h5>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-white" v-for="(item, key) in data" :key="key">
                <div :class="{'text-success': item.status}">
                    <i class="fa-solid fa-square-check " v-if="item.status"></i> <small :class="{'strikethrough': item.status}" v-html="item.message"></small>
                </div>
            </div>
            <!-- <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>There was an approval/review for the quotation <b>QTN0001 Head Contract</b> sent last <b>12-July-2023</b>. It will be ideal
                        for you to take action for this item before <b>14-July-2023</b> to avoid delays. Click <a href="#">here</a> to review/approve the quotation.</small>
                    </div>
                    <div class="col-md-2 text-end">
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-light " style="pointer-events:none;">
                <div class="row text-success">
                    <div class="col-md-10">
                        <i class="fa-solid fa-square-check "></i> <small>A quotation was sent to the client last <b>13-July-2023</b> for review/approval but no action was taken yet by the client.
                            You can send a reminder to the client through this <a href="#">link</a>.
                        </small>
                    </div>
                    <div class="col-md-2 text-end">
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-white" >
                <div class="row">
                    <div class="col-md-10">
                        <small>There are <b>10</b> awarded trade packages that needs to be purchased. Please click <a href="#">here</a> to start creating purchase orders. </small>
                    </div>
                    <div class="col-md-2 text-end">
                    </div>
                </div>
            </div> -->
            
        </div>
    </div>
</template>
<style scoped>
.strikethrough {
  text-decoration: line-through;
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex'
import {LinkList} from '@/helpers/utilities.js'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
import {DashboardGetProjectActionablesFirstPart,DashboardGetProjectActionables} from '@/actions/projectActions.js'
export default {
    name : 'NotificationList',
    components : {
        BreadcrumbsProject
    },
    computed : {
        ...mapGetters(['projectInfo'])
    },
    methods : {
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
    },
    mounted: async function(){
        const self = this
        self.ChangeActiveLink(LinkList.SUMMARY)
        await self.GetProjectInfo(self.projectId)
        const getData = await DashboardGetProjectActionablesFirstPart(self.projectId)
        let data = getData.messages

        if(getData.cleared){
            const getDotNetData = await DashboardGetProjectActionables(self.projectId)
            if(getDotNetData)
                data.push(...getDotNetData.data)
        }
        self.data = data.reverse()
    },
    data(){
        return {
            data : [],
            projectId : this.$route.params.id
        }
    }
}
</script>