<template>
    <div class="card card-round shadow ">
        <div class="card-header bg-dark text-white">
                <h6 class="fw-bold mb-0"><i class="fa-solid fa-receipt"></i> {{ $t('Client Invoices Log') }}</h6>
            </div>
        <div class="card-body p-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>{{ $t('S/N') }}</th>
                        <th>{{ $t('Invoice No.') }}</th>
                        <th>{{ $t('Date') }}</th>
                        <th class="text-end">{{ $t('Submitted Amount') }}</th>
                        <th>{{ $t('Status')}}</th> 
                        <th class="text-end">{{ $t('Amount') }} {{ $t('Paid') }}</th>
                        <th >{{ $t('Attachment') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="data.length==0">
                        <td colspan="7" class="text-center">{{ $t('No data Found') }}</td>
                    </tr>
                    <tr v-for="(item,key) in data" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td><a :href="dotNetUrl + '/clientinvoice/item/' + item.id" class="link-style-custom">{{ item.invoiceNumber || 'SINXXXX' }}</a></td>
                        <td>{{ ReformatDate(item.invoiceDate) }}</td>
                        <td  class="text-end">{{projectInfo.currencyId?.sign}}{{ ReformatNumber(item.amount) }}</td>
                        <td> <span :class="color[item.status - 1]">{{ item.statusName }}</span> </td>
                        <td  class="text-end">
                            <span v-if="item.status==7">{{projectInfo.currencyId?.sign}}{{ ReformatNumber(item.amount) }}</span>
                            <span v-else>-</span>
                        </td>
                        <td><a :href="dotNetUrl + '/clientinvoice/item/' + item.id" class="link-style-custom">{{ $t('Open') }}</a></td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center"> <a  :href="dotNetUrl + '/clientinvoice/' + projectInfo.projectOldId" class="link-style-custom">{{ $t('View All') }}</a></div>
        </div>
    </div>
</template>

<script>
import {DashboardGetProjectClientInvoice} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate} from '../../helpers/utilities.js'
import 'dotenv/config'
export default {
    name : 'ClientInvoiceList',
    props : ['projectInfo'],
    data(){
        return {
            data : [],
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            color : ['text-muted','text-primary','text-warning','text-success','text-primary','text-success','text-success']
        }
    },
    mounted : async function(){
        const self = this
        const response = await DashboardGetProjectClientInvoice(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,ReformatDate
    }
    
} 
</script>