<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : 'Projects',
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id + '/process' ,
                title : 'Process List',
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id + '/process/' + processId ,
                title : processItem.processName || '-',
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div class="" >
        <div>
            <div class="card">
                <div class="card-header bg-white">
                    <h6 class="fw-bold">Preview</h6>
                </div> 
                <div class="card-body bg-transparent">
                    <ProcessViewStepListPreview
                    v-if="processItem.type==ProcessStepType.VIEW"
                    :stepList="processItem.stepList"
                    :onClickStep="onClickStep"
                    :relatedList="processItem.relatedList"
                    />
                    <ProcessApprovalStepListPreview
                    v-if="processItem.type==ProcessStepType.APPROVAL"
                    :stepList="processItem.stepList"
                    :onClickStep="onClickStep"
                    :relatedList="processItem.relatedList"
                    />
                </div> 
            </div>
        </div>
        <div class="pt-2">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <h6 class="fw-bold ">Process Items</h6>
                        </div>
                        <div class="d-flex justify-content-end" v-if="processItem.type==1">
                            <button class="btn btn-primary"
                            data-bs-toggle="modal" data-bs-target="#ProccessItemAddModalView"
                            @click="()=>{ this.newStepView.relatedUserId = ''}"
                            ><i class="fa-solid fa-plus"></i> Add Related User</button>
                        </div>
                        <div class="d-flex justify-content-end" v-if="processItem.type==2">
                            <button class="btn btn-primary"
                            data-bs-toggle="modal" data-bs-target="#ProccessItemAddModal"
                            @click="()=>{ this.newStep.roleId = ''}"
                            ><i class="fa-solid fa-plus"></i> Add Step</button>
                        </div>
                        
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-reponsive" >
                        <table class="table table-striped" v-if="processItem.type==1">
                            <thead> 
                                <tr>
                                    <th>Viewer Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="processItem.relatedList.length==0">
                                    <td colspan="2" class="text-center">No Data Found</td>
                                </tr>
                                <tr v-for="(child,index) in processItem.relatedList" :key="index">
                                    <td class="text-capitalize">{{child.relatedUserId.fullname}}</td>
                                    <td>
                                        <a role="button" class="text-warning ps-1"
                                        @click="OpenRemoveUser(child.relatedUserId)"
                                        >
                                            <i class="fa-solid fa-minus"></i> Remove
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped" v-if="processItem.type==2">
                            <thead> 
                                <tr>
                                    <th>Step Name</th>
                                    <th>Order</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="processItem.stepList.length==0">
                                    <td colspan="3" class="text-center">No Data Found</td>
                                </tr>
                                <template v-for="(item, key) in  processItem.stepList" :key="key">
                                    <tr role="button"  @click="OpenStepList(key)">
                                        <td>{{item.roleId.roleName}}</td>
                                        <td>{{item.order}}</td>
                                        <td > 
                                            <a role="button" class="text-primary ps-1"
                                            data-bs-toggle="modal" data-bs-target="#ProccessItemOrderModal"
                                            @click.stop="OpenChangeOrderModal(item)"
                                            >
                                                <i class="fa-solid fa-plus"></i> Change Order
                                            </a>
                                            <a role="button" class="text-warning ps-1" @click.stop="OpenRemoveStep(item.roleId)">
                                                <i class="fa-solid fa-minus"></i> Remove Step
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="openTrList==key">
                                        <td colspan="3">
                                            <table class="table ">
                                                <thead>
                                                    <tr>
                                                        <th >Name</th>
                                                        <th >
                                                            <div class="d-flex justify-content-end">
                                                                <a role="button" class="text-primary"
                                                                data-bs-toggle="modal" data-bs-target="#ProccessItemUserModal"
                                                                @click="OpenNewRelatedUserModal(item.roleId)"
                                                                >
                                                                    <i class="fa-solid fa-plus"></i> Add User to Process
                                                                </a>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="processItem.relatedList.filter(u=>u.roleId==item.roleId._id).length==0">
                                                        <td colspan="2" class="text-center">No Data Found</td>
                                                    </tr>
                                                    <tr v-for="(child,index) in processItem.relatedList.filter(u=>u.roleId==item.roleId._id)" :key="index">
                                                        <td class="text-capitalize">{{child.relatedUserId.fullname}}</td>
                                                        <td>
                                                            <a role="button" class="text-warning ps-1"
                                                            @click="OpenRemoveUser(child.relatedUserId)"
                                                            >
                                                                <i class="fa-solid fa-minus"></i> Remove
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
         
        <Loader v-if="loaderProject"></Loader>
    </div>

    <Modal idModal="ProccessItemAddModal" headerTitle="Add Process Step"  >
        <form>
            <div class="form-group">
                <label class="required-style">Role Name </label>
                <select class="form-select" v-model="newStep.roleId" >
                    <option value="">Please Select</option>
                    <option :value="item._id" v-for="(item,key) in roles" :key="key">{{item.roleName}}</option>
                </select>
                <small class="text-warning" v-if="!newStep.roleId&&newStepClicked==true">Required</small>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeProcessAddItemModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="CreateNewStep"><i class="fa-solid fa-save"></i> Add Stage</button>
      </template>
    </Modal>

    <Modal idModal="ProccessItemAddModalView" headerTitle="Add User to Process"  >
        <form>
            <div class="form-group">
                <label class="required-style">User </label>
                <Select2 
                :filterQuery="GetUserFromDirectory"
                indexValue="_id"
                labelName="fullname"
                placeHolder="Search User From Project"
                v-model:optionValue="newStepView.relatedUserId"
                :onOptionClick="AppendRelatedUser"
                ></Select2>
                <small class="text-warning" v-if="!newStepView.relatedUserId">Required</small>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeItemAddModalView" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="AddNewUserToProcess"><i class="fa-solid fa-save"></i> Save Changes</button> 
      </template>
    </Modal>

    <Modal idModal="ProccessItemUserModal" headerTitle="Add User to Process"  >
        <form>
            <div class="form-group">
                <label class="required-style">User </label>
                <Select2 
                ref="select2UserFromDirectory"
                :filterQuery="GetUserFromDirectory"
                indexValue="_id"
                labelName="fullname"
                placeHolder="Search User From Project"
                v-model:optionValue="newUserToStep.relatedUserId"
                ></Select2>
                <small class="text-warning" v-if="!newUserToStep.relatedUserId&&newUserStepClicked">Required</small>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeStepUserModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="AddNewUserToStep"><i class="fa-solid fa-save"></i> Save Changes</button>
      </template>
    </Modal>

    <Modal idModal="ProccessItemOrderModal" headerTitle="Change Process Order"  >
        <form>
            <div class="form-group">
                <label>Order</label>
                <select class="form-select" v-model="orderStep.order" >
                    <option v-for="(item, key) in processItem.stepList" :key="key">{{key+1}}</option>
                </select>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeStepOrderModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="ChangeStepOrderStep"><i class="fa-solid fa-save"></i> Save Changes</button>
      </template>
    </Modal>
</template>

<script src="@/assets/projectJs/ProcessSetup.js" />