import {mapGetters, mapActions} from 'vuex'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
import ProcessViewStepListPreview from '@/components/projectInformation/ProcessViewStepListPreview.vue'
import ProcessApprovalStepListPreview from '@/components/projectInformation/ProcessApprovalStepListPreview.vue'
import Loader from '@/components/layout/Loader.vue'
import Select2 from '@/components/layout/Select2.vue'
import Modal from '@/components/layout/Modal.vue'
import { LinkList, ProcessStepType } from '@/helpers/utilities.js'
import  { GetProjectProcessItem, CreateStepProjectProcess,AddUserToStepProcess,RemoveStepProjectProcess, RemoveUserToStepProcess,ChangeOrdertepProjectProcess} from '@/actions/projectActions.js'
import  { SearchRolesDirectory,SearchUserFromProjectDirectory} from '@/actions/directoryActions.js'
import 'dotenv/config'
export default {
    name : 'ProjectDetails',
    components : {
        Loader,
        BreadcrumbsProject,
        ProcessViewStepListPreview,
        ProcessApprovalStepListPreview,
        Modal,
        Select2
    },
    data(){
        return{
            loaderProject: true,
            projectId : this.$route.params.id,
            processId : this.$route.params.processId,
            roles : [],
            processItem : {
                type : 1,
                processName : '',
                stepList : [],
                relatedList : []
            },
            newStep : {
                roleId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            newStepView : {
                roleId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            orderStep : {
                order : 1,
                roleId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            removeStep : {
                roleId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            newUserToStep : {
                userId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            removeUserToStep : {
                userId : '',
                id : this.$route.params.id,
                processId : this.$route.params.processId
            },
            newStepClicked : false,
            newUserStepClicked : false,
            openTrList :'-1',
            ProcessStepType : ProcessStepType,
            
        }
    },
    methods :{
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
        onClickStep(){

        },
        GetUserFromDirectory(search){
            return SearchUserFromProjectDirectory(search,this.newUserToStep.roleInt,this.$route.params.id )
        },
        AppendRelatedUser(data){
            this.newStepView.roleId = data.roleId
        },
        OpenNewRelatedUserModal(item){
            this.newUserToStep.roleId = item._id
            this.newUserToStep.roleInt = item.roleInt
            this.$refs.select2UserFromDirectory.ClearData()
        },
        OpenChangeOrderModal(item){
            this.orderStep.order = item.order
            this.orderStep.roleId = item.roleId._id
        },
        AddNewUserToStep(e){
            const btn = e.target
            const self = this

            self.newUserStepClicked = true

            if(!self.newUserToStep.relatedUserId){
                return
            }

            btn.disabled = true
            self.newUserStepClicked = false
            AddUserToStepProcess(self.newUserToStep).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeStepUserModal.click()
                    GetProjectProcessItem({id : self.newUserToStep.id, processId : self.newUserToStep.processId }).then((res)=>{
                        self.processItem = res
                    }).catch()
                }else{
                    self.$swal('Add User to Step', res, 'warning')
                }
            })
        },
        AddNewUserToProcess(e){
            const btn = e.target
            const self = this

            if(!self.newStepView.relatedUserId){
                return
            }

            btn.disabled = true
            AddUserToStepProcess(self.newStepView).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeItemAddModalView.click()
                    GetProjectProcessItem({id : self.newStepView.id, processId : self.newStepView.processId }).then((res)=>{
                        self.processItem = res
                    }).catch()
                }else{
                    self.$swal('Add User to Step', res, 'warning')
                }
            })
        },
        OpenStepList(id){
            this.openTrList = this.openTrList==id ? '-1' : id
        },
        OpenRemoveStep(item){
            const self = this
            self.removeStep.roleId = item._id
            self.$swal({
                title: 'Remove Step?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Remove',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return RemoveStepProjectProcess(self.removeStep).then((res)=>{
                        if(res!="OK"){
                            self.$swal('Step Removed', res, 'warning')
                        }else{
                             GetProjectProcessItem({id : self.removeStep.id, processId : self.removeStep.processId }).then((res)=>{
                                self.processItem = res
                            }).catch()
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Step Removed', '', 'success')
                }
            })
        },
        OpenRemoveUser(item){
            const self = this
            self.removeUserToStep.relatedUserId = item._id
            self.$swal({
                title: 'Remove User from Step?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Remove',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return RemoveUserToStepProcess(self.removeUserToStep).then((res)=>{
                        if(res!="OK"){
                            self.$swal('Remove From Step', res, 'warning')
                        }else{
                            const getIndex = self.processItem.relatedList.findIndex(u=>u.relatedUserId._id==item._id)
                            if(getIndex!=-1){
                                self.processItem.relatedList.splice(getIndex,1)
                            }
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('User Removed', '', 'success')
                }
            })
        },
        CreateNewStep(e){
            const self = this
            const btn = e.target
            self.newStepClicked = true

            if(!self.newStep.roleId){
                return 
            }

            self.newStepClicked = true
            btn.disabled = true
            CreateStepProjectProcess(self.newStep).then((res)=>{
                self.newStepClicked = false
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeProcessAddItemModal.click()
                    GetProjectProcessItem({id : self.newStep.id, processId : self.newStep.processId }).then((res)=>{
                        self.processItem = res
                    }).catch()
                }else{
                    self.$swal('Error',res,'warning')
                }
            }).catch()
        },
        ChangeStepOrderStep(e){
            const self = this
            const btn = e.target

            if(!self.orderStep.order){
                return 
            }

            if(self.orderStep.order < 0 || self.orderStep.order > self.processItem.stepList.length){
                return 
            }

            btn.disabled = true
            ChangeOrdertepProjectProcess(self.orderStep).then((res)=>{
                btn.disabled = false
                if(res=="OK"){
                    self.$refs.closeStepOrderModal.click()
                    GetProjectProcessItem({id : self.orderStep.id, processId : self.orderStep.processId }).then((res)=>{
                        self.processItem = res
                    }).catch()
                }else{
                    self.$swal('Error',res,'warning')
                }
            }).catch()
        }
    },
    computed : mapGetters(['projectInfo']),
    mounted :async function () {
        const self = this
        self.ChangeActiveLink(LinkList.PROJECT_PROCESS)
        const projectId = self.$route.params.id
        const processId = self.$route.params.processId
        self.loaderProject = true
        await self.GetProjectInfo(projectId).catch()
        const getItem = await GetProjectProcessItem({id : projectId, processId : processId }).catch()
        self.processItem = getItem
        const getRoles = await SearchRolesDirectory().catch()
        self.roles = getRoles
        self.loaderProject = false
        
    }
}