<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white ">
            <h6 class="mb-0"> <i class="fa-solid fa-book-bookmark "></i> {{ $t('Directory') }}</h6>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th> {{ $t('Name') }}</th>
                            <th> {{ $t('Role') }}</th>
                            <th> {{ $t('Email') }}</th>
                            <th> {{ $t('Contact') }} #</th>
                            <th>{{ $t('Location') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in projectDirectory.slice(0,10)" :key="p._id" >
                            <td class="text-capitalize">{{p.userId.fullname}}</td>
                            <td class="text-capitalize">{{p.roleIdProject}}</td>
                            <td >
                                <a :href="'mailto:' + p.userId.email" class="ms-1"> {{p.userId.email}}</a>
                            </td>
                            <td>
                                <a :href="'tel:' + p.userId.contactNumber[0]" class="ms-1"  v-if="p.userId.contactNumber[0]"> 
                                    {{p.userId.contactNumber[0] }}
                                </a >
                                <span v-else>
                                    -
                                </span>
                                
                            </td>
                            <td>
                                <small>
                                    {{ p.userId.address }}
                                </small>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center">
                    <router-link :to="'/project/' + projectId + '/directory'" class="link-style-custom">{{ $t('View All') }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    methods :{
        formatDate:ReformatDate,
    },
    data(){
        return{
            projectId : this.$route.params.id,
        }
    },
    computed : {
        ...mapGetters(['projectDirectory']),
    },
    
}
</script>
