<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : 'Projects',
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id + '/process' ,
                title : 'Approval List',
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>
    <div class="card border-top border-bottom">
        <div class="card-header bg-white">
            <h6 class="fw-bold">Approval List</h6>
        </div>
        <div class="card-body">
            <Pagination
                :changePage="PageChange" 
                :havePageCount="true"
                :paginationDetails="{totalPages : list.totalPages, page : list.page}" 
            />
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Module</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="list.docs.length==0">
                            <td colspan="7" class="text-center">No Data Found</td>
                        </tr>
                        <tr v-for="(item, key) in list.docs" :key="key">
                            <td>
                                <a class="link-item-route text-capitalize" :href="boqUrl + '/boq/review/' + item._id" v-if="item.onModel=='bill_of_quantities'">
                                    {{item.relatedId.name}}
                                </a>
                                <a class="link-item-route text-capitalize" :href="boqUrl + '/boq/' + item._id + '/quotation/approval'" v-if="item.onModel=='project_quotation'">
                                    {{item.relatedId.name}}
                                </a>
                            </td>
                            <td>{{ModalInfo[item.onModel]}}</td>
                            <td class="text-capitalize">{{item.createdBy.fullname}}</td>
                            <td class="text-capitalize">{{FormatDate(item.createdAt)}}</td>
                            <td>
                                <span class="badge bg-primary text-white" v-if="item.status==1">Internal Approval</span>
                                <span class="badge bg-primary text-white" v-else-if="item.status==2">External Approval</span>
                                <span class="badge bg-success text-white" v-else>Approval Completed</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
import Pagination from '@/components/layout/Pagination.vue'
import StepItem2 from '@/components/layout/StepItem2.vue'
import { LinkList, ReformatDate, ProcessStepTypeString} from '@/helpers/utilities.js'
import {GetWorkflowList} from '@/actions/projectActions.js'
import 'dotenv/config'

export default {
    name : 'ApprovalsList',
    components : {
        BreadcrumbsProject, StepItem2,Pagination
    },
    methods : {
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
        PageChange(page){
            const self = this
            self.pageParams.page = page
            GetWorkflowList(self.pageParams).then((res)=>{
                self.list = res
            }).catch()
        },
        FormatDate(date){
            return ReformatDate(date)
        }
    },
    mounted : async function() {
        const self = this
        self.ChangeActiveLink(LinkList.APPROVAL_LIST)
        const projectId = self.$route.params.id
        self.loader = true
        await self.GetProjectInfo(projectId).catch()
        self.loader = false
        const getList = await GetWorkflowList(self.pageParams).catch()
        self.list = getList
    },
    computed : mapGetters(['projectInfo']),
    data(){
        return {
            loader : false,
            list : {
                docs : [],
                totalPages : 0,
                totalDocs : 0
            },
            pageParams :{
                search : '',
                page  :1,
                id : this.$route.params.id
            },
            ModalInfo : {
                'bill_of_quantities' : 'Bill of Quantities',
                'project_quotation' : 'Quotations',
            },
            boqUrl : process.env.VUE_APP_BOQ_URL,
            ProcessStepTypeString : ProcessStepTypeString
        }
    }
    

}
</script>