import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true



export const AddQuotation = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq/quotation`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return {
            status : res.status,
            data : res.data
        }
    }).catch((err)=>{
        return {
            data : err.response.data,
            status :err.response.status,
        }
    })
}

export const CreateBoq = async (data) =>{
    return axios.post(`${MAIN_API}/api/boq`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return {
            status : res.status,
            data : res.data
        }
    }).catch((err)=>{
        return {
            status : err.response.status,
            data : err.response.data
        }
    })
}


export const SearchQuotationList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/search/quotation/${data.id}`,
    {
        params : {
            search : data.search || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}