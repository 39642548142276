<template>
    <div class="card border shadow">
        <div class="card-header bg-dark text-white">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-money-check-dollar"></i> {{ $t('Purhase Order Log') }}</h6>
        </div>
        <div class="card-body p-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>{{ $t('S/N') }}</th>
                        <th>{{ $t('PO No.') }}</th>
                        <th>{{ $t('Vendor') }}</th>
                        <th>{{ $t('Description') }}</th>
                        <th>{{ $t('Last Updated') }}</th>
                        <th>{{ $t('Status')}}</th> 
                        <th class="text-end">{{ $t('Amount') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="data.length==0">
                        <td colspan="7" class="text-center">{{ $t('No data Found') }}</td>
                    </tr>
                    <tr v-for="(item,key) in data" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td> <a :href="dotNetUrl + '/purchaseorder/PurchaseOrderApproval/' + item.id" class="link-style-custom"> {{projectInfo.projectNumber}}-{{item.poNumber}}</a> </td>
                        <td>{{ item.vendorName }}</td>
                        <td>
                            <small> {{ item.description }}</small>
                        </td>
                        <td>{{ ReformatDate(item.date) }}</td>
                        <td><span :class="color[item.status - 1]">{{item.statusName}}</span></td>
                        <td class="text-end">{{projectInfo.currencyId?.sign}}{{ ReformatNumber(item.amount) }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center"> <a :href="dotNetUrl + '/PurchaseOrder/purchaseordersList?projectId='+projectInfo.projectOldId" class="link-style-custom">{{ $t('View All') }}</a></div>
        </div>
    </div>
</template>

<script>
import {DashboardGetProjectPurchaseOrders} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate} from '../../helpers/utilities.js'
import 'dotenv/config'
export default {
    name : 'PurchaseOrdersList',
    props : ['projectInfo'],
    data(){
        return {
            data : [],
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            color : ['text-muted','text-info','text-success','text-warning','text-warning','text-primary','text-success']
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetProjectPurchaseOrders(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,ReformatDate
    }
    
} 
</script>