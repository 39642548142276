<template>
    <nav class="navbar navbar-light bg-white sticky-top navbar-expand border-bottom">
        <div class="container-fluid">
           
            <!-- <a class="navbar-brand" href="#">
                Commercial Management
            </a> -->
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <select class="form-select border-0" @change="ChangeLocale" v-model="language">
                            <option value="en">EN</option>
                            <option value="zh-CN">中文 (中国)</option>
                        </select>
                    </li>
                    <li class="nav-item">
                        <a><img src="https://staff.mmoseronelink.com/mmoser-associates.svg"  class=" hide-md-less" alt="" width="300" height="40"></a>
                        <img src="https://www.mmoseronelink.com/wp-content/uploads/2019/03/mmoser-icon.png" class="show-md-less" width="40" height="40" alt="">
                        
                    </li>
                    
                    
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
    import { GetMyUserInfo , UpdateMyLanguage} from '../../actions/userActions.js'
    import {LocaleNameVariable} from '@/translations/i18n.js'
    import 'dotenv/config'
    import dayjs from 'dayjs'
    import 'dayjs/locale/zh-cn'
    import 'dayjs/locale/en'
    export default {
        name : 'NavBar',
        data (){
            return {
                userInfo : {},
                directoryUrl : process.env.VUE_APP_DIRECTORY_URL,
                projectUrl : process.env.VUE_APP_PROJECT_URL,
                language : 'en'
            }
        },
        mounted : async function(){

            const self = this
            const getCurrentLanguage = window.localStorage.getItem(LocaleNameVariable) 
            self.userInfo = await GetMyUserInfo()
            if(self.userInfo == 401)
            {
                return
            }
            if(getCurrentLanguage != self.userInfo.language)
            {
                window.localStorage.setItem(LocaleNameVariable, self.userInfo.language)
                window.location.reload()
            }
            self.language = self.userInfo.language
            document.documentElement.lang = self.userInfo.language
        },
        methods : {
            GetMyInfo (){
                const self = this
                GetMyUserInfo().then((res)=>{
                    self.userInfo = res
                })
            }
            ,async ChangeLocale(){
                let value = this.language
                this.$i18n.locale = value
                window.localStorage.setItem(LocaleNameVariable, value)
                document.documentElement.lang = value
                dayjs.locale(value)
                const result = await UpdateMyLanguage({language : value})
                if(result=='OK')
                {
                    window.location.reload()
                }

            }
        }
    }
</script>
