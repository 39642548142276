<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : 'Projects',
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id + '/process' ,
                title : 'Process List',
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>
    <div class="card border-top border-bottom">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <h6 class="fw-bold ">Process List</h6>
                </div> 
                <div>
                    <button 
                    data-bs-toggle="modal" data-bs-target="#ProcessSetupModal"
                    @click="OpenModalCreate"
                    v-if="permission.indexOf(ModuleActionList.PROCESS_CREATE)>-1"
                    class="btn btn-primary"><i class="fa-solid fa-plus"></i> Create Process</button>
                </div>
            </div> 
        </div>
        <div class="card-body">
            <Pagination
                :changePage="PageChange" 
                :havePageCount="true"
                :paginationDetails="{totalPages : processList.totalPages, page : processList.page}" 
            />
            
            <div class="table-responsive">
                <table class="table table-striped text-capitalize">
                    <thead>
                        <tr>
                            <th>Process Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Created At</th>
                            <th v-if="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loaderProject">
                            <td :colspan="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1 ? 5 :4" >
                                <Loader></Loader>
                            </td>
                        </tr>
                        <template v-else> 
                            <tr v-if="processList.docs.length==0">
                                <td :colspan="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1 ? 5 :4" class="text-center">No Data Found</td>
                            </tr>
                            <tr v-for="(item, key) in processList.docs" :key="key">
                                <td>
                                    <router-link class="link-item-route" 
                                    v-if="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1"
                                    :to="{name : 'ProcessSetup', params : {id : projectId, processId : item._id}}">
                                        {{item.processName}}
                                    </router-link>
                                    <span v-else>
                                        {{item.processName}}
                                    </span>
                                </td>
                                <td>{{GetProjectType(item.type)}}</td>
                                <td>
                                    <span class="badge bg-primary" v-if="item.status==true">Active</span>
                                    <span class="badge bg-light text-dark" v-else>Inactive</span>
                                </td>
                                <td>{{FormatDate(item.createAt)}}</td>
                                <td v-if="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1">
                                    <router-link class="link-item-route" :to="'/project/' + projectId +'/process/' + item._id " title="View Setup">
                                        <button class="btn btn-action btn-outline-primary"><i class="fa-solid fa-arrow-right-to-bracket"></i></button>
                                    </router-link>
                                    
                                    <button class="btn btn-outline-primary btn-action ms-1" title="Edit"
                                    data-bs-toggle="modal" data-bs-target="#ProcessUpdateModal"
                                    @click="OpenModalUpdate(item)"
                                    ><i class="fa-solid fa-pencil"></i></button>

                                    <!-- <button class="btn btn-outline-primary btn-action ms-1"
                                    data-bs-toggle="modal" data-bs-target="#ProcessLinkItemModal"
                                    @click="OpenModalLink(item)"
                                    title="Link Item"
                                    ><i class="fa-solid fa-link"></i></button> -->
                                </td>
                            </tr>  
                        </template>
                        
                    </tbody>   
                </table>
            </div>
        </div> 
    </div>
    <Modal idModal="ProcessSetupModal" headerTitle="Create Process" v-if="permission.indexOf(ModuleActionList.PROCESS_CREATE)>-1" >
        <form>
            <div class="form-group">
                <label class="required-style">Process Name </label>
                <input type="text" class="form-control" v-model="newProcess.processName" placeholder="Process Name"/>
                <span class="text-warning small" v-if="!newProcess.processName&&saveClicked">Required</span>
            </div>
            <div class="form-group">
                <label class="required-style">Type </label>
                <select class="form-select" v-model="newProcess.type">
                    <option value="">Select Type</option>
                    <option value="1">Review</option>
                    <option value="2">Approval</option>
                </select>
                <span class="text-warning small" v-if="!newProcess.type&&saveClicked">Required</span>
            </div>
            <div class="form-group">
                <label class="required-style">Use Pre-defined Process Set-Up </label>
                <select class="form-select" v-model="newProcess.preDefined">
                    <option value="1">No</option>
                    <option value="2">Yes</option>
                </select>
                <small class="text-primary" v-if="newProcess.preDefined==2"> <i class="fa-solid fa-circle-info"></i> Automatically fill-out process steps based on the Project Directory</small>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeProcessModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="CreateProcessRun" ><i class="fa-solid fa-save"></i> Create Process</button>
      </template>
    </Modal>
    <Modal idModal="ProcessUpdateModal" headerTitle="Update Process" v-if="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1" >
        <form>
            <div class="form-group">
                <label class="required-style">Process Name </label>
                <input type="text" class="form-control text-capitalize" v-model="updateProcess.processName" placeholder="Process Name"/>
                <span class="text-warning small" v-if="!updateProcess.processName&&updateClicked">Required</span>
            </div>
            <div class="form-group">
                <label class="required-style">Status </label>
                <select class="form-select" v-model="updateProcess.status" >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </select>
                <span class="text-warning small" v-if="!updateProcess.status&&updateClicked">Required</span>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeUpdateProcessModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="UpdateProcessRun"><i class="fa-solid fa-save"></i> Save Changes</button>
      </template>
    </Modal>
    <Modal idModal="ProcessLinkItemModal" headerTitle="Link Process" v-if="permission.indexOf(ModuleActionList.PROCESS_EDIT)>-1" >
        <form>
            <div class="form-group">
                <label class="required-style">Process Name </label>
                <input type="text" class="form-control-plaintext text-capitalize" :value="linkProcess.processName" readonly placeholder="Process Name"/>
            </div>
            <div class="form-group">
                <label class="required-style">Type of Item </label>
                <select class="form-select"  >
                    <option value="">Please Select</option>
                    <option value="1">Bill Of Quantities</option>
                    <option value="2">Estimates</option>
                    <option value="3">Quotations</option>
                </select>
            </div>
            <div class="form-group">
                <label class="required-style">Item To link </label>
                <select class="form-select"  >
                    <option value="">Please Select</option>
                </select>
            </div>
        </form>
        <template v-slot:actions>
            <button type="button" ref="closeUpdateProcessModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary"  @click="UpdateProcessRun"><i class="fa-solid fa-link"></i> Link Item</button>
      </template>
    </Modal>
    
</template>

<script src="@/assets/projectJs/ProcessList.js" />