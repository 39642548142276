import { createStore } from 'vuex'
import ProjectInformation from './modules/ProjectInformation.js'

export default createStore({
  state:{
    activeLink : 1,
  },
  getters : {
    activeLink :state =>  state.activeLink
  },
  actions:{
    ChangeActiveLink :({commit},id) => commit('setActiveLink',id)
  },
  mutations : {
    setActiveLink :  (state,data) => (state.activeLink = data),
  },
  modules: {
    ProjectInformation
  }
})
