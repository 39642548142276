
import Draggable from 'vuedraggable'
import {mapGetters, mapActions} from 'vuex'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
import StepItem2 from '@/components/layout/StepItem2.vue'
import Select2 from '@/components/layout/Select2.vue'
import Loader from '@/components/layout/Loader.vue'
import DirectoryOverview from '@/components/projectInformation/DirectoryOverview.vue'
import PaymentPositionToVendor from '@/components/widgets/PaymentPositionToVendor.vue'
import PaymentPositionToClient from '@/components/widgets/PaymentPositionToClient.vue'
import NetCashPosition from '@/components/widgets/NetCashPosition.vue'
import TotalTradesProcured from '@/components/widgets/TotalTradesProcured.vue'
import ProcurementDetails from '@/components/widgets/ProcurementDetails.vue'
import CummulativePaymentPosition from '@/components/widgets/CummulativePaymentPosition.vue'
import PurchaseOrdersList from '@/components/widgets/PurchaseOrdersList.vue'
import ClientInvoiceList from '@/components/widgets/ClientInvoiceList.vue'
import ClientProgressClaims from '@/components/widgets/ClientProgressClaims.vue'
import ChangeRequestList from '@/components/widgets/ChangeRequestList.vue'
import ProjectBudgetBreakdown from '@/components/widgets/ProjectBudgetBreakdown.vue'
import ProjectInformationWidget from '@/components/widgets/ProjectInformationWidget.vue'
import ProjectDates from '@/components/widgets/ProjectDates.vue'
import BillOfQuanitityListWidget from '@/components/widgets/BillOfQuanitityListWidget.vue'
import ProjectQuotationListWidget from '@/components/widgets/ProjectQuotationListWidget.vue'
import NotificationWidget from '@/components/widgets/NotificationWidget.vue'
import ActionablesWidget from '@/components/widgets/ActionablesWidget.vue'
import ProjectOverviewWidget from '@/components/widgets/ProjectOverviewWidget.vue'
import {GetSystemSettings, GetTokenSharepointFromAuth,GetProjectSharepointFolders, UpdateProjectSharepointFolders } from '@/actions/projectActions.js'
import {GetMyUserDashboarSettings , UpsertMyUserDashboarSettings} from '@/actions/userActions.js'
import { stageIcons, LinkList, ModuleActionList, ApprovalStageBoqObj, ReformatNumber ,CleanSharePointFilename } from '@/helpers/utilities.js'
import  *  as msalHelper  from '@/helpers/msalHelpers.js'
import Modal from '@/components/layout/Modal.vue'
import { markRaw } from 'vue';
import 'dotenv/config'
import {CreateBoq, SearchQuotationList, AddQuotation} from '@/actions/boqActions.js'
export default {
    name : 'Overiew',
    components : {
        StepItem2,
        Draggable,Loader,
        BreadcrumbsProject,Modal, Select2,
        DirectoryOverview,PaymentPositionToVendor, PaymentPositionToClient, NetCashPosition, TotalTradesProcured,
        ProcurementDetails, CummulativePaymentPosition, PurchaseOrdersList, ClientInvoiceList, ClientProgressClaims,
        ChangeRequestList, ProjectBudgetBreakdown, ProjectInformationWidget, ProjectDates,BillOfQuanitityListWidget,
        ProjectQuotationListWidget, NotificationWidget,ActionablesWidget,ProjectOverviewWidget
        
    },
    data(){

        

        return{
            editMode : false,
            dashboardSettings : {
                settings : []
            },
            widgetList : [
                { id : 1 , name : 'Notifications', component : markRaw(NotificationWidget), presetWidth : 6,  },
                { id : 2 , name : 'Actionables', component : markRaw(ActionablesWidget), presetWidth : 6 },
                { id : 19 , name : 'Project Overview', component : markRaw(ProjectOverviewWidget), presetWidth : 12 },
                { id : 3 , name : 'Payment Position (To Vendor)', component : markRaw(PaymentPositionToVendor), presetWidth : 4 },
                { id : 4 , name : 'Payment Position (To Client)', component : markRaw(PaymentPositionToClient), presetWidth : 4 },
                { id : 5 , name : 'Net Cash Position', component : markRaw(NetCashPosition), presetWidth : 4 },
                { id : 6 , name : 'Trade Package Procured', component : markRaw(TotalTradesProcured), presetWidth : 6 },
                { id : 7 , name : 'Project Schedule', component : markRaw(ProjectDates), presetWidth : 6 },
                { id : 8 , name : 'Procurement Progress', component : markRaw(ProcurementDetails), presetWidth : 6 },
                { id : 9 , name : 'Cummulative Payment Position', component : markRaw(CummulativePaymentPosition), presetWidth : 6 },
                { id : 10 , name : 'Project Budget Breakdown', component : markRaw(ProjectBudgetBreakdown), presetWidth : 6 },
                { id : 11 , name : 'Project Directory', component : markRaw(DirectoryOverview), presetWidth : 6 },
                { id : 12 , name : 'Project Information', component : markRaw(ProjectInformationWidget), presetWidth : 6 },
                { id : 13 , name : 'Latest Bill of Quantities', component : markRaw(BillOfQuanitityListWidget), presetWidth : 6 },
                { id : 14 , name : 'Latest Quotations', component : markRaw(ProjectQuotationListWidget) },
                { id : 15 , name : 'Latest Purchase Orders ', component : markRaw(PurchaseOrdersList), presetWidth : 6 },
                { id : 16 , name : 'Latest Client Progress Claims ', component : markRaw(ClientProgressClaims), presetWidth : 6 },
                { id : 17 , name : 'Latest Client Invoices', component : markRaw(ClientInvoiceList), presetWidth : 6 },
                { id : 18 , name : 'Latest Change Requests', component : markRaw(ChangeRequestList), presetWidth : 6 },
            ],
            directoryOverviewLoader: true,
            projectOverviewLoader: true,
            boqOverviewLoader: true,
            quotationLoader: true,
            placeHolderOverviewLoader: true,
            stages : stageIcons,
            boqData : [],
            quotationData : [],
            boqUrl : process.env.VUE_APP_BOQ_URL,
            projectId : this.$route.params.id,
            ModuleActionList : ModuleActionList,
            ApprovalStageBoqObj : ApprovalStageBoqObj,
            ReformatNumber : ReformatNumber,
            newQuotation  : {},
            qtnSaveClicked : false,
            isExecuting : false,
            quotationList : [],
            newBoq : {},
            boqSaveClicked : false,
            projectSettings : {},
            sharepointToken : null,
            sharepointFolders : {},
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            updateWidgetObj : {},
            selectedWidgets : [],
            isWidgetSaving : false,
            haveChanges : false
        }
    },
    methods :{
        ...mapActions(['GetProjectInfo','GetProjectDirectory','ChangeActiveLink']),
        CheckWidget(id){
            const self = this
            const getSettings = self.dashboardSettings.settings
            const getIndex = self.selectedWidgets.indexOf(id)
            if(getIndex==-1){
                self.selectedWidgets.push(id)
                const getWidget = self.widgetList.find(u=>u.id==id)
                getSettings.push({
                    position : getSettings.length + 1,
                    height : 1,
                    width : getWidget.presetWidth,
                    widgetId : getWidget.id,
                    component : getWidget.component,
                    name : getWidget.name 
                })
            }
            else{
                self.selectedWidgets.splice(getIndex,1)
                const getIndexInSettings = getSettings.findIndex(u=>u.widgetId==id)
                if(getIndexInSettings==-1){ return }
                getSettings.splice(getIndexInSettings,1)
            }
        },
        OnDragEnd(){
            this.haveChanges = true
        },
        SetUpProject(){
            const self = this
            if(!self.sharepointFolders.projectSharepointFolders && !self.sharepointToken){
                location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=2&returnUrl='+encodeURIComponent(window.location.href)
            }
        },
        OpenUpdateWidget(id,width,height){
            this.updateWidgetObj.id = id
            this.updateWidgetObj.width = width
            this.updateWidgetObj.height = height
        },
        UpdateSettings(){
            const self = this
            const widgetSize = self.updateWidgetObj
            const getSettingsWidget = self.dashboardSettings.settings.find(u=>u.widgetId==widgetSize.id)
            if(!getSettingsWidget){ return }
            getSettingsWidget.width = widgetSize.width
            getSettingsWidget.height = widgetSize.height
            this.haveChanges = true

            self.$refs.closeModalUpdateWidget.click()
        },
        async SaveSettings(){
            const self = this
            
            if(self.selectedWidgets.length==0){
                self.$swal('Save Dashboard', 'Please select atleast one Widget', 'warning')
                return
            }
            
            self.isWidgetSaving = true

            for(let i =0 ; i < self.dashboardSettings.settings.length ; i++){
                self.dashboardSettings.settings[i].position = i + 1
            }
            const saved = await UpsertMyUserDashboarSettings({
                settings : self.dashboardSettings.settings
            })
            self.isWidgetSaving = false
            if(saved!='OK'){
                self.$swal('Save Dashboard', saved, 'warning')
                return
            }
            self.editMode = false
            self.haveChanges = false
            self.$swal('Save Dashboard', 'Dashboard successfully saved!', 'success')
        },
        SearchQuotationList(value){
            return SearchQuotationList({search : value, id : this.projectInfo._id})
        },
        CreateBoq(e){
            const btn = e.target
            const self = this

            if(self.isExecuting){
                return
            }

            const newData = self.newBoq
            if(!newData.name){
                return
            }
            newData.projectId = self.projectInfo._id
            newData.currencyId = self.projectInfo.currencyId._id
            btn.disabled = true
            self.isExecuting = true

            CreateBoq(newData).then((res)=>{
                self.isExecuting = false
                if(res.status!=200){
                    btn.disabled = false
                    self.$swal('Add BoQ',res.data,'warning')
                }else{
                    window.location.href = self.boqUrl + '/boq/items/' + res.data
                }
            })
        },
        CreateQuotation(e){
            const btn = e.target
            const self = this
            self.qtnSaveClicked = true

            if(self.isExecuting){
                return
            }
            const data = self.newQuotation

            if(!data.qtnName || !data.qtnNumber){
                return
            }
            data.projectId = self.projectInfo._id
            self.qtnSaveClicked = false
            btn.disabled = true
            self.isAdding = true
            AddQuotation(data).then((res)=>{
                if(res.status!=200){
                    
                    self.isAdding = false
                    btn.disabled = false
                    self.$swal('Add Quotation',res.data,'warning')
                }else{
                    window.location.href = self.boqUrl + '/boq/' + res.data + '/quotation/item?isEdit=true'
                }
            })
        },
        
    },
    watch : {
        selectedWidgets : {
            handler : function(){
                this.haveChanges = true
            },
            deep :true
        }
    },
    beforeRouteLeave(){
        if(this.haveChanges){
            const answer = window.confirm('You have unsaved changes. Are you sure you want to discard them?')
            if(!answer){
                return false
            }
        }
    },
    computed : mapGetters(['projectInfo','projectUpdateObject', 'permission']),
    mounted :async function () {
        const self = this
        self.ChangeActiveLink(LinkList.SUMMARY)
        const projectId = self.$route.params.id
        await self.GetProjectInfo(projectId).catch()
        self.projectOverviewLoader = false
        await self.GetProjectDirectory().catch()
        self.directoryOverviewLoader = false

        const getDasboard = await GetMyUserDashboarSettings()
        if(getDasboard){
            self.dashboardSettings= getDasboard
        }else{
            let presetDasboardSettings = []
            for(let x = 0; x < self.widgetList.length; x++){
                const getWidget = self.widgetList[x]
                presetDasboardSettings.push({
                    position : x,
                    height : 1,
                    width : getWidget.presetWidth,
                    widgetId : getWidget.id, 
                })
            }
            self.dashboardSettings.settings = presetDasboardSettings
        }

        //set the components
        for(let x = 0; x< self.dashboardSettings.settings.length; x++){
            const getSettings = self.dashboardSettings.settings[x]
            const getWidget = self.widgetList.find(u=> u.id==getSettings.widgetId)
            if(!getWidget) { continue }
            getSettings.component = getWidget.component
            getSettings.name = getWidget.name
            self.selectedWidgets.push(getSettings.widgetId)
        }
        self.projectSettings = await GetSystemSettings()
        self.sharepointToken = await GetTokenSharepointFromAuth()
        self.sharepointFolders = await GetProjectSharepointFolders(projectId)
        if(self.sharepointToken &&!self.sharepointFolders.projectSharepointFolders && self.projectSettings.sharepointDriveId){
            const projectFolder = await msalHelper.CreateFolderToDrive(self.sharepointToken,self.projectSettings.sharepointDriveId,CleanSharePointFilename(self.projectInfo.projectNumber + ' ' + self.projectInfo.projectName))
            const qtnFolder = await msalHelper.CreateFolderToFolder(self.sharepointToken,self.projectSettings.sharepointDriveId,projectFolder.id,'Quotation')
            const poFolder = await msalHelper.CreateFolderToFolder(self.sharepointToken,self.projectSettings.sharepointDriveId,projectFolder.id,'Purchase Order')
            const viFolder = await msalHelper.CreateFolderToFolder(self.sharepointToken,self.projectSettings.sharepointDriveId,projectFolder.id,'Vendor Invoice')
            await UpdateProjectSharepointFolders({
                projectId : projectId,
                projectFolderId : projectFolder.id,
                quotationFolderId : qtnFolder.id,
                purchaseOrderFolderId : poFolder.id,
                vendorInvoiceFolderId : viFolder.id,

            })
        }

        self.haveChanges = false
        self.placeHolderOverviewLoader = false
        
    }
}