<template>
    <div class="card card-round shadow " >
        <div class="card-header bg-dark text-white  ">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-circle-info"></i> {{ $t('Project Information') }}</h6>
        </div>
        <div class="card-body p-0 ">
            <table class="table mb-0">
                <tbody>
                    <tr>
                        <td>
                            <b class=" "> {{ $t('Project Address') }}:</b>
                        </td>
                        <td>
                            {{projectInfo.projectAddress}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b class=" "> {{ $t('Contract Execution Date') }}:</b>
                        </td>
                        <td>
                            {{projectInfo.confirmedDate}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b class=" "> {{ $t('Project Commencement Date') }}:</b>
                        </td>
                        <td>
                            {{projectInfo.startDate}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b class=""> {{ $t('Contract Handover Date') }}:</b>
                        </td>
                        <td>
                            {{projectInfo.handOverDate}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b class=""> {{ $t('Contract Type') }}:</b>
                        </td>
                        <td>
                            {{projectInfo.contractType}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
    export default {
    name : 'ProjectInformationWidget',
    props : ['projectInfo'],
} 
</script>