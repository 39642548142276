import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ch from './locales/ch.json'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/en'

export const LocaleNameVariable = 'z_locale'
// Define your translations
const messages = {
  en: en,
  'zh-CN': ch
}

let getLocale = window.localStorage.getItem(LocaleNameVariable)
if(!getLocale)
{
    getLocale = 'en'
    window.localStorage.setItem(LocaleNameVariable,getLocale)
    document.documentElement.lang = getLocale
}

dayjs.locale(getLocale.toLocaleLowerCase())


// Create the i18n instance
const i18n = createI18n({
  locale: getLocale, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
})


export default i18n