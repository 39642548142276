<template>

    <Modal :idModal="idModal" :headerTitle="headerTitle" modalSize="modal-xl" >
        <div>
            <div class="form-group">
                <label>{{ $t('User Type') }}</label>
                <select class="form-select" v-model="createType" >
                    <option value="1">{{ $t('Existing') }}</option>
                    <option value="2"> {{ $t('New') }}</option>
                </select>
            </div>
            <hr class="ms-0 me-0" />
            <template v-if="createType==1">
                <div class="form-group">
                    <label class="required-style">{{ $t('Company Type') }}</label>
                    <select class="form-select" v-model="existing.companyType" @change="ChangeExistingCType">
                        <option v-for="(item, key) in CompanyTypeList" :key="key" :value="item.id">{{item.value}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="required-style">{{ $t('User') }} </label>
                    <Select2 
                        :filterQuery="SearchUser"
                        indexValue="_id"
                        labelName="fullname"
                        :placeHolder="$t('Search Here')"
                        v-model:optionValue="existing.userId"
                        :onOptionClick="AppendSelectedInfo"
                        ref="select2UserExist"
                    ></Select2>
                    <small class="text-warning" v-if="!existing.userId&&SaveClicked">{{ $t('Required') }}</small>
                </div>
                 <div class="form-group" v-if="existing.companyType==CompanyType.MMOSER">
                <label class="required-style"> {{ $t('Role in Project') }}</label>
                    <select class="form-select" v-model="existing.roleId">
                    <option value="">{{ $t('Please Select') }}</option>
                    <option v-for="(item, key) in roles" :key="key" :value="item._id">{{$t(item.roleName)}}</option>
                </select>
                <small class="text-warning" v-if="!existing.roleId&&SaveClicked">{{ $t('Required') }}</small>
            </div>
                <div class="form-group"  v-if="existing.companyType!=CompanyType.MMOSER">
                    <label class="required-style">{{ $t('Role in Project') }}</label>
                    <input type="text" class="form-control" placeholder="Project Leader" v-model="existing.roleProject"  readonly  />
                    <small class="text-warning" v-if="!existing.roleProject&&SaveClicked">{{ $t('Required') }}</small>
                </div>
                <!-- <div class="form-group" v-if="existing.companyType!=CompanyType.MMOSER">
                    <label class="required-style">Company </label>
                    <input type="text" class="form-control" placeholder="Company Name" v-model="existing.company" />
                    <small class="text-warning" v-if="!existing.company&&SaveClicked">Required</small>
                </div> -->
                <div class="form-group" >
                    <label class="required-style" > {{ $t('Job Description') }} </label>
                    <input type="text" class="form-control" :placeholder="$t('Job Description')" v-model="existing.jobDescription" />
                    <small class="text-warning" v-if="!existing.jobDescription&&SaveClicked">Required</small>
                </div>
            </template>
            <template v-else>
                <div class="form-group">
                    <label class="required-style">{{ $t('Company Type') }} </label>
                    <select class="form-select" v-model="newUser.companyType" @change="ChangeNewUserCtype">
                        <option v-for="(item, key) in CompanyTypeList" :key="key" :value="item.id">{{item.value}}</option>
                    </select>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        
                        <div class="form-group" v-if="newUser.companyType==CompanyType.MMOSER">
                            <label class="required-style">{{ $t('Search From KC') }}</label>
                            <!-- <input type="text" class="form-control" placeholder="John" v-model="newUser.firstName" /> -->
                            <Select2 
                                :filterQuery="SearchFromKc"
                                indexValue="firstName"
                                labelName="fullname"
                                :placeHolder="$t('Search User from KC')"
                                :onOptionClick="AppendSelectedKcUser"
                                ref="select2UserExist"
                            ></Select2>
                            <small class="text-warning" v-if="!newUser.firstName&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="required-style">{{ $t('First Name') }}</label>
                            <input type="text" class="form-control" :placeholder="$t('First Name')" v-model="newUser.firstName" />
                            <small class="text-warning" v-if="!newUser.firstName&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="required-style">${{ $t('Last Name') }} </label>
                            <input type="text" class="form-control" :placeholder="$t('Last Name')" v-model="newUser.lastName" />
                            <small class="text-warning" v-if="!newUser.lastName&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group" v-if="newUser.companyType==CompanyType.MMOSER">
                            <label class="required-style">{{ $t('Role in Project') }}</label>
                             <select class="form-select" v-model="newUser.roleId">
                                <option value="">{{ $t('Please Select') }}</option>
                                <option v-for="(item, key) in roles" :key="key" :value="item._id">{{$t(item.roleName)}}</option>
                            </select>
                            <small class="text-warning" v-if="!newUser.roleId&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group"  v-else-if="newUser.companyType==CompanyType.CLIENT">
                            <label class="required-style">Role in Project</label>
                            <input type="text" class="form-control" readonly value="Client" />
                        </div>
                        <div class="form-group"  v-else>
                            <label class="required-style">{{ $t('Role in Project') }}</label>
                            <input type="text" class="form-control" :placeholder="$t('Role in Project')" readonly v-model="newUser.roleProject" />
                            <small class="text-warning" v-if="!newUser.roleProject&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="required-style">{{ $t('Job Description') }} </label>
                            <input type="text" class="form-control" :placeholder="$t('Job Description')" v-model="newUser.jobDescription" />
                            <small class="text-warning" v-if="!newUser.jobDescription&&SaveClicked">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="required-style"> {{ $t('Email') }} </label>
                            <input type="text" class="form-control" placeholder="sample@gmail.com" v-model="newUser.email" />
                            <small class="text-warning" v-if="(!newUser.email&&SaveClicked) || (!IsValidEmail(newUser.email)&&SaveClicked)">{{ $t('Required') }}</small>
                        </div>
                        <div class="form-group" v-if="newUser.companyType!=CompanyType.MMOSER && !IsNewCompany">
                            <label class="required-style">{{$t('Company Name')}} </label>
                            <!-- <input type="text" class="form-control" placeholder="Company Name" v-model="newUser.company" /> -->
                            <Select2 
                            :filterQuery="SearchCompanies"
                            indexValue="_id"
                            labelName="companyName"
                            :placeHolder="$t('Search Here')"
                            ref="select2UsercompanyUpdate"
                            v-model:optionValue="newUser.company"
                            ></Select2>
                            <small class="text-warning" v-if="!newUser.company&&SaveClicked">Required <br/></small>
                            <a class="text-primary small" role="button" @click="()=>{ IsNewCompany =!IsNewCompany;newUser.company = ''}">{{ $t('Not able to Find Company? Click here to create')}}</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('Photo Url') }}</label>
                            <input type="text" class="form-control" placeholder="https://url" v-model="newUser.photoUrl" />
                        </div>
                        <div class="form-group">
                            <label>{{ $t('Address') }}</label>
                            <textarea class="form-control" v-model="newUser.address" :placeholder="$t('Address')"></textarea> 
                        </div>
                        <div class="form-group" v-for="(item, key) in addedContacts" :key="key">
                            <div class="d-flex justify-content-between">
                                <label> {{ $t('Contact Number') }}</label>
                                <small v-if="key!=0" @click="RemoveContact(key)" class="text-warning" role="button"><i class="fa-solid fa-minus"></i> {{ $t('Remove') }}</small>    
                            </div>
                            <input type="text" class="form-control" placeholder="+6312345678910" v-model="item.value" @change="ChangeContact(key,$event.target.value)" />
                        </div>
                        <div>
                            <small @click="AddNewLineContact" class="text-primary" role="button"><i class="fa-solid fa-plus"></i> {{ $t('Add Contact Number') }}</small>
                        </div>
                        
                    </div>
                </div>
                <div class="row" v-if="IsNewCompany">
                    <div class="col-md-6" >
                        <div class="form-group">
                            <label class="required-style">{{ $t('Company Name') }} </label>
                            <input type="text" class="form-control"  v-model="newUser.client_company"  :placeholder="$t('Company Name')"
                            />
                            <small class="text-warning" v-if="!newUser.client_company&&SaveClicked">
                                {{ $t('Required') }} <br />
                            </small>
                            <a class="text-primary small" role="button" @click="()=>{ IsNewCompany =!IsNewCompany;newUser.client_company = '';newUser.client_company_address=''}">{{ $t('Choose Existing Company? Click Here') }}</a>
                        </div>
                    </div>
                    <div class="col-md-6" >
                        <div class="form-group">
                            <label class="required-style">{{ $t('Company Address') }} </label>
                            <textarea class="form-control" v-model="newUser.client_company_address" :placeholder="$t('Company Address')"></textarea>
                            <small class="text-warning" v-if="!newUser.client_company_address&&SaveClicked">
                                {{ $t('Required') }}
                            </small>
                        </div>
                    </div>
                </div>
                
                
            </template>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeDirModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <div>
                <button type="button" class="btn btn-primary" @click="SaveNew" ><i class="fas fa-save"></i> {{ $t('Save') }}</button>
                <button type="button" class="btn btn-primary ms-1" @click="SaveAndClose"><i class="fas fa-save"></i> {{ $t('Save and Close') }}</button>
            </div>
        </template>
    </Modal>
</template>


<script>
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import {CompanyTypeList, CompanyType, IsValidEmail} from '@/helpers/utilities.js'
import {SearchUserDirectory, SearchCompanies} from '@/actions/directoryActions.js'
import {AddNewToProjectDirectory, AddToProjectDirectory, SearchUserFromKC} from '@/actions/projectActions.js'
import {mapActions}  from 'vuex'

import  i18n from '@/translations/i18n.js'
const i18nTranslate = i18n.global.t
export default {
    name : 'CreateModalUser',
    props : ['idModal', 'headerTitle','SetList', 'roleList'],
    components : {
        Modal,
        Select2
    },
    computed : {
        roles(){
            return this.roleList
        }
    },
    methods : {
        ...mapActions(['GetProjectDirectory']),
        IsValidEmail (value){
            return IsValidEmail(value)
        },
        SearchUser(search){
            return SearchUserDirectory(search,"0", this.existing.companyType)
        },
        SearchFromKc(search){
            return SearchUserFromKC(search)
        },
        SearchCompanies(search){
            return SearchCompanies({search : search})
        },
        AddNewLineContact(){
            this.addedContacts.push({value : ''})
        },
        RemoveContact(key){
            this.addedContacts.splice(key,1)
            this.newUser.contacts.splice(key,1)
        },
        ChangeExistingCType(){
            this.$refs.select2UserExist.ClearData()
            this.$refs.select2UserExist.searchExe()
            this.existing.roleProject = CompanyTypeList[this.existing.companyType - 1].value
        },
        ChangeNewUserCtype(){
            this.newUser.roleProject = CompanyTypeList[this.newUser.companyType - 1].value
        },
        AppendSelectedInfo(data){
            this.existing.company = data.company
        },
        AppendSelectedKcUser(data){
            this.newUser.firstName = data.firstName
            this.newUser.lastName = data.lastName
            this.newUser.photoUrl = data.photo
            this.newUser.email = data.email
            this.addedContacts = [{value : ''}]
            this.newUser.contacts  = []
            if(data.mobileNo!=""){
                this.addedContacts[0].value = data.mobileNo
                this.newUser.contacts.push(data.mobileNo)
            }
        },
        SaveNew(e){
            const self = this
            if(self.createType==1){
                self.SaveExistingToDirectory(e).then((res)=>{
                    if(res!="OK"){
                        self.$swal(i18nTranslate('Add User to Directory'),res,'warning')
                    }
                })
            }else if(self.createType==2){
                self.SaveExe(e).then((res)=>{
                    if(res!="OK"){
                        self.$swal(i18nTranslate('Add User to Directory'),res,'warning')
                    }
                })
            }
        },
        SaveAndClose(e){
            const self = this
            if(self.createType==1){
                self.SaveExistingToDirectory(e).then((res)=>{
                    if(res!="OK"){
                        self.$swal(i18nTranslate('Add User to Directory'),res,'warning')
                    }else{
                        self.$refs.closeDirModal.click()
                    }
                    
                })
            }else if(self.createType==2){
                self.SaveExe(e).then((res)=>{
                    if(res!="OK"){
                        self.$swal(i18nTranslate('Add User to Directory'),res,'warning')
                    }else{
                        self.$refs.closeDirModal.click()
                    }
                })
            }
        },
        SaveExistingToDirectory(e){
            const self = this
            return new Promise((resolve)=>{
                const btn = e.target
                self.SaveClicked = true
                if(!self.existing.userId){
                    return
                }
                const { companyType, jobDescription, roleId} = self.existing

                if(companyType==CompanyType.MMOSER){
                    if(!companyType || !jobDescription ||!roleId ){
                        return
                    }
                }
                else{
                    if(!companyType  || !jobDescription ){
                        return
                    }
                }
                self.SaveClicked = false
                
                btn.disabled = true
                AddToProjectDirectory(self.existing).then((res)=>{
                    btn.disabled = false
                    if(res=="OK"){
                        self.existing = {
                            id : this.$route.params.id,
                            companyType : companyType,
                            roleId : ''
                        }
                        
                        self.$swal(i18nTranslate('Add User to Directory'), i18nTranslate('User Added to directory Successfully'), 'success')
                        self.GetProjectDirectory(1).then(()=>{
                            self.SetList()
                            resolve(res)
                        }).catch()
                    }else{
                        resolve(res)
                    }
                    
                })
            })
        },
        SaveExe(e){
            const self = this
            return new Promise((resolve)=>{
                const btn = e.target
                self.SaveClicked = true
                const { companyType, company, firstName, lastName, roleId, jobDescription, email, client_company, client_company_address} = self.newUser

                if(companyType==CompanyType.MMOSER){
                    if(!companyType || !firstName || !lastName || !roleId  || !jobDescription || !IsValidEmail(email) ){
                        return
                    }
                }
                else if(companyType==CompanyType.CLIENT){
                    if(!companyType ||  !firstName || !lastName  || !jobDescription || !IsValidEmail(email) ){
                        return
                    }
                }else{
                    if(!companyType || !firstName || !lastName  || !jobDescription || !IsValidEmail(email) ){
                        return
                    }
                }

                if(companyType!=CompanyType.MMOSER){
                    if(self.IsNewCompany){
                        if(!client_company || !client_company_address){
                            return
                        }
                    }else{
                        if(!company){
                            return
                        }
                    }
                }

                btn.disabled = true

                self.newUser.contacts = self.newUser.contacts.filter(u=>u!='')
                self.newUser.projectId = self.$route.params.id
                AddNewToProjectDirectory(self.newUser).then((res)=>{
                    self.SaveClicked = false
                    btn.disabled = false
                    if(res=="OK"){
                        self.newUser = {
                            companyType : self.newUser.companyType,
                            roleId : '',
                            contacts : [''],
                            roleProject : self.newUser.roleProject
                        }

                        console.log(self.newUser, 'herere')
                        self.addedContacts = [{value : ''}]
                        self.$swal(i18nTranslate('Add User to Directory'), 'User Added to directory Successfully', 'success')
                        self.GetProjectDirectory(1).then(()=>{
                            self.SetList()
                            resolve(res)
                        }).catch()
                    }
                    resolve(res)
                }).catch(()=>resolve(false))

            })
        },
        ChangeContact(key,value){
            if(typeof this.newUser.contacts[key]==='undefined'){
                this.newUser.contacts.push(value)
            }else{
                this.newUser.contacts[key] = value
            }
        },
        OpenModal(){
            this.createType = 1
            if(this.$refs.select2UserExist){
                this.$refs.select2UserExist.ClearData()
            }
            this.existing = {
                id : this.$route.params.id,
                companyType : 1,
                roleId : ''
            }
            this.newUser = {
                companyType :  1,
                roleId : '',
                contacts : ['']
            }
            this.IsNewCompany = false
            this.addedContacts = [{value : ''}]
        }
    },
    data(){
        return{
            newUser : {
                companyType : '1',
                roleId : '',
                contacts : ['']
            },
            addedContacts : [{value : ''}],
            SaveClicked : false,
            CompanyTypeList : CompanyTypeList,
            createType: 1,
            existing : {
                id : this.$route.params.id,
                companyType : 1,
            },
            CompanyType : CompanyType,
            IsNewCompany : false,
            ClientCompanyList : [],
        }
    }

}
</script>