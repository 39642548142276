<template> 

    <DoughnutChart :chartData="data" :options="options" :width="60"  ></DoughnutChart>
</template>


<script>
import { DoughnutChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"

Chart.register(...registerables)


export default {
    name : 'Doughnut',
    components : {
        DoughnutChart
    },
    props : {
        data : {
            type : Object,
            default : ()=>{}
        }
    },
    data(){
        return {
            options : {
                plugins : {
                    legend: {
                        display: false
                    },
                    // tooltip: {
                    //     enabled: false
                    // }
                },
                layout: {
                    padding: 0
                },
                cutout : '80%'
            }
        }
    }
}

</script>