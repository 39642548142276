<template>
<div class="alert alert-warning rounded-3 d-flex justify-content-between pt-3" role="button" @click="OpenNotifications">
    <div > {{ $t('Notification') }} </div>
    <div > <span class="badge bg-warning border">{{ data }}</span> </div>
</div>
</template>

<script>
import 'dotenv/config'
import {DashboardGetProjectNotificationCount} from '@/actions/projectActions.js'
export default {
    name : 'NotificationWidget',
    props : ['projectInfo'],
    mounted : async function(){
        const self = this

        const response = await DashboardGetProjectNotificationCount(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods : {
        OpenNotifications(){
            const self = this
            window.location.href = self.dotNetUrl + '/project/notifications/' + self.projectInfo.projectOldId
            // this.$router.push({name : 'NotificationList', params : {id : this.projectInfo._id}})
        }
    },
    data(){
        return {
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            data : 0
        }
    }
} 
</script>