<template>
    <div class="p-3">
      <div class="row ">
        <div class="col-sm-12 col-md-6 col-lg-6 pb-3">
            <div class="d-flex justify-content-start">
              <input v-model="searchParams.search" type="text" @keyup="SeachProject" class="form-control" :placeholder="$t('Search Project Here')">
              <button class="btn btn-outline-primary ms-2 " @click="OpenFilterModal" title="Advance Filter" data-bs-toggle="modal" data-bs-target="#MainFilterModal"><i class="fa fa-filter"></i></button>
            </div> 
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 pb-3">
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="OpenModalCreate" data-bs-toggle="modal" data-bs-target="#MainModal"> <i class="fa-solid fa-plus"></i> {{ $t('Add Project') }}</button>
          </div> 
        </div>
      </div>
      <div class="row" v-if="userProject.totalDocs!=0" >
        <div class="col-sm-12 col-md-12 col-lg-12">
          <Pagination
           :changePage="PageChange" 
           :havePageCount="true"
           :paginationDetails="{totalPages : userProject.totalPages, page : userProject.page}" 
           />
        </div>
      </div>
      <Loader v-if="projectLoading"></Loader>
      <template v-else>
        <div class="row " v-if="userProject.docs.length==0">
          <div class="col-md-12">
            <div class="alert alert-primary d-flex align-items-center" role="alert">
              <i class="fas fa-info-circle fa-2x me-2"></i>  
              <div>
                {{ $t('No data Found') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="userProject.docs.length!=0">
          <div  class="col-sm-12 col-md-6 col-lg-6 pb-3" v-for="proj in userProject.docs" :key="proj._id" >
            <ProjectItem :project="proj" :OpenUpdate="OpenUpdate" :UpdateProjectStatus="UpdateProjectStatus" />
          </div>
          
        </div>
      </template>
      <div class="row" v-if="userProject.totalDocs!=0" >
        <div class="col-sm-12 col-md-12 col-lg-12">
          <Pagination
           :changePage="PageChange" 
           :havePageCount="true"
           :paginationDetails="{totalPages : userProject.totalPages, page : userProject.page}" 
           />
        </div>
      </div>
    </div>
    <!--start of add modal-->
    <Modal idModal="MainModal" :headerTitle="$t('CREATE') +' ' + $t('Project')" modalSize="modal-xl" >
      <div class="row">
        <span class="text-danger">{{saveErrorMessage}}</span>
        <div class="col-md-3">
          <div class="form-group">
            <label  >{{$t('Project Number')}} </label> 
            <!-- <Select2 
            :filterQuery="GetProjectAction"
            indexValue="projectNo"
            labelName="projectNumberAndName"
            placeHolder="Project Number"
            v-model:optionValue="projectItemInfo.projectNo"
            :iniLabel="projectItemInfo.projectNo"
            :onOptionClick="AppendProjectInfo"
            ></Select2> -->
            <input type="text" :value="projectItemInfo.projectNo" disabled :placeholder="$t('Project Number')" class="form-control" />
            <small class="text-warning" v-if="arrayValidator.indexOf('c1')> -1">
              {{ $t('Required') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for=""  class="required-style">{{ $t('Project Name') }}</label>
            <Select2 
            :filterQuery="GetProjectAction"
            indexValue="projectNo"
            labelName="projectNumberAndName"
            :placeHolder="$t('Project Name')"
            labelOtherValue="projectName"
            v-model:optionValue="projectItemInfoId"
            :onOptionClick="AppendProjectInfo"
            ></Select2>
            <small class="text-muted">{{ $t('Start typing to search for a project number or name') }}</small> <br />
            <small class="text-warning" v-if="arrayValidator.indexOf('c2')> -1">
              {{ $t('Required') }}
            </small>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="required-style">{{$t('Currency')}} </label>
            <select class="form-select" v-model="projectItemInfo.currency">
              <option value="">{{ $t('Please Select') }}</option>
              <option v-for="(item, key) in currencyList" :key="key" :value="item._id">{{item.sign}} {{item.name}}</option>
            </select>
            <small class="text-warning" v-if="arrayValidator.indexOf('c4')> -1">
              {{ $t('Required') }}
            </small>
          </div>
        </div>
        <div class="col-md-4">
           <div class="form-group">
              <label  >{{ $t('Office in Charge') }} </label>
              <input type="text" :value="projectItemInfo.officeId" class="form-control" disabled :placeholder="$t('Office in Charge')" list="officeList" @keyup="GetOffice($event.target.value)" >
              <datalist id="officeList">
                <option  v-for="u in officeList" :key="u._id" :value="u.officeName">{{u.officeName}}</option>
              </datalist>
              <small class="text-warning" v-if="arrayValidator.indexOf('c5')> -1">
                {{ $t('Required') }}
              </small>
          </div>

        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="required-style">{{ $t('Contract Type') }} </label>
            <select class="form-select" v-model="projectItemInfo.contractType">
              <option value="">{{ $t('Please Select') }}</option>
              <option :value="item.id" v-for="(item,key) in ContractTypeObj" :key="key" >{{item.value}} </option>
            </select>
            <small class="text-warning" v-if="arrayValidator.indexOf('c8')> -1">
              {{ $t('Required') }}
            </small>
          </div>
          <div class="form-group">
            <label >{{ $t('Contract Type(KC)') }} </label>
            <input type="text" disabled :value="projectItemInfo.cType" class="form-control"/>
          </div>
          <!-- <div class="form-group">
            <label for="" >Contract Type (KC)</label>
            <input type="text" v-model="projectItemInfo.cType" class="form-control" readonly placeholder="Contract Type" >
          </div> -->
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="" >{{ $t('Project Size(ft2)') }}</label>
            <input type="number" class="form-control" disabled :value="projectItemInfo.sf" />
              <!-- <input type="number" v-model="projectItemInfo.size" class="form-control" disabled  >
              <span class="input-group-text" >
                <select class="form-select border-0 pt-0 pb-0" v-model="projectItemInfo.unit_of_measure">
                  <option v-for="(item, key) in  LandMeasurements" :key="key" :value="item">{{item}}</option>
                </select>
              </span> -->
          </div>
          <div class="form-group">
            <label for="" > {{ $t('Project Size(m2)') }}</label>
            <input type="number" class="form-control" disabled :value="projectItemInfo.sm" />
          </div>
        </div>
        <div class="col-md-12">
           <div class="form-group">
            <label for="" >{{ $t('Project Address') }}</label>
            <textarea class="form-control" disabled 
            style="height : 100px; resize:none;"
            v-model="projectItemInfo.projectAddress"  
            :placeholder="$t('Address of the project')"></textarea>
            <small class="text-warning" v-if="arrayValidator.indexOf('c3')> -1">
              {{ $t('Required') }}
            </small>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="form-group">
            <label for="" >{{ $t('Contract Date') }}</label>
            <DatePicker v-model="projectItemInfo.projectConfirmDate" elemId="home-date-contract" :disabled="true" :placeholder="''" />
            <!-- <input type="date" v-model="projectItemInfo.projectConfirmDate" class="form-control" > -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="" >{{ $t('Start Date') }}</label>
            <DatePicker v-model="projectItemInfo.projectStartDate" elemId="home-date-projectStartDate" :disabled="true" :placeholder="''" />
            <!-- <input type="date" v-model="projectItemInfo.projectStartDate" class="form-control" > -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="" >{{ $t('Handover Date') }}</label>
            <DatePicker v-model="projectItemInfo.projectHandOverDate" elemId="home-date-projectHandOverDate" :disabled="true" :placeholder="''" />
            <!-- <input type="date" v-model="projectItemInfo.projectHandOverDate" class="form-control" > -->
          </div>
        </div>
        <hr class="mt-3" />
        <h6 class="fw-bold">{{ $t('Client Company Details') }}</h6>
        <small class="text-primary">{{ $t('At least one Client Company is needed to Proceed') }} </small>
        <div class="col-md-6">
          <div class="form-group">
            <label class="required-style">{{ $t('Company Name') }} </label>

            <AutocompleteSelect
              v-model:optionValue="projectItemInfo.client_company"
              :filterQuery="SearchCompanyData"
              indexValue="companyName"
              :placeHolder="$t('Company Name')"
              :onOptionClick="AppendDataOfCompany"
              :onClear="OnClientSearchClear"
              ></AutocompleteSelect>
            <!-- <input type="text" class="form-control" @keyup="SearchCompanyData($event.target.value)" list="h-project-client-company" v-model="projectItemInfo.client_company"
            placeholder="Company Name"
            @change="AppendDataOfCompany($event.target.value)"
            /> -->
            <small class="text-warning" v-if="arrayValidator.indexOf('c7')> -1">
              {{ $t('Required') }} <br />
            </small>
            <small class="text-muted">{{ $t('You can also search Company name here') }} </small> 
          </div>
          <!-- <datalist  id="h-project-client-company">
            <option  v-for="(item, key) in ClientCompanyList" :key="key" :value="item.companyName" > {{item.companyName}}</option>
          </datalist> -->
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="required-style">{{ $t('Company Address') }} </label>
            <textarea class="form-control" v-model="projectItemInfo.client_company_address" :placeholder="$t('Company Address')"></textarea>
            <small class="text-warning" v-if="arrayValidator.indexOf('c7')> -1">
              {{ $t('Required') }}
            </small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label >{{ $t('Company Phone No') }}</label>
            <input type="text" v-model="projectItemInfo.company_phone" class="form-control" :placeholder="$t('Company Phone No')">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label >{{ $t('Company Email') }} </label>
            <input type="email" v-model="projectItemInfo.company_email" class="form-control" :placeholder="$t('Company Email')" >
          </div>
        </div>
        <hr class="mt-3" />
        <div class="col-md-12">
          <h6 class="fw-bold">{{ $t('Directory') }}</h6>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t('User') }}</th>
                <th>{{ $t('Role') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              
              <tr class="" v-if="directoryLoading">
                <td class="text-center" colspan="3">
                  <Loader></Loader>
                </td>
              </tr>
              <tr class="" v-if="projectDirectory.length==0">
                <td class="text-center" colspan="3">{{ $t('No data Found') }}</td>
              </tr>
              <tr v-for="(items,key) in projectDirectory" :key="key" >
                <td :class="!items.isFound ? 'text-decoration-line-through' : ''">{{items.name}}</td>
                <td :class="!items.isFound ? 'text-decoration-line-through' : ''">{{items.roleName}}</td>
                <td>
                  <span role="button" class="text-danger " @click="RemoveFromDirectory(items.name)" v-if="items.isFound">
                    <i class="fa-solid fa-minus"></i> {{ $t('Remove') }}
                  </span>
                  <span v-else>
                    {{ $t('No Record') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div> 
      </div>
      <div class="d-flex justify-content-end">
        <span class="text-primary">
          <i class="fa-solid fa-circle-info"></i> {{ $t('After creating the project you will be redirected to an Authentication Page for Setting up the folder in the Sharepoint') }}
        </span>
      </div>
      <template v-slot:actions>
        <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
        <button type="button" class="btn btn-primary" @click="CreateProject($event.target)" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
      </template>
    </Modal>
    <!--end of add modal-->
    <!--start of Filter modal-->
    <Modal idModal="MainFilterModal" :headerTitle="$t('Advance Filters')"  >
      <form>
        <div class="form-group">
          <label>{{ $t('Created By') }}</label>
          <Select2 
            :filterQuery="SearchSpecificUser"
            indexValue="_id"
            labelName="fullname"
            :placeHolder=" $t('search') + ' ' + $t('User')"
            v-model:optionValue="searchParams.createdBy"
          ></Select2>
        </div>
        <div class="form-group">
          <label>{{ $t('Client') }}</label>
          <Select2 
            :filterQuery="SearchSpecificClient"
            indexValue="_id"
            labelName="fullname"
            :placeHolder=" $t('search') + ' ' + $t('Client')"
            v-model:optionValue="searchParams.clientId"
          ></Select2>
        </div>
        <div class="form-group">
          <label>{{ $t('Office') }}</label>
          <select class="form-select text-capitalize">
            <option value="">{{ $t('Please Select') }}</option>
            <option v-for="(item, key) in directory.office" :key="key" :value="item._id">{{item.officeName}}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t('Region') }}</label>
          <select class="form-select text-capitalize">
            <option value="">{{ $t('Please Select') }}</option>
            <option v-for="(item, key) in directory.region" :key="key" :value="item._id">{{item.regionName}}</option>
          </select>
        </div>
      </form>
      <template v-slot:actions>
        <button type="button" ref="closeSearchModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
        <button type="button" class="btn btn-primary" @click="AdvanceFilterClick"  ><i class="fa-solid fa-magnifying-glass"></i> {{ $t('Save Changes') }}</button>
      </template>
    </Modal>
    <!--end of Filter modal-->
    
</template>

<script src="@/assets/projectJs/Home.js" />

