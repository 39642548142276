<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white">
            <h6 class="fw-bold mb-0"><i class="fa-solid fa-arrow-right-arrow-left"></i> {{ $t('Change Order Log') }}</h6>
        </div>
        <div class="card-body p-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>{{ $t('S/N') }}</th>
                        <th>{{ $t('Change Request No.') }}</th>
                        <th>{{ $t('Change Order No.') }}</th>
                        <th>{{ $t('Description') }}</th>
                        <th> {{ $t('Date') }}</th>
                        <th>{{ $t('Submitted Amount') }}</th>
                        <th>{{ $t('Status') }}</th>
                        <th>{{ $t('Approved Amount') }}</th>
                        <th>{{ $t('Attachment') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="data.length==0">
                        <td class="text-center" colspan="9">{{ $t('No data Found') }}</td>
                    </tr>
                    <tr v-for="(item,key) in data" :key="key">
                        <td> {{ key + 1 }}</td>
                        <td> 
                            <a :href="dotNetUrl + '/change-request/' +projectInfo.projectOldId+ '/bill-of-quantity/' + item.id" class="link-style-custom"> 
                                {{ item.changeRequestNumber }} 
                            </a> 
                        </td>
                        <td>{{ item.variationNumber || '-' }}</td>
                        <td><small>{{ item.description }}</small></td>
                        <td>{{ ReformatDate(item.initiatedDate) }}</td>
                        <td>{{item.currencySign}}{{ ReformatNumber(item.expectedAmount) }}</td>
                        <td><span :class="color[item.status - 1]">{{item.statusName}}</span></td>
                        <td :class="{  'text-danger' :item.actualAmount < 0}" >{{item.currencySign}}{{ ReformatNumber(item.actualAmount) }}</td>
                        <td>
                            <a :href="dotNetUrl + '/change-request/' +projectInfo.projectOldId+ '/bill-of-quantity/' + item.id" class="link-style-custom"> 
                                {{ $t('Open') }}
                            </a> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center"> <a :href="dotNetUrl + '/change-request/'+projectInfo.projectOldId" class="link-style-custom">{{ $t('Open') }}</a></div>
        </div>
    </div>
</template>


<script>
import {DashboardGetProjectChangeRequest} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate} from '../../helpers/utilities.js'
import 'dotenv/config'
export default {
    name : 'ChangeRequestList',
    props : ['projectInfo'],
    data(){
        return {
            data : [],
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            color : ['text-muted','text-primary','text-primary','text-warning','text-danger','text-success','text-success']
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetProjectChangeRequest(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,ReformatDate
    }
    
} 
</script>