import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true

// export const GetProjectFromProjectNo = async (projectNo, check = false) =>{
//     return axios.get(`${PROJECT_INFO_API}/api/project/byProjectNo?projectno=${projectNo}&check=${check}`
//     ,{
//         headers :  {
//             Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_REST)
//         }
//     })
//     .then((res)=>{
//         return res.data
//     }).catch(()=>[])
// }

export const GetTokenSharepointFromAuth = async () =>{
    return axios.get(`${MAIN_API}/api/auth/token/sharepoint/get`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetSystemSettings = async () =>{
    return axios.get(`${MAIN_API}/api/project/get/system/settings`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const UpdateProjectSharepointFolders = async (data) =>{
    return axios.put(`${MAIN_API}/api/project/me/sharepoint/folders`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetProjectSharepointFolders = async (projectId) =>{
    return axios.get(`${MAIN_API}/api/project/me/sharepoint/folders`
    ,{
        params : {
            projectId : projectId,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetProjectFromProjectNo = async (projectNo, check = false) =>{
    return axios.get(`${MAIN_API}/api/project/external/project/search`
    ,{
        params : {
            projectNo : projectNo,
            check  :check
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const SearchUserFromKC = async (search) =>{
    return axios.get(`${MAIN_API}/api/project/external/user/search`
    ,{
        params : {
            search : search,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

// export const SearchUserFromKC = async (search) =>{
//     return axios.get(`${PROJECT_INFO_API}/api/employee/searchbyname`
//     ,{
//         params : {
//             search : search
//         },
//         headers :  {
//             Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_REST)
//         }
//     })
//     .then((res)=>{
//         return res.data
//     }).catch(()=>[])
// }


export const GetMyProjects = async (params) =>{
    return axios.get(`${MAIN_API}/api/project/me`,
    {
        params :params,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetProject = async (id) =>{
    return axios.get(`${MAIN_API}/api/project/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const SearchClientData = async (search) =>{
    return axios.get(`${MAIN_API}/api/project/client/search`,
    {
        params : {
            search : search || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}
export const SearchCompanyData = async (search) =>{
    return axios.get(`${MAIN_API}/api/project/company/search`,
    {
        params : {
            search : search || '',
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const UpdateCompanyOnProject = async (data) =>{
    return axios.put(`${MAIN_API}/api/project/directory/company/update`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetProjectDirectory = async (id,page = 1,search = "") =>{
    return axios.get(`${MAIN_API}/api/project/${id}/directory?page=${page}&seach=${search}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetProjectLogs = async (id, page = 1) =>{
    return axios.get(`${MAIN_API}/api/project/${id}/logs/${page}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const GetUserProjectPermission = async (projectId) =>{
    return axios.get(`${MAIN_API}/api/project/${projectId}/directory/permission/me`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}


export const AddProject = async (project) =>{

    return axios.post(`${MAIN_API}/api/project/add`,
    project,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res
    }).catch((err)=>err.response.data)
}

export const AddToProjectDirectory = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/${data.id}/directory`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddNewToProjectDirectory = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/${data.projectId}/directory/new`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateUserToProjectDirectory = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/${data.id}/directory/update`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const RemoveToProjectDirectory = async (id, userId) =>{
    return axios.put(`${MAIN_API}/api/project/${id}/directory`,
    { userId : userId},
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateProject = async (project) =>{

    return axios.put(`${MAIN_API}/api/project/update`,
    project,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateProjectStatus = async (projectId) =>{
    return axios.put(`${MAIN_API}/api/project/archive`,
    {projectId : projectId},
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

//to be removed
export const UpdateUserProjectPermission = async (directoryId) =>{
    return axios.put(`${MAIN_API}/api/project/${directoryId}/directory/permission`,
    {},
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then(()=>{
        return true
    }).catch(()=>false)
}

export const UpdateUserProjectAccess = async (directoryId, access) =>{
    return axios.put(`${MAIN_API}/api/project/directory/user/access/${directoryId}`,
    {
        access : access
    },
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}




export const ValidateUserFromDirectory = async (array) =>{
    return axios.get(`${MAIN_API}/api/project/validate/directory?data=${JSON.stringify(array)}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

//#region Process
export const CreateProjectProcess = async (project) =>{

    return axios.post(`${MAIN_API}/api/project/${project.id}/process`,
    project,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const GetProjectProcessList = async (params) =>{
    return axios.get(`${MAIN_API}/api/project/${params.id}/process`,
    {
        params : params,
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.status)
}

export const UpdateProjectProcess = async (project) =>{

    return axios.put(`${MAIN_API}/api/project/${project.id}/process/${project.processId}`,
    project,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetProjectProcessItem = async (params) =>{
    return axios.get(`${MAIN_API}/api/project/${params.id}/process/${params.processId}/items`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const CreateStepProjectProcess = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/step`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const ChangeOrdertepProjectProcess = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/step/order`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const RemoveStepProjectProcess = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/step/remove`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const AddUserToStepProcess = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/user`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const RemoveUserToStepProcess = async (data) =>{

    return axios.put(`${MAIN_API}/api/project/${data.id}/process/${data.processId}/items/user/remove`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const SearchProjectProcessView = async (projectId ="", search ="") =>{
    return axios.get(`${MAIN_API}/api/project/process/search/view`,
    {
        params : {
            search : search,
            projectId  :projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const SearchProjectProcessApproval = async (projectId ="", search ="") =>{
    return axios.get(`${MAIN_API}/api/project/process/search/approval`,
    {
        params : {
            search : search,
            projectId  :projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}
//#endregion

//#region Approval List actions
export const GetApprovalList = async (data) =>{
    return axios.get(`${MAIN_API}/api/project/${data.id}/approval/list`,
    {
        params : {
            search : data.search || '',
            page : data.page || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const GetWorkflowList = async (data) =>{
    return axios.get(`${MAIN_API}/api/project/workflow/list/${data.id}`,
    {
        params : {
            search : data.search || '',
            page : data.page || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}
//#endregion

//#region Comment Section
export const GetCommentsList = async (data) =>{
    return axios.get(`${MAIN_API}/api/project/comment/list/${data.id}`,
    {
        params : {
            page : data.page || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}

export const AddCommentToList = async (data) =>{
    return axios.post(`${MAIN_API}/api/project/comment/${data.id}`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>{ return {} })
}
//#endregion


//boq
export const GetBoqList = async (projectId, page = 1, search ="", limit = 15) =>{
    return axios.get(`${MAIN_API}/api/boq/list/${projectId}?page=${page}&search=${search}&limit=${limit}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

export const GetQuotationList = async (data) =>{
    return axios.get(`${MAIN_API}/api/boq/quotation/list/${data.id}`,
    {
        params : {
            search : data.search || '',
            page : data.page || 1,
            limit : data.limit || 15
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

//boq also
export const GetCurrencyList = async () =>{
    return axios.get(`${MAIN_API}/api/boq/currency`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

//#region Dashboard management
export const DashboardGetTradePackageProcurement = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/trade-package-procurement`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetTradePackageProcurementPerformance = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/trade-package-procurement-performance`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetPaymentPositionToVendor = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/payment-position-vendor`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetPaymentPositionToClient = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/payment-position-client`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectPaymentPosition = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/payment-position-net`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectCummulativePaymentPosition = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/payment-position-cummulative`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectActionables = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/actionables`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectPurchaseOrders = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/purchase-orders`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectProgressClaims = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/progress-claims`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectChangeRequest = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/change-request`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectClientInvoice = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/client-invoice`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectBudgetBreakdown = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/project-budget-breakdown`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectOverviewTotals = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/project-overview-totals`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectActionablesFirstPart = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/project-actionables-part-1`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}
export const DashboardGetProjectNotificationCount = async (id) =>{
    return axios.get(`${MAIN_API}/api/internal-dashboard/${id}/project-notification-count`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>null)
}

//#endregion Dashboard management







