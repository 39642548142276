<template>
    <div class="card card-round border">
        <div class="card-header bg-dark text-white"><h6 class="fw-bold mb-0"> <i class="fa-solid fa-money-bills"></i> {{ $t('Net Cash Position') }}</h6></div>
        <div class="card-body">
            <div class="text-center">
                <h4 class="fw-bold">{{projectInfo.currencyId?.sign}} {{ ReformatNumber(data.paymentPosition) }}</h4>
                <!-- <a class="text-primary small" role="button">View Full Breakdown</a> -->
            </div>
        </div>
    </div>
</template>

<script>
import {DashboardGetProjectPaymentPosition} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
export default {
    name : 'NetCashPosition',
    props : ['projectInfo'],
    data(){
        return {
            data : { paymentPosition : 0}
            
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetProjectPaymentPosition(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,
    }
    
} 
</script>