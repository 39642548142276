<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white ">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-info-circle"></i> {{ $t('Cummulative Payment Position') }}</h6>
        </div>
        <div class="card-body">
            <div class="text-center">
                <h5> {{ $t('Total') }}</h5>
                <h5 class="fw-bold">{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.contractSum + data.variation)}}</h5>
                <div class="d-flex justify-content-between text-primary">
                    <span> {{ $t('Paid') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.clientPaid)}}</span>
                </div>
                <div class="d-flex justify-content-between text-info">
                    <span> {{ $t('Invoice Approved') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.approvedInvoice)}}</span>
                </div>
                <div class="d-flex justify-content-between text-info">
                    <span> {{ $t('Invoice to Approve') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.invoiceToApprove)}}</span>
                </div>
                <div class="d-flex justify-content-between text-info">
                    <span> {{ $t('To Invoice') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.toInvoice)}}</span>
                </div>
                <div class="d-flex justify-content-between text-success">
                    <span> {{ $t('Contract Sum') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.contractSum)}}</span>
                </div>
                <div class="d-flex justify-content-between text-warning">
                    <span> {{ $t('Variation') }}</span>
                    <span>{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.variation)}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DashboardGetProjectCummulativePaymentPosition} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
export default {
    name : 'NetCashPosition',
    props : ['projectInfo'],
    data(){
        return {
            data : { 
                approvedInvoice : 0,
                clientPaid : 0,
                contractSum : 0,
                invoiceToApprove : 0,
                toInvoice : 0,
                variation : 0,
            }
            
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetProjectCummulativePaymentPosition(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,
    }
    
} 
</script>