<template>
  <div class="">
    <h3>Redirecting please wait... </h3>
  </div>
</template>
<script >
  import { defineComponent } from "@vue/runtime-core"
  import { useCookies } from "vue3-cookies"
  import { DecryptToken } from "../helpers/jwtHelpers.js" 
  import 'dotenv/config'


  export default defineComponent({
    setup(){
      const { cookies } = useCookies()
      return { cookies }
    }, 
    created(){
      if(this.cookies.get('_auzer')){
        window.location.href = "/"
      }

      const REST_API = process.env.VUE_APP_REST_MAIN_API
      const { code, redir}  = this.$route.query
      if(code){
        DecryptToken(code,0).then((res) =>{
          if(res){
            const _redirreq = this.cookies.get('_redirreq')
            this.cookies.remove('_redirreq')
            this.cookies.set('_auzer', 'checker','1d','/','',true,'Strict')
            window.location.href = _redirreq
          }else{
            this.$router.push("not-found")
          }
        })
      }
      else if(!this.cookies.get('_auzer')){
        this.cookies.set('_redirreq', window.location.origin + redir ,'15m','/','',true,'Strict')
        window.location.href = REST_API + "/api/auth/sso?origin="+window.location.origin
      }
    }
  })
    
</script>
