import  { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/customs/custom.css"
import "@/assets/customs/new-style.css"
import '@/assets/customs/custom-breadcrumbs-pointed.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import router from './router'
import store from './store'

import 'js-datepicker/dist/datepicker.min.js'
import 'js-datepicker/dist/datepicker.min.css'

import i18n from './translations/i18n.js'

const options = {
    confirmButtonText: i18n.global.t('Proceed'),
    cancelButtonText: i18n.global.t('Cancel'),
}


createApp(App).use(i18n).use(router).use(store).use(VueSweetalert2,options).mount('#app')
