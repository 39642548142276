import { mapGetters,mapActions } from 'vuex'
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import CreateModalUser from '@/components/projectInformation/CreateModalUser.vue'
import {SearchRolesDirectory, GetUserProjectDirectory, SearchCompanies,GetCompanyTypes} from '@/actions/directoryActions.js'
import {UpdateUserToProjectDirectory, UpdateCompanyOnProject} from '@/actions/projectActions.js'
import dayjs from 'dayjs'
import {CompanyTypeList, IsValidEmail, CompanyType, ModuleActionList} from '@/helpers/utilities.js'

import  i18n from '@/translations/i18n.js'
const i18nTranslate = i18n.global.t

export default {
    name : 'DirectoryInfo',
    components : {
        CreateModalUser,
        Modal,
        Select2
    },
    props : ['openPermission'],
    data(){
        return {
            searchUser : '',
            isNotValid : false,
            directorySearch : '',
            ArrayList : [],
            CompanyTypeList : CompanyTypeList,
            CompanyType : CompanyType,
            updateUser : {
                companyType : '1',
                roleId : '',
                contacts : ['']
            },
            updatedContacts : [],
            userCompany : [],
            roles : [],
            SaveClicked : false,
            isLoadingData : false,
            ModuleActionList : ModuleActionList,
            TradeTypeList : [],
            updateCompanyData : {},
            isUpdateCompanyClicked : false,
            isSavingCompany : false,
        }
    },
    mounted : async function(){
        this.ArrayList = this.projectDirectory
        const roleList = await SearchRolesDirectory().catch()
        this.roles = roleList

        this.TradeTypeList =  await GetCompanyTypes()
    },
    watch : {
        projectDirectory(val){
            this.ArrayList = val
        }
    },
    methods : {
        ...mapActions(['FilterUserByName','updateSearchUserDirectory', 'ProjectDirectoryAdd','ProjectDirectoryRemove', 'ChangeUserPermission', 'GetProjectDirectory']),
        IsValidEmail(value){
            return IsValidEmail(value)
        },
        formatDate(date){
            return dayjs(date).format("YYYY-MM-DD")
        },
        SearchCompanies(search){
            return SearchCompanies({search : search})
        },
        OpenModalUpdateCompany(company){
            let filterExternal = this.projectDirectory.filter(u=>u.companyType!=CompanyType.MMOSER)
            let getFirst = filterExternal.find(u=>u.userId.company.companyName==company)
            let companyData = getFirst.userId.company
            this.updateCompanyData._id = companyData._id
            this.updateCompanyData.companyName = companyData.companyName
            this.updateCompanyData.address = companyData.address
            this.updateCompanyData.mainTrade = companyData.mainTrade || ''
            this.updateCompanyData.email = companyData.email
            this.updateCompanyData.phone = companyData.phone
            this.updateCompanyData.fax = companyData.fax
            this.updateCompanyData.companyType = companyData.companyType
        },
        UpdateCompany(){
            const self = this
            const data = self.updateCompanyData

            self.isUpdateCompanyClicked = true
            if(!data._id || !data.companyName || !data.address){
                return
            }

            self.isUpdateCompanyClicked = false
            self.isSavingCompany = true
            UpdateCompanyOnProject(data).then((res)=>{
                self.isSavingCompany = false
                if(res!='OK'){
                    self.$swal(i18nTranslate('Edit Company'), res, 'warning')
                }else{
                    self.$swal(i18nTranslate('Edit Company'), i18nTranslate('Company Updated Successfully'), 'success')
                    self.$refs.closeUpdateModalCompany.click()
                    self.GetProjectDirectory(1).then(()=>{
                        self.SetList()
                    }).catch()
                }
            })

        },
        Validator(){
            const self = this
            self.isNotValid = false
            if(!self.directorySearch){
                self.isNotValid = true
            }

            self.ProjectDirectoryAdd(self.directorySearch).then((res)=>{
                if(res=='OK'){
                    self.$swal(i18nTranslate('Add User to Directory'), '', 'success')
                    self.ArrayList = self.projectDirectory
                }else{
                    self.$swal(res, '', 'warning')
                }
            }).catch()
        },
        RemoveFromDirectory(id){
            const self = this
            self.$swal({
                title: i18nTranslate('Remove User from Directory'),
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: i18nTranslate('Remove'),
                cancelButtonText: i18nTranslate('Cancel'),
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return self.ProjectDirectoryRemove(id).then((res)=>{
                        if(res!='OK'){
                            self.$swal(i18nTranslate('Remove User from Directory'), '', 'warning')
                        }
                        
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.ArrayList = self.projectDirectory
                    self.$swal(i18nTranslate('Remove User from Directory'), '', 'success')
                }
            })
        },
        searchFromDirectory(){
            
            this.ArrayList = this.projectDirectory.filter(z => z.userId.fullname.indexOf(this.searchUser)> -1)
        },
        SetList(){
            this.ArrayList = this.projectDirectory
        },
        changePermission (id){
            const self = this
            self.$swal({
                title: i18nTranslate('Change User Permission'),
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: i18nTranslate('Change'),
                cancelButtonText: i18nTranslate('Cancel'),
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return self.ChangeUserPermission(id).then((res)=>{
                        if(!res){
                            self.$swal(i18nTranslate('Change User Permission'), '', 'warning')
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal(i18nTranslate('Change User Permission'), '', 'success')
                }
            })
        },
        OpenUpdateModal(id){
            const self = this
            self.isLoadingData = true
            GetUserProjectDirectory(id).then(res=>{
                const user = res.userId
                self.updateUser.contacts = []
                self.updatedContacts = []
                self.updateUser.id = res._id
                self.updateUser.firstName = user.firstName
                self.updateUser.lastName = user.lastName
                self.updateUser.email = user.email
                self.updateUser.companyType = res.companyType
                self.updateUser.roleId = res.roleId
                self.updateUser.roleProject = res.roleIdProject
                self.updateUser.jobDescription = res.jobDescription
                self.updateUser.address = user.address
                self.updateUser.contacts = user.contactNumber
                self.updateUser.photoUrl = user.photoUrl
                if(res.userId.company){
                    self.updateUser.company = res.userId.company._id
                    self.updateUser.companyName = res.userId.company.companyName
                }
                
                if(self.updateUser.contacts.length!=0){
                    self.updateUser.contacts.forEach((item)=>{
                        self.updatedContacts.push({value : item})
                    })
                }

                self.isLoadingData = false
            })
        },
        OpenModal(){
            this.$refs.ModalCreateUser.OpenModal()
        },
        UpdateUserRun(e){
            const self = this
            const btn = e.target
            self.SaveClicked = true
            const data = self.updateUser
            const { companyType, company, firstName, lastName, roleId, jobDescription, email} = data

            if(companyType==CompanyType.MMOSER){
                if(!companyType || !firstName || !lastName || !roleId  || !jobDescription || !IsValidEmail(email) ){
                    return
                }
            }
            else if(companyType==CompanyType.CLIENT){
                if(!companyType || !company || !firstName || !lastName  || !jobDescription || !IsValidEmail(email) ){
                    return
                }
            }else{
                if(!companyType || !company || !firstName || !lastName  || !jobDescription || !IsValidEmail(email) ){
                    return
                }
            }

            btn.disabled  = true
            self.SaveClicked = false
            data.contacts = self.updatedContacts.filter(u=>u.value!='').map(u=>u.value)
            UpdateUserToProjectDirectory(data).then(res=>{
                btn.disabled  = false
                if(res=="OK"){
                    self.$refs.closeDirModalUpdate.click()
                    self.GetProjectDirectory(1).then(()=>{
                        self.SetList()
                    }).catch()
                }else{
                    self.$swal(i18nTranslate('Edit User from Directory'), res, 'warning')
                }
            })
        },
        AddNewLineContact(){
            this.updatedContacts.push({value : ''})
        },
        RemoveContact(key){
            this.updatedContacts.splice(key,1)
            this.updateUser.contacts.splice(key,1)
        },
        GroupedCompany(data,companyType){
            if(companyType==CompanyType.MMOSER){
                return ['MMoser']
            }
            const map = data.map(u=>u.userId.company.companyName)
            let grouped = map.filter(function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            })
            return grouped
        },
        CheckLoopRole(data,companyType, company){
            if(companyType==CompanyType.MMOSER){
                return data.filter(u=>u.companyType==CompanyType.MMOSER)
            }else{
                let remap = data.filter(u=>u.companyType==companyType)
                return remap.filter(u=>u.userId.company.companyName==company )

            }
        }
    },
    computed : {
        ...mapGetters(['projectDirectory', 'permission']),
        RoleMMoser(){
            return this.roles.filter(u=>u.isExternal==false)
        }
        
    },
}