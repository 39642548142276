<template>
<div class="card card-round shadow">
    <div class="card-header bg-dark text-white ">
        <h6 class="mb-0 fw-bold"><i class="fa-solid fa-cubes"></i> {{ $t('Procurement Performance') }}</h6>
    </div>
    <div class="card-body p-0">
        <table class="table mb-0">
            <tbody>
                <tr>
                    <td>
                        <div class=" fw-bold">
                            <span> {{ $t('Total Project Budget') }}:</span>
                        </div>
                    </td>
                    <td>
                        {{ projectInfo.currencyId.sign }}{{ ReformatNumber(data.totalProjectBudget) }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class=" fw-bold">
                            <span> {{ $t('Budget for Procured Packages') }}:</span>
                        </div>
                    </td>
                    <td>
                    {{ projectInfo.currencyId.sign }} {{ ReformatNumber(data.tradesProcuredBudget) }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class=" fw-bold">
                            <span> {{ $t('Actual Cost Procured') }}:</span>
                        </div>
                    </td>
                    <td>
                    {{ projectInfo.currencyId.sign }} {{ ReformatNumber(data.tradesProcuredActualCost) }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class=" fw-bold">
                            <span > {{ $t('Total Procured Savings') }}:</span>
                        </div>
                    </td>
                    <td>
                        <div :class="{'text-danger' : data.tradesProcuredBudget - data.tradesProcuredActualCost < 0}">
                            {{ projectInfo.currencyId.sign }}{{ ReformatNumber(data.tradesProcuredBudget - data.tradesProcuredActualCost) }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class=" fw-bold">
                            <span class=" "> {{ $t('Remaining Budget to Procure') }}:</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            {{ projectInfo.currencyId.sign }}{{ ReformatNumber(data.totalProjectBudget - data.tradesProcuredBudget) }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import {DashboardGetTradePackageProcurementPerformance} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
export default {
    name : 'TotalTradesProcured',
    props : ['projectInfo'],
    data(){
        return {
            data : { 
                tradesProcuredBudget : 0, 
                tradesProcuredActualCost : 0,
                totalProjectBudget : 0
            }
            
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetTradePackageProcurementPerformance(self.projectInfo._id)
        if(!response)
            return
        self.data = response.data
    },
    methods :{
        ReformatNumber,
    }
    
} 
</script>