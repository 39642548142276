<template>
    <div class="card border-top">
        <div class="card-header bg-white">
            <div class="row">
                <!-- d-flex align-items-center -->
                <div class="col-md-3">
                    <div class="input-group mb-3">
                        <input class="form-control" type="text" autocomplete="false"  :placeholder="$t('Search Here')" v-model="searchUser" >
                        <button class="btn btn-outline-primary rounded-0" type="button" @click="searchFromDirectory"><i class="fa-solid fa-magnifying-glass"></i>{{ $t('Search') }} </button>
                    </div>
                    
                </div>
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <h6 class="fw-bold mb-0">{{ $t('Project Directory') }}</h6>
                </div>
                <div class="col-md-3 d-flex justify-content-end align-items-center">
                    <button class="btn btn-primary me-1"
                    v-if="permission.indexOf(ModuleActionList.DIRECTORY_CREATE)>-1"
                    @click="OpenModal"
                    data-bs-toggle="modal" data-bs-target="#AddToDirectory"
                    ><i class="fa-solid fa-plus"></i> {{ $t('Add User to Directory') }}</button>
                </div>
            </div>
        </div>
    </div>

    <div v-for="(item,key) in  CompanyTypeList" :key="key">
        <div class="accordion pt-2 " :id="'accordionGroupings'+key" v-if="ArrayList.findIndex(u=>u.companyType==item.id)!=-1"  >
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                    <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + key" aria-expanded="true" aria-controls="collapseOne">
                        <i class="fa-solid fa-users pe-3"></i> {{item.value}}
                    </button>
                </h2>
                <div :id="'collapseOne' + key"   class="accordion-collapse collapse show" aria-labelledby="headingOne" :data-bs-parent="'#accordionGroupings'+key">
                    <div class="accordion-body p-0">
                        <div class="table-responsive">
                            <table class="table mb-0">
                            
                                <tbody>
                                    <tr class="fw-bold">
                                        <td>{{ $t('Name') }}</td>
                                        <td>{{ $t('Role') }}</td>
                                        <td>{{ $t('Name') }}</td>
                                        <td>{{ $t('Contact') }} #</td>
                                        <td>{{ $t('Actions') }}</td>
                                    </tr>
                                    <template  v-for="(company,companyKey) in  GroupedCompany(ArrayList.filter(u=>u.companyType==item.id),item.id)" :key="companyKey">
                                        <tr v-if="item.id!=CompanyType.MMOSER" class="">
                                            <td colspan="4" class="text-capitalize fw-bolder bg-light ps-4">
                                                <i class="fa-solid fa-building-user"></i> {{company}}
                                            </td>  
                                            <td class="bg-light">
                                                <span class="small "
                                                role="button"
                                                v-if="permission.indexOf(ModuleActionList.DIRECTORY_EDIT)>-1"
                                                data-bs-toggle="modal" data-bs-target="#EditCompanyModal" @click="OpenModalUpdateCompany(company)"
                                                ><i class="fa-solid fa-pencil"></i> {{ $t('Edit') }} </span>
                                            </td>
                                        </tr>
                                        <tr v-for="(child,childKey) in  CheckLoopRole(ArrayList,item.id,company)" :key="childKey">
                                            <td class="text-capitalize ps-5"> 
                                                <img :src=" child.userId.photoUrl!='' && (typeof child.userId.photoUrl !=='undefined') ? child.userId.photoUrl : '/images/placeholder-user.png'"
                                                @error="(e)=>{e.target.src = '/images/placeholder-user.png';}"
                                                class="img-circle img-xs" >
                                                {{child.userId.fullname}}
                                            </td>
                                            <td class="text-capitalize"> {{$t(child.roleIdProject)}} </td>
                                            <td> <a :href="'mailto:' + child.userId.email" class="ms-1"> {{child.userId.email}}</a> </td>
                                            <td v-if="child.userId.contactNumber.length!=0"> 
                                                <a :href="'tel:' + child.userId.email" class="ms-1" v-for="(c,i) in child.userId.contactNumber" :key="i"> 
                                                    {{c}}
                                                </a >

                                            </td>
                                            <td v-else>
                                                <span class="text-muted"> {{ $t('No Contact number')}}</span>
                                            </td>
                                            <td>
                                                <span class="small pe-2"
                                                role="button"
                                                v-if="permission.indexOf(ModuleActionList.DIRECTORY_EDIT)>-1"
                                                @click="OpenUpdateModal(child._id)"
                                                data-bs-toggle="modal" data-bs-target="#ModalUpdateUser"
                                                ><i class="fa-solid fa-pencil"></i> {{ $t('Edit') }}</span>
                                                <span  class="small pe-2"
                                                role="button"
                                                @click="openPermission(child)" 
                                                data-bs-toggle="modal" data-bs-target="#PermissionModal"
                                                v-if="permission.indexOf(ModuleActionList.DIRECTORY_PERMISSION)>-1&&item.id==CompanyType.MMOSER"
                                                ><i class="fa-solid fa-key"></i>  {{ $t('Access') }} </span>
                                                <!-- <a href="#" class="text-primary  pe-1" @click="changePermission(p._id)" title="Change Permission">
                                                    <i :class="p.isAdmin ? 'fa-solid fa-lock' : 'fas fa-lock-open'"></i>
                                                </a> -->
                                                <span  role="button" class="text-danger small"
                                                @click="RemoveFromDirectory(child.userId._id)" 
                                                v-if="permission.indexOf(ModuleActionList.DIRECTORY_REMOVE)>-1"
                                                >
                                                    <i class="fas fa-trash"></i>  {{ $t('Remove') }}
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CreateModalUser
    v-if="permission.indexOf(ModuleActionList.DIRECTORY_CREATE)>-1"
    idModal="AddToDirectory"
    :headerTitle="$t('Add User to Directory')"
    :SetList="SetList"
    :roleList="RoleMMoser"
    ref="ModalCreateUser"
    ></CreateModalUser>
    <Modal  idModal="ModalUpdateUser" :headerTitle="$t('UPDATE') + ' ' + $t('User') " modalSize="modal-xl" >
        <fieldset :disabled="isLoadingData">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" >
                        <label >{{ $t('Company Type') }}</label>
                        <input type="text" class="form-control-plaintext" readonly :value="CompanyTypeList[updateUser.companyType - 1].value" />
                    </div>
                    <div class="form-group" v-if="CompanyType.MMOSER!=updateUser.companyType">
                        <label class="required-style">{{ $t('Company Name') }} </label>
                        <!-- <input type="text" class="form-control" placeholder="Company Name" v-model="updateUser.company" /> -->
                        <Select2 
                        :filterQuery="SearchCompanies"
                        indexValue="_id"
                        labelName="companyName"
                        :placeHolder="$t('You can also search Company name here')"
                        ref="select2UsercompanyUpdate"
                        :iniLabel="updateUser.companyName"
                        :optionValue="updateUser.company"
                        v-model:optionValue="updateUser.company"
                        ></Select2>
                        <!-- <a class="text-primary small" href="#">{{ $t('Not able to Find Company? Click here to create') }}</a> -->
                        <small class="text-warning" v-if="!updateUser.company&&SaveClicked">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group">
                        <label class="required-style"> {{ $t('First Name') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('First Name')" v-model="updateUser.firstName" />
                        <small class="text-warning" v-if="!updateUser.firstName&&SaveClicked">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group">
                        <label class="required-style">{{ $t('Last Name') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('Last Name')" v-model="updateUser.lastName" />
                        <small class="text-warning" v-if="!updateUser.lastName&&SaveClicked">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group" v-if="CompanyType.MMOSER==updateUser.companyType">
                        <label class="required-style"> {{ $t('Role in Project') }}</label>
                        <select class="form-select" v-model="updateUser.roleId">
                            <option value="">{{ $t('Please Select') }}</option>
                            <option v-for="(item, key) in RoleMMoser" :key="key" :value="item._id">{{ $t(item.roleName)}}</option>
                        </select>
                        <small class="text-warning" v-if="!updateUser.roleId&&SaveClicked">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group" v-else-if="CompanyType.CLIENT==updateUser.companyType" >
                        <label class="required-style"> {{ $t('Role in Project') }}</label>
                        <input type="text" class="form-control-plaintext" readonly value="Client" />
                    </div>
                    <div class="form-group" v-else>
                        <label class="required-style"> {{ $t('Role in Project') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('Role in Project')" v-model="updateUser.roleProject" />
                        <small class="text-warning" v-if="!updateUser.roleProject&&SaveClicked">{{ $t('Required') }}</small>
                    </div>

                    <div class="form-group">
                        <label class="required-style"> {{ $t('Job Description') }} </label>
                        <input type="text" class="form-control" :placeholder="$t('Role in Project')" v-model="updateUser.jobDescription" />
                        <small class="text-warning" v-if="!updateUser.jobDescription&&SaveClicked">{{ $t('Required') }}</small>
                    </div>
                    
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="required-style"> {{ $t('Email') }}</label>
                        <input type="text" class="form-control" placeholder="sample@gmail.com" v-model="updateUser.email" />
                        <small class="text-warning" v-if="(!updateUser.email&&SaveClicked) || !IsValidEmail(updateUser.email)">{{ $t('Required') }}</small>
                    </div>
                    <div class="form-group">
                        <label> {{ $t('Photo Url') }}</label>
                        <input type="text" class="form-control" placeholder="https://url" v-model="updateUser.photoUrl" />
                    </div>
                    <div class="form-group">
                        <label> {{ $t('Address') }}</label>
                        <textarea class="form-control" v-model="updateUser.address" :placeholder="$t('Address')"></textarea> 
                    </div>
                    <div class="form-group" v-for="(item, key) in updatedContacts" :key="key">
                        <div class="d-flex justify-content-between">
                            <label>  {{ $t('Contact Number') }}</label>
                            <small @click="RemoveContact(key)" class="text-warning" role="button"><i class="fa-solid fa-minus"></i>  {{ $t('Remove') }}</small>    
                        </div>
                        <input type="text" class="form-control" placeholder="+6312345678910" v-model="item.value" />
                    </div>
                    <div>
                        <small @click="AddNewLineContact" class="text-primary" role="button"><i class="fa-solid fa-plus"></i> {{ $t('Add Contact Number') }}</small>
                    </div>
                    
                </div>
            </div>
        </fieldset>
        <template v-slot:actions>
            <button type="button" ref="closeDirModalUpdate" class="btn btn-outline-danger" data-bs-dismiss="modal">X  {{ $t('Cancel') }}</button>
            <div v-if="!isLoadingData">
                <button type="button" class="btn btn-primary" @click="UpdateUserRun" ><i class="fas fa-save"></i> {{ $t('Save Changes') }}</button>
            </div>
        </template>
    </Modal>
    <Modal idModal="EditCompanyModal" :headerTitle="$t('Edit Company')">
        <div class="form-group">
            <label class="required-style">{{ $t('Company Name') }} </label>
            <input type="text" class="form-control" :placeholder="$t('Company Name')" v-model="updateCompanyData.companyName" />
            <small class="text-warning" v-if="isUpdateCompanyClicked&&!updateCompanyData.companyName">{{$t('Required')}}</small>
        </div>
        <div class="form-group">
            <label class="required-style">{{ $t('Company Address') }} </label>
            <textarea class="form-control" v-model="updateCompanyData.address" :placeholder="$t('Company Address')"></textarea>
            <small class="text-warning" v-if="isUpdateCompanyClicked&&!updateCompanyData.address"> {{$t('Required')}} </small>
        </div>
        <div class="form-group" v-if="updateCompanyData.companyType==CompanyType.VENDOR">
            <label >Main Trade {{ $t('Company Name') }}</label>
            <select class="form-select" v-model="updateCompanyData.mainTrade">
                <option value="">{{ $t('Please Select') }}</option>
                <template v-for="(item, key) in TradeTypeList" :key="key">
                    <option :value="item._id">{{item.companyTypeName}}</option>
                </template>
            </select>
        </div>
        <div class="form-group">
            <label>{{ $t('Company Email') }}</label>
            <input type="text" class="form-control" :placeholder="$t('Company Email')" v-model="updateCompanyData.email" />
        </div>
        <div class="form-group">
            <label >{{ $t('Company Phone No') }}</label>
            <input type="text" class="form-control" :placeholder="$t('Company Phone No')" v-model="updateCompanyData.phone" />
        </div>
        <div class="form-group">
            <label >{{ $t('Fax') }}</label>
            <input type="text" class="form-control" :placeholder="$t('Fax')" v-model="updateCompanyData.fax" />
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeUpdateModalCompany" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button class="btn btn-primary" :disabled="isSavingCompany" @click="UpdateCompany" ><i class="fa-solid fa-floppy-disk"></i> {{ $t('Save Changes') }}</button>
        </template>
    </Modal>
</template>
<script src="@/assets/projectJs/components/DirectoryInfo.js" />