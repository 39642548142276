<template>
    <ul class="progressbar-custom ">
        <li v-for="(item,key) in stepList" :key="key" :data-name="item.roleId.roleName" :class="classLi" @click="clickStepper(item)" >
            <div class="card floating-card shadow  text-black" v-if="openStep==item._id" @click.stop >
                <div class="card-header bg-white">
                    <h6>Approvers</h6>
                </div>
                <div class="card-body">
                    <form class="row ">
                        <template v-for="(child, key) in relatedList.filter(u=>u.roleId==item.roleId._id)" :key="key">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control-plaintext text-center text-capitalize h6" readonly :value="child.relatedUserId.fullname" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Date</label>
                                    <input type="text" class="form-control-plaintext text-center h6" readonly :value="child.approveDate || '-'" />
                                </div>
                            </div>
                        </template>
                    </form>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name : 'ProcessStepList',
    props : ['stepList','relatedList','onClickStep'],
    data(){
        return {
            openStep : '',
            classLi : 'width-5-per'
        }
    },
    updated :function(){
        this.classLi = this.stepList.length < 5 ? 'width-'+ this.stepList.length +'-per' : 'width-5-per'
    },
    mounted :function(){
        this.classLi = this.stepList.length < 5 ? 'width-'+ this.stepList.length +'-per' : 'width-5-per'
    },
    methods: {
        clickStepper(item){
            
            this.openStep = this.openStep==item._id ? '' : item._id
            this.onClickStep(item)
        }
    },

}
</script>
<style scoped>
    .width-1-per{
        width:  50%;
    }
    .width-2-per{
        width:  50%;
    }
    .width-3-per{
        width:  30%;
    }
    .width-4-per{
        width:  25%;
    }
    .width-5-per{
        width:  20%;
    }
</style>
