<template>
    <div class="card card-round shadow" >
        <div class="card-header  d-flex justify-content-between mb-0 bg-dark text-white">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-file-signature"></i> {{ $t('Bill of Quantities') }}</h6>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive" >
                <table class="table table-striped ">
                    <thead>
                        <tr>
                            <th> {{ $t('Name') }}</th>
                            <th class="text-center"> {{ $t('Total Cost') }}</th>
                            <th class="text-center"> {{ $t('Total Sell') }}</th>
                            <th class="text-center"> {{ $t('GP Total') }}</th>
                            <th class="text-center"> {{ $t('GP') }} %</th>
                            <th> {{ $t('Status') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in data.docs" :key="key">
                            <td title="Click to View Items" class="text-capitalize">
                                <a :href="boqUrl + '/boq/builder/' + item._id" class="link-style-custom">  {{item.name}}</a>
                            </td>
                                <template v-if="item.summary">
                                <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.costTotal)}}</td>
                                <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.sellTotal)}}</td>
                                <td class="text-end">{{item.currencyId.sign}}{{ReformatNumber(item.summary.gp)}}</td>
                                <td class="text-end">{{ReformatNumber((item.summary.gp / item.summary.sellTotal) * 100)}}%</td>
                            </template>
                            <template v-else>
                                <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                <td class="text-end">{{item.currencyId.sign}}0.00</td>
                                <td class="text-end">-</td>
                            </template>
                            <td>
                                <span :class="'badge ' + ApprovalStageBoqObj[item.approvalStage -1].color">{{ApprovalStageBoqObj[item.approvalStage-1].name}}</span>
                            </td>
                        </tr>
                        <tr v-if="data.length==0">
                            <td colspan="6" class="text-center " >
                                {{ $t('No data Found') }}
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
            <div class="d-flex justify-content-between ps-2 pe-2 pb-2"  v-if="data.length!=0">
                
                <a class="btn btn-outline-primary" 
                data-bs-target="#BoqModalAdd" data-bs-toggle="modal"  @click="()=>{ newBoq={};}"> <i class="fa-solid fa-plus"></i> {{ $t('Add Bill of Quantity') }}</a>
                <a :href="boqUrl + '/boq/' + projectInfo._id" class="link-style-custom pt-2"> {{ $t('View All') }}</a>
            </div>
        </div>
    </div>
    
</template>

<script>
import {GetBoqList} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate, ApprovalStageBoqObj, ModuleActionList} from '../../helpers/utilities.js'
import { mapGetters } from 'vuex'
import 'dotenv/config'
export default {
    name : 'BillOfQuanitityListWidget',
    props : ['projectInfo'],
    data(){
        return {
            data : [],
            ApprovalStageBoqObj,
            newBoq : {},
            boqSaveClicked : false,
            isExecuting : false,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            ModuleActionList,
            quotationList : []
        }
    },
    mounted : async function(){
        const self = this

        const response = await GetBoqList(self.projectInfo._id , 1,"",5)
        if(!response)
            return
        self.data = response
    },
    methods :{
        ReformatNumber,
        ReformatDate,
        
    },
    computed : {
        ...mapGetters(['permission'])
    }
    
} 
</script>