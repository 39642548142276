import {GetProject, UpdateProject, GetProjectDirectory, AddToProjectDirectory,RemoveToProjectDirectory ,GetProjectLogs,UpdateUserProjectPermission} from '@/actions/projectActions.js'
import {GetUserByName} from '@/actions/userActions.js'
import dayjs from 'dayjs'
import { stageIcons,statusIcons,ContractType, ReformatDate, RoleListInt } from '@/helpers/utilities.js'
import router from '@/router'
const state ={
    projectInfo : {
        stage : 1,
        statusIcon :{
            name : '',
            icon : ''
        },
        stageProcess : []
    },
    projectPreview : {
        currencyId : {
            name : '',
            sign : ''
        }
    },
    offices : [],
    permission : [],
    projectUpdateObject : {},
    validatorProject : [],
    directoryValidator : false,
    loaderDirectory : false,
    directorySearch : '',
    projectDirectory: [],
    logsList : {},
}

const getters ={
    projectInfo : state => state.projectInfo,
    projectPreview : state => state.projectPreview,
    validatorProject : state => state.validatorProject,
    projectUpdateObject : state => state.projectUpdateObject,
    projectDirectory : state => state.projectDirectory,
    loaderDirectory : state => state.loaderDirectory,
    directorySearch : state => state.directorySearch,
    logsList : state => state.logsList,
    permission : state => state.permission,
    offices : state => state.offices,
}

const actions ={
    async GetProjectInfo({commit}, id){
        let projectInfoAll = await GetProject(id)
        if([401,400].indexOf(projectInfoAll)>-1){
            router.push('/unauthorized')
        }
        let projectInfo = projectInfoAll.project

        commit('setPermission',projectInfoAll.permission.accessList)
        commit('setOffices',projectInfoAll.office)

        let project = {
            _id : projectInfo._id,
            projectName : projectInfo.projectName,
            projectAddress : projectInfo.projectAddress,
            projectNumber : projectInfo.projectNumber,
            contractType : ContractType[projectInfo.contractType],
            startDate : ReformatDate(projectInfo.startDate),
            confirmedDate : ReformatDate(projectInfo.confirmedDate),
            handOverDate : ReformatDate(projectInfo.handOverDate),
            stageIcon : stageIcons[projectInfo.stage],
            stage : Number(projectInfo.stage),
            statusIcon : statusIcons[projectInfo.status],
            stageProcess : projectInfo.stageProcess,
            currencyId : projectInfo.currencyId,
            projectOldId : projectInfo.projectOldId,
        }

        commit('setProjectInfo',project)
        
        let projectEditable = JSON.parse(JSON.stringify(projectInfo))
        projectEditable.contractType = projectEditable.contractType || ""
        projectEditable.currencyId = projectEditable.currencyId._id
        projectEditable.unit_of_measure = projectEditable.unit_of_measure || "sm"
        projectEditable.startDate = projectEditable.startDate ? dayjs(projectEditable.startDate).format("YYYY-MM-DD") : ""
        projectEditable.confirmedDate = projectEditable.confirmedDate ? dayjs(projectEditable.confirmedDate).format("YYYY-MM-DD") : ""
        projectEditable.handOverDate = projectEditable.handOverDate ? dayjs(projectEditable.handOverDate).format("YYYY-MM-DD") : ""
        commit('setProjectUpdateInfo',projectEditable)

        let preview = JSON.parse(JSON.stringify(projectInfo))
        preview.contractType = preview.contractType || ""
        preview.unit_of_measure = preview.unit_of_measure || ""
        preview.size = preview.size || "-"
        preview.startDate = preview.startDate ? ReformatDate(projectEditable.startDate) : "-"
        preview.confirmedDate = preview.confirmedDate ? ReformatDate(projectEditable.confirmedDate) : "-"
        preview.handOverDate = preview.handOverDate ? ReformatDate(projectEditable.handOverDate) : "-"
        commit('setProjectPreviewInfo',preview)

    },
    // async GetProjectInfoInitial({commit},id){
        
    //     let projectInfo = await GetProject(id)
    //     let project = {
    //         _id : id,
    //         projectName : projectInfo.projectName,
    //         projectAddress : projectInfo.projectAddress,
    //         projectNumber : projectInfo.projectNumber,
    //         stageIcon : stageIcons[projectInfo.stage],
    //         statusIcon : statusIcons[projectInfo.status]
    //     }

    //     commit('setProjectInfo',project)
    // },
    FilterUserByName (obj,search){
        return GetUserByName(search)
    },
    FilterClientByName (obj,search){
        return GetUserByName(search, RoleListInt.CLIENT)
    },
    async UpdateProject(obj,elem){
        const self = this
        let validator = []
        const project = self.getters.projectUpdateObject
        if(!project.projectName){
            validator.push('a1')
        }
        if(!project.projectAddress){
            validator.push('a2')
        }
        if(!project.currencyId){
            validator.push('a3')
        }
        if(!project.contractType){
            validator.push('a4')
        }
        if(!project.officeId){
            validator.push('a5')
        }
        obj.commit('setValidatorProject',validator)
        if(validator.length>0){
            return
        }
        elem.disabled = true
        const execute = await UpdateProject(project)
        await obj.dispatch('GetProjectInfo',project._id)
        await obj.dispatch('GetProjectLogList',1)

        elem.disabled = false

        return execute

    },
    async GetProjectDirectory({commit}, page = 1){
        const id = this.getters.projectInfo._id
        commit('setloaderDirectory',true)
        const directory = await GetProjectDirectory(id,page).catch()
        commit('setloaderDirectory',false)
        if(directory==401){
            return directory
        }
        commit('setProjectDirectory',directory)

        return directory
    },
    updateSearchUserDirectory({commit },value){
        commit('setDirectorySearch',value)
    },
    async ProjectDirectoryAdd(obj,userId){
        const id = this.getters.projectInfo._id
        const add =  await AddToProjectDirectory(id,userId).catch()
        if(add=='OK'){
            await obj.dispatch('GetProjectDirectory',1)
        }
        return add
    },
    async ProjectDirectoryRemove({commit},userId){
        const id = this.getters.projectInfo._id
        const remove  = await RemoveToProjectDirectory(id,userId).catch()
        if(remove=='OK'){
            let docs = this.getters.projectDirectory
            const index = docs.findIndex((u)=>u.userId._id===userId)
            docs.splice(index,1)
            commit('setProjectDirectory',docs)
        }
        return remove
    },
    async GetProjectLogList({commit},page = 1){
        const id = this.getters.projectInfo._id
        let logs = await GetProjectLogs(id,page).catch()
        commit('setLogsList',logs)
    },
    async AppendProjectLogList({commit},page = 1){
        const id = this.getters.projectInfo._id
        let docs = this.getters.logsList.docs || []
        let logs = await GetProjectLogs(id,page).catch()
        logs.docs = docs.concat(logs.docs)
        commit('setLogsList',logs)
    },
    async ChangeUserPermission({commit},id){
        let docs = this.getters.projectDirectory
        const index = docs.findIndex((u)=>u._id===id)
        const changed = await UpdateUserProjectPermission(id)
        if(changed){
            docs[index].isAdmin = !docs[index].isAdmin
            commit('setProjectDirectory',docs)
        }
        return changed
    }

    
}

const mutations ={
    setProjectInfo : (state, data) => (state.projectInfo = data),
    setProjectUpdateInfo : (state, data) => (state.projectUpdateObject = data),
    setProjectPreviewInfo : (state, data) => (state.projectPreview = data),
    setValidatorProject : (state, data) => (state.validatorProject = data),
    setProjectDirectory : (state, data) => (state.projectDirectory = data),
    setloaderDirectory : (state, data) => (state.loaderDirectory = data),
    setDirectorySearch: (state, data) => (state.directorySearch = data),
    setLogsList: (state, data) => (state.logsList = data),
    setPermission : (state, data) => (state.permission = data),
    setOffices : (state, data) => (state.offices = data)
}

export default {
    state,
    getters,
    actions,
    mutations
}
