<template>
    <div class="card card-round border ">
        <div class="card-header bg-dark text-white"><h6 class="fw-bold mb-0"> <i class="fa-solid fa-money-bills"></i> {{ $t('Client Payment Position') }}</h6></div>
        <div class="card-body">
            <div class="row">
                <div class=" col-md-4 text-center text-info">
                    <div>
                        <span class="fw-bold">{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.notPaid)}}</span>
                    </div>
                    <div >
                        <small> {{ $t('Invoices') }}</small>
                    </div>
                </div>
                <div class=" col-md-4 text-center text-success">
                    <div>
                        <span class="fw-bold">{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.paid)}}</span>
                    </div>
                    <div >
                        <small> {{ $t('Paid') }}</small>
                    </div>
                </div>
                <div class=" col-md-4 text-center text-delta">
                    <div>
                        <span class="fw-bold">{{projectInfo.currencyId?.sign}}{{ReformatNumber(data.delta)}}</span>
                    </div>
                    <div >
                        <small> {{ $t('Delta') }}</small>
                    </div>
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{ width : data.percentage + '%' }"></div>
                <div class="progress-bar bg-info" role="progressbar" :style="{ width :  (100 -data.percentage)+ '%' }" ></div>
            </div>
        </div>
    </div>
</template>

<script>
import {DashboardGetPaymentPositionToClient} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
export default {
    name : 'PaymentPositionToClient',
    props : ['projectInfo'],
    data(){
        return {
            data : {
                paid :0, 
                notPaid :0, 
                delta : 0,
                percentage : 0
            },
            
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetPaymentPositionToClient(self.projectInfo._id)
        if(!response)
            return
        self.data = {
            paid : response.data.paid,
            notPaid : response.data.notPaid,
            delta : response.data.paid - response.data.notPaid,
            percentage : Math.round((response.data.paid/response.data.notPaid )*100)
        }
    },
    methods :{
        ReformatNumber,
    }
    
} 
</script>