<template>
    <div class="card card-round shadow " >
        <div class="card-header bg-dark text-white  ">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-circle-info"></i> {{ $t('Project Overview') }}</h6>
        </div>
        <div class="card-body ">
            <div class="row g-2 text-center">
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Total Sell Contract & Variation') }}</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationSellTotal) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light">{{ $t('Total Budget Contract & Variation') }}</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationBudgetTotal) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Committed Total') }}</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationCommittedTotal) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Uncommitted Total') }}</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationUncommittedTotal) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Contingency') }}</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationContingecyTotal) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Overall Running GP') }} ({{ReformatNumber(contractAndVariationGpPercentage)}}%)</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationGp) }}</div>
                    </div>
                </div>
                <div class="col">
                    <div class="border rounded px-2 py-3">
                        <h6 class="h6 fw-light"> {{ $t('Overall Forecast GP') }} ({{ReformatNumber(contractAndVariationGpForecastPercentage)}}%)</h6>
                        <div style="font-size: 125%;">{{projectInfo.currencyId.sign}}{{ ReformatNumber(contractAndVariationGpForecast) }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <a :href="dotNetUrl + '/project/overview/'+projectInfo.projectOldId" class="btn btn-link"> {{ $t('Show Full Summary') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import 'dotenv/config'
import {DashboardGetProjectOverviewTotals} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
export default {
    name : 'ProjectOverviewWidget',
    props : ['projectInfo'],
    data(){
        return {
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            totals : {

            },
            ReformatNumber
        }
    },
    computed :{
        contractAndVariationBudgetTotal(){
            return this.totals.contractBudgetTotal + this.totals.variationBudgetTotal
        },
        contractAndVariationCommittedTotal(){
            return this.totals.contractCommitedTotal + this.totals.variationCommitedTotal
        },
        contractAndVariationUncommittedTotal(){
            // return (this.totals.contractBudgetTotal - this.totals.contractCommitedTotal - this.totals.contractTransferedToGp) + (this.totals.variationBudgetTotal - this.totals.variationCommitedTotal - this.totals.variationTransferedToGp)
            return this.totals.uncommittedTotal
        },
        contractAndVariationSellTotal(){
            return this.totals.contractSellTotal + this.totals.variationSellTotal
        },
        contractAndVariationContingecyTotal(){
            // return (this.totals.contractSavings - this.totals.contractTransferedToGp) + (this.totals.variariationSavings + this.totals.variationTransferedToGp)
            return this.totals.contigencyGp
        },
        contractAndVariationGp(){
            return (this.totals.contractTransferedToGp + this.totals.variationTransferedToGp)
        },
        contractAndVariationGpPercentage(){
            return (this.contractAndVariationGp / this.contractAndVariationSellTotal) * 100
        },
        contractAndVariationGpForecast(){
            // return ( this.contractAndVariationSellTotal -  this.contractAndVariationBudgetTotal) + this.contractAndVariationGp
            return this.totals.gpForecast
        },
        contractAndVariationGpForecastPercentage(){
            // return (this.contractAndVariationGpForecast / this.contractAndVariationSellTotal) * 100
            return (this.totals.gpForecast / this.contractAndVariationSellTotal) * 100
        },
    },
    mounted : async function(){
        const self = this
        const response = await DashboardGetProjectOverviewTotals(self.projectInfo._id)
        if(!response)
            return
        self.totals = response.data || {}
    }
}
</script>