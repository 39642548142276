<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white">
            <h6 class="fw-bold mb-0"><i class="fa-solid fa-list"></i> {{ $t('Budget Breakdown') }}</h6>
        </div>
        <div class="card-body">
            <div class="text-center" v-if="data.sections.length==0">
                <div class="alert alert-outline-primary d-flex align-items-center" role="alert">
                    <i class="fas fa-info-circle fa-2x me-2"></i>  
                    <div>
                        {{ $t('No Approved Bill Of Quantities Yet to Show Breakdown') }}
                    </div>
                </div>
            </div>
            <div class="row" v-if="data.sections.length!=0">
                <div class="col-md-6">
                    <DoughnutChart :data="doughnutData"></DoughnutChart>
                </div>
                <div class="col-md-6">
                    <div class="row" v-for="(item,key) in data.sections" :key="key">
                        <div class="col-md-8">
                            <small class=" text-capitalize" :style="'color:' + colors[key]">{{ item.name }} <span class="badge bg-light text-black">{{Math.round((item.amount / data.sum)* 100)}}%</span></small>
                        </div>
                        <div class="col-md-4 text-end">
                            <span :style="'color:' + colors[key]" class="badge bg-light ">{{projectInfo.currencyId?.sign}}{{ ReformatNumber(item.amount) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DashboardGetProjectBudgetBreakdown} from '@/actions/projectActions.js'
import {ReformatNumber} from '../../helpers/utilities.js'
import DoughnutChart from '@/components/layout/DoughnutChart.vue'
export default {
    name : 'NetCashPosition',
    props : ['projectInfo'],
    components : {
        DoughnutChart
    },
    data(){
        return {
            colors : ['#94d13d','#6f58e9','#2d99fe','#2ddac1','#15a2fd','#dcd96c',
                        '#9c2349','#784975','#582da8', '#FFC600','#00AAF3','#50D691'],
            doughnutData : {
                labels: [],
                datasets: [],
            },
            data : { 
                sections : [],
                sum : 0
            }
            
        }
    },
    mounted : async function(){
        const self = this

        self.data = await DashboardGetProjectBudgetBreakdown(self.projectInfo._id)
        if(!self.data)
            return
        self.doughnutData.labels  = self.data.sections.map(u=>u.name)
        self.doughnutData.datasets = [
            {
                data : self.data.sections.map(u=>{
                    return Math.round((u.amount / self.data.sum)* 100)
                }),
                backgroundColor : ['#94d13d','#6f58e9','#2d99fe','#2ddac1','#15a2fd','#dcd96c',
                        '#9c2349','#784975','#582da8', '#FFC600','#00AAF3','#50D691']
            }
        ]
    },
    methods :{
        ReformatNumber,
    }
    
} 
</script>