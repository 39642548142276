<template>
    <div>
        <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : 'Projects',
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id  + '/notifications',
                title : 'Notifications',
                isRouterLink: true
            },
        ]"
        ></BreadcrumbsProject>

        <div class="container">
            <div class="text-center pt-3">
                <h5 class="fw-bold">Notifications</h5>
            </div>
            <h6 class="pt-2">16-July-2023</h6>
            <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>Trade Package <b>Accessories</b> has been awarded to vendor <b>168 Corebilt Inc.</b>. </small>
                    </div>
                    <div class="col-md-2 text-end">
                        <small role="button" class="text-muted" ><i class="fa-solid fa-times"></i> Dismiss</small>
                    </div>
                </div>
            </div>
            <h6 class="pt-2">15-July-2023</h6>
            <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>Client requested for a revision for <b>QTN0001 Head Contract</b>. Please click <a href="#">here</a> to view  </small>
                    </div>
                    <div class="col-md-2 text-end">
                        <small role="button" class="text-muted" ><i class="fa-solid fa-times"></i> Dismiss</small>
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>Quotation is <b>QTN0001 Head Contract</b> is waiting for your approval. Please click <a href="#">here</a> to Approve/Review</small>
                    </div>
                    <div class="col-md-2 text-end">
                        <small role="button" class="text-muted" ><i class="fa-solid fa-times"></i> Dismiss</small>
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>Bill of quantity <b>Air-Conditioning Works</b> that you sent for approval was been approved! </small>
                    </div>
                    <div class="col-md-2 text-end">
                        <small role="button" class="text-muted" ><i class="fa-solid fa-times"></i> Dismiss</small>
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-2 mt-1 bg-white">
                <div class="row">
                    <div class="col-md-10">
                        <small>New contact persons are added into the directory! Please click <a href="#">here</a> to view </small>
                    </div>
                    <div class="col-md-2 text-end">
                        <small role="button" class="text-muted" ><i class="fa-solid fa-times"></i> Dismiss</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {LinkList} from '@/helpers/utilities.js'
import BreadcrumbsProject from '@/components/projectInformation/BreadcrumbsProject.vue'
export default {
    name : 'NotificationList',
    components : {
        BreadcrumbsProject
    },
    computed : {
        ...mapGetters(['projectInfo'])
    },
    methods : {
        ...mapActions(['GetProjectInfo','ChangeActiveLink']),
    },
    mounted: async function(){
        const self = this
        self.ChangeActiveLink(LinkList.SUMMARY)
        await self.GetProjectInfo(self.$route.params.id)
    },
    data(){
        return {
            data : [{
                message : ``,
                date : '16-July-2023'
            }]
        }
    }
}
</script>