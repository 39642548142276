<template>
    
   <div class="custom2-container">
        <nav class="custom2-breadcrumbs">
            <a  
            class="custom2-breadcrumbs__item"
            :class="{
                'custom2-breadcrumbs__item is-inprogress' : projectInfo.stage - 1==key,
                'custom2-breadcrumbs__item is-completed' : key < projectInfo.stage - 1,
                'custom2-breadcrumbs__item is-pending' :key > projectInfo.stage - 1
            }"
            v-for="(item, key) in stageIcons" :key="key"
            :title="item.name + ' - ' + (projectInfo.stageProcess[key] ? stageActionsText[projectInfo.stageProcess[key].status -1]  :'Inactive')"
            
            @click="ClickStep(key)"
            >
            {{item.name}}</a>
        </nav>
        <button class="d-none" ref="modalCaller" data-bs-toggle="modal" data-bs-target="#StepModal" />
   </div>
    <Modal idModal="StepModal" :headerTitle="modalData.processName" >
        <form>
            <div class="form-group">
                <label>Status </label>
                <input type="text" :value="modalData.status" class="form-control-plaintext" readonly  />
            </div>
            <div class="form-group">
                <label>Updated At </label>
                <input type="text" :value="modalData.updatedAt" class="form-control-plaintext" readonly  />
            </div>
        </form>
        <template v-slot:actions>
        <div></div>
        <button type="button" ref="closeStageModal" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
      </template>
    </Modal>
</template>

<script>
import 'dotenv/config'
import Modal from '@/components/layout/Modal.vue'
import {stageIcons} from '@/helpers/utilities.js'
import {mapGetters} from 'vuex'
export default {
    name : 'StepItem2',
    props : ['stage', 'stageDetails'],
    components : {
        Modal
    },
    data(){

        let projectUrl = process.env.VUE_APP_PROJECT_URL
        let boqUrl = process.env.VUE_APP_BOQ_URL
        let dotNetUrl = process.env.VUE_APP_DOTNET_URL
        return {            
            stepDetails : {
                stepName : '',
                stepNumber : 1
            },
            modalData :{
                processName : '',
                updatedAt : '',
                status : ''
            },
            processName : '',
            stageIcons : stageIcons,
            stageActions : [
                'is-pending',
                'is-inprogress',
                'is-completed',
                'is-skipped',
                'is-canceled',
            ],
            stageActionsText : [
                'Pending',
                'In Progress',
                'Completed',
                'Skipped',
                'Canceled',
            ],
            urlList :[
                 projectUrl + '/project/idHere',
                 boqUrl + '/boq/idHere',
                 boqUrl + '/boq/idHere/quotation/list',
                 boqUrl + '/boq/idHere/quotation/list',
                 dotNetUrl + '/tradepackage/idHere',
                 dotNetUrl + '/contingency/idHere',
                 dotNetUrl + '/PurchaseOrder/purchaseordersList?projectId=idHere',
                 dotNetUrl + '/progressclaim/idHere',
                 dotNetUrl + '/clientinvoice/idHere',
                 dotNetUrl + '/change-request/idHere',
                 '',
 
             ]
        }
    },
    methods :{
        ClickStep(data){
            console.log(data)

            if(data >3){
                window.location.href = this.urlList[data].replace('idHere',this.projectInfo.projectOldId)
            }else{
                window.location.href = this.urlList[data].replace('idHere',this.projectInfo._id)
            }
            // if(!data){
            //     return
            // }
            // this.modalData.processName = this.stageIcons[data.stage -1].name
            // this.modalData.status = this.stageActionsText[data.status -1] 
            // this.modalData.updatedAt = ReformatDate(data.updateAt) 
            // this.$refs.modalCaller.click()
        }
    },
    computed : mapGetters(['projectInfo']),
}
</script>