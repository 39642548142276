<template>
    <ul class="progressbar-custom ">
        <li v-for="(item,key) in listOfSteps" :key="key" :data-name="item" :class="classLi" @click.stop="clickStepper(item)" >
            
            <div class="card floating-card shadow  text-black" v-if="openStep==item" @click.stop >
                <div class="card-header bg-white">
                    <h6>{{item}}</h6>
                </div>
                <div class="card-body">
                    <div v-if="key==0">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Date Published</label>
                                <input type="date" class="form-control-plaintext text-center  h6" readonly  />
                            </div>
                        </div>
                    </div>
                    <div class="row " v-else>
                        <template v-for="(child, cKey) in relatedList" :key="cKey">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control-plaintext text-center text-capitalize h6" readonly :value="child.relatedUserId.fullname" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Viewed on Date</label>
                                    <input type="date" class="form-control-plaintext  h6" readonly  />
                                </div>
                            </div>
                        </template>
                    </div>
                    
                    
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name : 'ProcessViewStepListPreview',
    props : ['stepList','relatedList','onClickStep'],
    data(){
        return {
            openStep : '',
            classLi : 'width-5-per',
            listOfSteps : ['Published', 'Viewers']
        }
    },
   mounted :function(){
        this.classLi = this.stepList.length < 5 ? 'width-'+ this.stepList.length +'-per' : 'width-5-per'
    },
    updated :function(){
        this.classLi = this.listOfSteps.length < 5 ? 'width-'+ this.listOfSteps.length +'-per' : 'width-5-per'
    },
    methods: {
        clickStepper(item){
            this.openStep = this.openStep==item ? '' : item
            // this.onClickStep(item)
        }
    },

}
</script>
<style scoped>
    .width-1-per{
        width:  50%;
    }
    .width-2-per{
        width:  50%;
    }
    .width-3-per{
        width:  30%;
    }
    .width-4-per{
        width:  25%;
    }
    .width-5-per{
        width:  20%;
    }
</style>
