

export async function CreateFolderToDrive(accessToken, driveId, newFolderName) {
	const folderCreationRequest = {
		'name': newFolderName,
		'folder': {},
		'@microsoft.graph.conflictBehavior': 'rename'
	};

	const folderCreationResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/root/children`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(folderCreationRequest)
	});
	return folderCreationResponse.json();
}

export async function CreateFolderToFolder(accessToken, driveId,parentId,  newFolderName) {
	const folderCreationRequest = {
		'name': newFolderName,
		'folder': {},
		'@microsoft.graph.conflictBehavior': 'rename'
	};
	const folderCreationResponse = await fetch(`https://graph.microsoft.com/v1.0/me/drives/${driveId}/items/${parentId}/children`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(folderCreationRequest)
	});
	return folderCreationResponse.json();
}