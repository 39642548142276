<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : $t('Projects'),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
        ]"
    ></BreadcrumbsProject>
    <div class="">
        <StepItem2
        ></StepItem2>
    </div> 
    <div class="pt-3 ps-3" >
        <div class="d-flex justify-content-between">
            <h5 class="fw-bold">{{ $t('Dashboard') }}</h5>
            <div>
                <button class="btn btn-primary me-2" v-if="!editMode" @click="()=>{ editMode=!editMode}"><i class="fa-solid fa-pen"></i> {{ $t('Edit') }}</button>
                <button class="btn btn-outline-primary me-1"  data-bs-target="#ModalOpenWidgets" data-bs-toggle="modal"
                v-if="editMode" ><i class="fa-solid fa-object-ungroup"></i> {{ $t('Widget List') }}</button>
                <button class="btn btn-outline-success me-1" :disabled="isWidgetSaving" v-if="editMode" @click="SaveSettings"><i class="fa-solid fa-save"></i>{{ $t('Save Changes') }} </button>

                <!-- <button class="btn btn-primary " v-if="!sharepointFolders.projectSharepointFolders&&sharepointToken" @click="SetUpProject">Set-Up Sharepoint Folders</button> -->
            </div>
        </div>
    </div>
    <div class=""  v-if="projectInfo._id"  >
        <Draggable v-model="dashboardSettings.settings"  tag="div" class="row p-3 pt-2 "  itemKey="id"
            :animation="150" ghostClass="opacity-50"  handle=".handle" :onEnd="OnDragEnd"  >
            <template #item="{element}">
                <div  style="position: relative;" :style="{ 'min-height' : (100 * element.height) +'px'}" :class=" (!editMode ? 'move-from-bottom ' : '') + '  p-1 mt-1 col-md-' + element.width  "  >
                    <div class="top-right-button " v-if="editMode">
                        <button class="btn btn-info ps-2 pe-2 me-1" data-bs-target="#ModalUpdateWidget" data-bs-toggle="modal" @click="OpenUpdateWidget(element.widgetId, element.width,element.height)"><i class="fa-solid fa-cogs"></i></button>
                        <button class="btn btn-info ps-2 pe-2 handle"><i class="fa-solid fa-up-down-left-right"></i></button>
                    </div>
                    <div :style="{'pointer-events' : editMode ? 'none' : 'auto'}">
                        <component :is="element.component" :projectInfo="projectInfo"></component> 
                    </div>
                </div>
            </template>
        </Draggable>
    </div>
    <div v-else>
        <Loader />
    </div>
    <Modal idModal="ModalUpdateWidget" headerTitle="Change Size" modalSize="">
        <div class="form-group">
            <label class="required-style">Widget Width </label>
            <select v-model="updateWidgetObj.width" class="form-select">
                <option value="3">25%</option>
                <option value="6">50%</option>
                <option value="12">100%</option>
            </select>
            <small class="text-warning" v-if="!updateWidgetObj.width">Required</small>
        </div>
        <!-- <div class="form-group">
            <label class="required-style">Widget Height </label>
            <select v-model="updateWidgetObj.height" class="form-select">
                <option value="1">Small</option>
                <option value="2">Regular</option>
                <option value="3">Large</option>
            </select>
            <small class="text-info">INFO : Widget may adjust the chosen height to accommodate content size.</small>
            <small class="text-warning" v-if="!updateWidgetObj.height">Required</small>
        </div> -->
        <template v-slot:actions>
            <button type="button" ref="closeModalUpdateWidget" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="UpdateSettings" ><i class="fas fa-save"></i> Save changes</button>
        </template>
    </Modal>
    <Modal idModal="ModalOpenWidgets" headerTitle="Widget List" modalSize="modal-xl" >
        <div class="row">
            <div v-for="(item,key) in widgetList" class="col-md-4" :key="key" @click="CheckWidget(item.id)" role="button">
                <div class="form-group p-2 m-1 border">
                    <input type="checkbox" :value="item.id" v-model="selectedWidgets" class="me-3" />
                    <label  role="button">{{ item.name }}</label>
                </div>
            </div>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeModalOpenWidgets" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
        </template>
    </Modal>
    <Modal idModal="BoqModalAdd" headerTitle="Add BoQ" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_CREATE)>-1">
        <div class="form-group">
            <label class="required-style"> Name </label>
            <input class="form-control" placeholder="Example Quotation no. 1 01_REVISON 5" v-model="newBoq.name" />
            <small class="text-warning" v-if="boqSaveClicked && !newBoq.name">Required</small>
        </div>
         <div class="form-group">
            <label> Reference </label>
            <Select2
            indexValue="_id"
            :filterQuery="SearchQuotationList"
            labelName="fullname"
            placeHolder="Search Quotation here"
            v-model:optionValue="newBoq.reference"
            :dataOptions="quotationList"
            :willCapitalize="false"
            />
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="CreateBoq" ><i class="fas fa-save"></i> Save changes</button>
        </template>
    </Modal>
    <Modal idModal="BoqModalAddQuotation" headerTitle="Add Quotation" modalSize="modal-md" v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_CREATE)>-1">
        <div class="form-group">
            <label class="required-style"> Quotation Number </label>
            <input class="form-control" placeholder="Example Qtn-0001" v-model="newQuotation.qtnNumber" />
            <small class="text-warning" v-if="qtnSaveClicked && !newQuotation.qtnNumber">Required</small>
        </div>
         <div class="form-group">
            <label class="required-style">  Name </label>
            <input class="form-control" placeholder="Example Quotation name" v-model="newQuotation.qtnName" />
            <small class="text-warning" v-if="qtnSaveClicked && !newQuotation.qtnName">Required</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeBoqModalAddQuotation" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button type="button" class="btn btn-primary" @click="CreateQuotation" ><i class="fas fa-save"></i> Save changes</button>
        </template>
    </Modal>
     <!-- <div class="col-md-4 ">
            <ProjectInformationWidget :projectInfo="projectInfo"  v-if="projectInfo._id"  />
        </div>
        <div class="col-md-4 ">
            <PaymentPositionToVendor :projectInfo="projectInfo" v-if="projectInfo._id" />
            <PaymentPositionToClient :projectInfo="projectInfo" v-if="projectInfo._id" />
            
            <ProjectDates :projectInfo="projectInfo" v-if="projectInfo._id" />
        </div>
        <div class="col-md-4 ">
            <NotificationWidget :projectInfo="projectInfo" v-if="projectInfo._id" />
            <ActionablesWidget :projectInfo="projectInfo" v-if="projectInfo._id" />
            <NetCashPosition :projectInfo="projectInfo"  v-if="projectInfo._id" />
            <TotalTradesProcured :projectInfo="projectInfo"  v-if="projectInfo._id"  />
        </div>
        <div class="col-md-6 ">
            <BillOfQuanitityListWidget :projectInfo="projectInfo" v-if="projectInfo._id" />
        </div>
        <div class="col-md-6 ">
            <ProcurementDetails  :projectInfo="projectInfo"  v-if="projectInfo._id"  />
        </div>
        <div class="col-md-6 ">
            <ProjectQuotationListWidget :projectInfo="projectInfo"  v-if="projectInfo._id"  />
        </div>
        <div class="col-md-4 ">
            <CummulativePaymentPosition :projectInfo="projectInfo"  v-if="projectInfo._id"/>
        </div>
        <div class="col-md-6 ">
            <PurchaseOrdersList :projectInfo="projectInfo"  v-if="projectInfo._id" />
        </div>
        <div class="col-md-6 mt-2 ">
            <DirectoryOverview></DirectoryOverview>
        </div>
        <div class="col-md-12 mt-2 ">
            <ProjectBudgetBreakdown :projectInfo="projectInfo"  v-if="projectInfo._id" />
        </div>
        <div class="col-md-6 mt-2 ">
            <div class="card card-round border">
                <div class="card-body text-center">
                    <h3 class="fw-bold">Budget Spending</h3>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-2 ">
            <div class="card card-round border">
                <div class="card-body text-center">
                    <h3 class="fw-bold">Forecast</h3>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2 ">
            <ClientInvoiceList :projectInfo="projectInfo"  v-if="projectInfo._id"  />
        </div>
        <div class="col-md-12 mt-2 ">
            <ClientProgressClaims :projectInfo="projectInfo"  v-if="projectInfo._id"/>
        </div>
        <div class="col-md-12 mt-2 ">
            <ChangeRequestList :projectInfo="projectInfo"  v-if="projectInfo._id" />
        </div> -->
       
        
</template>
<style scoped>
    .text-delta{
        color: #919092;
    }
    .bg-delta{
        color: #CBD4D8;
    }
    .top-right-button {
        position: absolute; /* Positions the button absolutely within the card div */
        top: 0; /* Aligns the top of the button with the top of the card div */
        right: 0; /* Aligns the right of the button with the right of the card div */
        z-index: 500;
    }
    .top-right-button-before {
        position: absolute; /* Positions the button absolutely within the card div */
        top: 0; /* Aligns the top of the button with the top of the card div */
        right: 20000; /* Aligns the right of the button with the right of the card div */
        z-index: 500;
    }
    .move-from-bottom { 
        opacity: 0;
        transform: translateY(100%);
        animation: moveFromBottom 1s forwards;
    }
    .card.card-round.border{
        height: inherit;
        min-height: inherit;
    }
    @keyframes moveFromBottom {
        to {
                opacity: 1;
                transform: translateY(0);
            }
    }

</style>
<script src="@/assets/projectJs/Overview.js">
</script>