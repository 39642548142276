<template>
    <div class="card" >
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <h6 class="fw-bold">{{ $t('Project Information') }}</h6>
                <button class="btn btn-outline-primary" @click="ChangeView"> <i class="fa-solid fa-eye"></i> {{ $t('View Only') }}</button>
            </div>
        </div>
        <div class="card-body">
            <form class="">
                <fieldset >
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{ $t('Project Number') }}</label>
                                <input type="text" class="form-control" disabled :value="projectUpdateObject.projectNumber">
                            </div>
                        </div>
                        <div class="col-md-4">
                             <div class="form-group ">
                                <label> {{ $t('Project Name') }}</label>
                                <input type="text" :value="projectUpdateObject.projectName" class="form-control" disabled placeholder="Name of the project">
                                <small class="text-warning" v-if="validatorProject.indexOf('a1')> -1">Required</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> {{ $t('Currency') }}</label>
                                <select class="form-select" v-model="projectUpdateObject.currencyId">
                                    <option value="">{{ $t('Please Select') }}</option>
                                    <option v-for="(item, key) in currencyList" :key="key" :value="item._id">{{item.sign}} {{item.name}}</option>
                                </select>
                                <small class="text-warning" v-if="validatorProject.indexOf('a3')> -1">
                                    Currency is required
                                </small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label> {{ $t('Office in Charge') }}</label>
                                <select class="form-control text-capitalize" disabled :value="projectUpdateObject.officeId">
                                    <option>Select</option>
                                    <option v-for="(item, key) in offices" :key="key" :value="item._id">{{item.officeName}}</option>
                                </select>
                                <small class="text-warning" v-if="validatorProject.indexOf('a5')> -1">
                                    Office in Charge is required
                                </small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label> {{ $t('Contract Type') }}</label>
                                <select class="form-select" v-model="projectUpdateObject.contractType">
                                     <option :value="item.id" v-for="(item,key) in ContractTypeObj" :key="key" >{{item.value}} </option>
                                </select>
                                <small class="text-warning" v-if="validatorProject.indexOf('a4')> -1">
                                    Contract Type is Required
                                </small>
                            </div>
                            <div class="form-group">
                                <label> {{ $t('Contract Type(KC)') }}</label>
                                <input type="text" class="form-control" disabled :value="projectUpdateObject.kcCtype != null ? $t(projectUpdateObject.kcCtype.trim()) : ''" readonly />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> {{ $t('Project Size(ft2)') }}</label>
                                <input type="text" class="form-control" disabled :value="projectUpdateObject.squareFoot" readonly/>
                            </div>
                            <div class="form-group">
                                <label> {{ $t('Project Size(m2)') }}</label>
                                <input type="text" class="form-control" disabled :value="projectUpdateObject.squareMeter" readonly/>
                            </div>
                             <div class="form-group">
                                <!-- <label for="" >Project size</label>
                                <div class="input-group mb-3">
                                    <input type="number" v-model="projectUpdateObject.size" class="form-control"  >
                                    <span class="input-group-text" >
                                    <select class="form-select border-0 pt-0 pb-0" v-model="projectUpdateObject.unit_of_measure">
                                        <option v-for="(item, key) in  LandMeasurements" :key="key" :value="item">{{item}}</option>
                                    </select>
                                    </span>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label> {{ $t('Address') }}</label>
                                <textarea class="form-control" placeholder="Address of the Project" disabled :value="projectUpdateObject.projectAddress" style="resize: none;height: 91px;"></textarea>
                                <small class="text-warning" v-if="validatorProject.indexOf('a2')> -1">Required</small>
                            </div>
                            
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label> {{ $t('Contract Date') }}</label>
                                <DatePicker v-model="projectUpdateObject.confirmedDate" :disabled="true" elemId="home-date-confirmedDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label> {{ $t('Start Date') }}</label>
                                <DatePicker v-model="projectUpdateObject.startDate" :disabled="true" elemId="home-date-startDate" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label> {{ $t('Handover Date') }}</label>
                                <DatePicker v-model="projectUpdateObject.handOverDate" :disabled="true" elemId="home-date-handOverDate" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
            <div class="d-flex justify-content-end pb-3" >
                <button class="btn btn-primary" @click="UpdateProjectConfirm($event.target)"> <i class="fas fa-save"></i> {{$t('Save Changes')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'

import DatePicker from '@/components/layout/DatePicker.vue'

import  i18n from '@/translations/i18n.js'
const i18nTranslate = i18n.global.t
export default {
    name : 'ProjectGeneralInfo',
    props : ['ChangeView', 'ContractTypeObj','LandMeasurements', 'currencyList'],
    components : {DatePicker},
    methods : {
        ...mapActions(['PropertyChanger','FilterUserByName','UpdateProject','GetProjectLogList','FilterClientByName']),
        UpdateProjectConfirm(value){
            const self = this
            self.UpdateProject(value).then((res)=>{
                if(res=='OK'){
                    self.$swal(i18nTranslate('Project Update'), '', 'success')
                }else{
                    self.$swal(i18nTranslate('Project Update'), res, 'warning')
                }
            }).catch()
        }
    },
    computed : mapGetters(['projectUpdateObject','validatorProject', 'permission', 'offices']),
}
</script>
<style scoped>
    .form-group{
        margin-bottom: 1rem !important;
    }
    .col-form-label{
        font-weight: 500;
    }
</style>