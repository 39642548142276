<template>
    <div class="card card-round shadow"  >
        <div class="card-header bg-dark text-white">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-file-lines"></i> {{ $t('Quotation') }}</h6>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>{{ $t('Quotation No') }}</th>
                            <th>{{ $t('Description') }}</th>
                            <th>{{ $t('Amount') }}</th>
                            <th>{{ $t('Status') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in data.docs" :key="key">
                            <td>
                                <a :href="boqUrl + '/boq/' + item._id + '/quotation/item'" class="link-item-route text-capitalize">  {{item.quotationNumber}} {{item.quotationName}}</a>
                            </td>
                            <td>
                                <small>
                                    {{ item.remark || '-' }}
                                </small>
                            </td>
                            <td>
                               {{ projectInfo.currencyId.sign }}{{ ReformatNumber(item.amount) }}
                            </td>
                            <td>
                                <span :class="'badge ' + ApprovalStageBoqObj[item.approvalStage -1].color">{{ApprovalStageBoqObj[item.approvalStage-1].name}}</span>
                            </td>
                        </tr>
                        
                        <tr v-if="permission.indexOf(ModuleActionList.BOQ_QUOTATION_CREATE)>-1" >
                            <td class="text-center" colspan="4" >
                                <a class="btn btn-outline-primary"
                                data-bs-target="#BoqModalAddQuotation" data-bs-toggle="modal" @click="()=>{ newQuotation = {};}"
                                ><i class="fa-solid fa-plus"></i> {{ $t('Add Quotation') }}</a>
                            </td>
                        </tr>
                        <tr v-else-if="data.docs.length==0">
                            <td class="text-center" colspan="4" >
                                {{ $t('No data Found') }}
                            </td>
                        </tr>
                    </tbody>
                </table>   
            </div>
            <div class="d-flex justify-content-center"  v-if="data.docs.length!=0">
                <a :href="boqUrl + '/boq/' + projectInfo._id + '/quotation/list'" class="link-style-custom">{{ $t('View All') }}</a>
            </div>
        </div>
    </div>
    
</template>



<script>
import {GetQuotationList} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate, ApprovalStageBoqObj, ModuleActionList} from '../../helpers/utilities.js'
import { mapGetters } from 'vuex'
import 'dotenv/config'
export default {
    name : 'BillOfQuanitityListWidget',
    props : ['projectInfo'],
    data(){
        return {
            data : {
                docs : []
            },
            ApprovalStageBoqObj,
            newQuotation : {},
            qtnSaveClicked : false,
            isExecuting : false,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            ModuleActionList,
            quotationList : []
        }
    },
    mounted : async function(){
        const self = this
        const response = await GetQuotationList({ id : self.projectInfo._id, page : 1, search : "", limit : 5})
        if(!response)
            return
        self.data = response
    },
    methods :{
        ReformatNumber,
        ReformatDate
    },
    computed : {
        ...mapGetters(['permission'])
    }
    
} 
</script>