<template>
    <div class="alert alert-success rounded-3 d-flex justify-content-between pt-3" role="button" @click="OpenActionables">
        <div > {{ $t('Actionables') }} </div>
        <div > <span class="badge bg-success border">{{ count }}</span> </div>
    </div>
    </template>
    
<script>

import {DashboardGetProjectActionablesFirstPart,DashboardGetProjectActionables} from '@/actions/projectActions.js'
export default {
    name : 'ActionablesWidget',
    props : ['projectInfo'],
    mounted : async function(){
        const self = this
        const getData = await DashboardGetProjectActionablesFirstPart(self.projectInfo._id)
        let data = getData.messages

        if(getData.cleared){
            const getDotNetData = await DashboardGetProjectActionables(self.projectInfo._id)
            if(getDotNetData)
                data.push(...getDotNetData.data)
        }

        self.count = data.filter(u=>!u.status).length
    },
    data(){
        return {
            count : 0,
        }
    },
    methods : {
        OpenActionables(){
            this.$router.push({name : 'ActionableList', params : {id : this.projectInfo._id}})
        }
    }
} 
</script>