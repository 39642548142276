<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white">
            <h6 class="fw-bold mb-0"><i class="fa-solid fa-calendar-days"></i> {{ $t('Project Schedule') }}</h6>
        </div>
        <div class="card-body p-0">
            <table class="table table-bordered mb-0">
                <tbody>
                    <tr>
                        <td rowspan="2" valign="middle" class="text-center fw-bold"> {{ $t('Contract') }}</td>
                        <td class="text-center fw-bold"> {{$t('Commencement Date')}}</td>
                        <td class="text-center fw-bold">{{$t('Handover Date')}}</td>
                    </tr>
                    <tr>
                        <td class="text-center">{{projectInfo.startDate|| '-'}}</td>
                        <td class="text-center">{{projectInfo.handOverDate || '-'}}</td>
                    </tr>
                    <tr>
                        <td rowspan="2" valign="middle" class="text-center fw-bold">
                            <span class="text-success">{{$t('Actual')}}</span>/<span class="text-danger"> {{$t('Forecast')}}</span>
                        </td>
                        <td class="text-center fw-bold">{{$t('Commencement Date')}}</td>
                        <td class="text-center fw-bold">{{$t('Handover Date')}}</td>
                    </tr>
                    <tr>
                        <td class="text-center"> -</td>
                        <td class="text-center">-</td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</template>

<script>
    export default {
    name : 'ProjectDates',
    props : ['projectInfo'],
} 
</script>