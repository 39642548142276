<template>
    <div class="card card-round shadow">
        <div class="card-header bg-dark text-white">
            <h6 class="mb-0 fw-bold"><i class="fa-solid fa-cubes"></i> {{ $t('Procurement Progress') }}</h6>
        </div>
        <div class="card-body">
            <div class="text-center">
                <h5 class="fw-bold">{{ $t('Progress') }}</h5>
            </div>
            <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{width: data.percentage + '%'}">{{data.percentage}}%</div>
            </div>
            <div class="row ">
                <div class="col-md-6 mt-2">
                    <div class=" border p-2 text-center rounded-3 ">
                        <div><h5>{{data.lineItemsAttachedToTradePackage}}/{{data.lineItemsNotAttachedToTradePackage}}</h5></div>
                        <div>
                            <small> {{ $t('Line Items attached to Trade Packages')}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="border ms-1 p-2 text-center rounded-3">
                        <div><h5>{{data.tradesProcured}}/{{data.tradesNotProcured}}</h5></div>
                        <div>
                            <small> {{ $t('Trade Package Awarded')}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-outline-primary rounded-3 mt-2" @click="()=>{showEvents = !showEvents}"> {{ $t('Toggle Upcoming Events')}}</button>
            <div class="card border card-round mt-2" v-show="showEvents">
                <div class="card-header">
                    <small class="fw-bold"> {{ $t('Upcoming Procurement Events')}}</small>
                </div>
                <div class="card-body">
                    <div class="small mt-1 text-capitalize text-center" v-if="data.upcomingTradePackageRounds.length==0">
                        <span class="fw-bold text-muted"> {{ $t('No Upcoming Rounds')}}</span> 
                    </div>  
                    <div class="small mt-1 text-capitalize" v-for="(item,key) in  data.upcomingTradePackageRounds" :key="key">
                        <span :class="'badge bg-'+colors[item.statusId - 1] + ' text-white'"> {{ item.statusName }}</span> <span class="fw-bold">{{ ReformatDate(item.roundDate) }}</span> {{item.tradePackageName }} ({{ $t('Round') }} {{item.round - 1 }}) 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {DashboardGetTradePackageProcurement} from '@/actions/projectActions.js'
import {ReformatNumber, ReformatDate} from '../../helpers/utilities.js'
export default {
    name : 'ProcurementDetails',
    props : ['projectInfo'],
    data(){
        return {
            data : { 
                lineItemsAttachedToTradePackage : 0, 
                lineItemsNotAttachedToTradePackage : 0,
                tradesNotProcured : 0,
                tradesProcured : 0,
                upcomingTradePackageRounds : [],
                percentage : 0,
            },
            colors : ['info','warning','success'],
            showEvents : false
            
        }
    },
    mounted : async function(){
        const self = this

        const response = await DashboardGetTradePackageProcurement(self.projectInfo._id)
        if(!response)
            return
        let first50 = Math.round((response.data.lineItemsAttachedToTradePackage/response.data.lineItemsNotAttachedToTradePackage )*50)
        let second50 = Math.round((response.data.tradesProcured/response.data.tradesNotProcured )*50)
        self.data = response.data
        self.data.percentage = first50 + second50;

    },
    methods :{
        ReformatNumber, ReformatDate
    }
    
} 
</script>