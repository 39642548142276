<template>
    <div class="card">
        <div class="card-header bg-white">
            <div class="d-flex justify-content-between">
                <h6 class="fw-bold">{{ $t('Project Information') }}</h6>
                <button class="btn btn-primary" @click="ChangeView" v-if="permission.indexOf(ModuleActionList.PROJECT_DETAILS_EDIT)>-1"> <i class="fa-solid fa-pencil"></i> {{$t('Edit')}}</button>
            </div>
        </div>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ $t('Project Number') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.projectNumber" readonly />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Project Name') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.projectName" readonly  />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Currency') }}</label>
                            <input type="text" class="form-control-plaintext" :value=" ' ('+ projectData.currencyId.sign + ' '+projectData.currencyId.shortName + ') ' +projectData.currencyId.name " readonly />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Office in Charge') }}</label>
                            <input type="text" class="form-control-plaintext text-capitalize" :value="GetOffice(projectData.officeId)" readonly/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Contract Type') }}</label>
                            <input type="text" class="form-control-plaintext" :value="ContractType[projectData.contractType]" readonly />
                        </div>
                        <div class="form-group">
                            <label> {{ $t('Contract Type(KC)') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.kcCtype != null ? $t(projectData.kcCtype.trim()) : ''" readonly />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Project Size(ft2)') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.squareFoot" readonly/>
                        </div>
                        <div class="form-group">
                            <label> {{ $t('Project Size(m2)') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.squareMeter" readonly/>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label> {{ $t('Address') }}</label>
                            <textarea class="form-control-plaintext" v-model="projectData.projectAddress" style="resize: none;height: 91px;" readonly ></textarea>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Contract Date') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.confirmedDate"  readonly/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Start Date') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.startDate" readonly />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> {{ $t('Handover Date') }}</label>
                            <input type="text" class="form-control-plaintext" :value="projectData.handOverDate"  readonly/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {ContractType, ModuleActionList} from '@/helpers/utilities.js' 

export default {
    name : 'ProjectViewOnly',
    props : ['ChangeView'],
    computed : mapGetters(['projectPreview', 'offices', 'permission']),
    mounted : function(){
        this.projectData = Object.assign({}, this.projectPreview)
    },
    methods : {
        GetOffice(id){
            if(!id){
                return ""
            }
            const getIndex = this.offices.findIndex(u=>u._id===id)
            return this.offices[getIndex].officeName
        }
    },
    data (){
        return{
            projectData : {
                currencyId : {
                    name : '',
                    sign : ''
                }
            },
            ContractType : ContractType,
            ModuleActionList : ModuleActionList
        }
    }
}
</script>