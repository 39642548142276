import 'dotenv/config'
import dayjs from "dayjs"
import utc  from 'dayjs/plugin/utc'
import advancedFormat  from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import  i18n from '../translations/i18n.js'
import {LocaleNameVariable} from '@/translations/i18n.js'
const i18nTranslate = i18n.global.t


export const statusIcons = [
    {},
    {name : 'Ongoing', icon : 'fas fa-cog fa-spin', color : 'primary'},
    {name : 'Complete', icon : 'fas fa-check-circle', color : 'success'},
    {name : 'Cancelled', icon : 'fas fa-times-circle', color : 'danger'}
]

export const stageIcons = [
    {name : i18nTranslate('Project Information'), icon : 'fas fa-lightbulb'},
    {name : i18nTranslate('Bill of Quantities'), icon : 'fas fa-lightbulb'},
    {name : i18nTranslate('Quotation'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Contract'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Procurement'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Contingency'), icon : 'fas fa-hard-hat'},
    {name : i18nTranslate('Purchase Order'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Progress Claims'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Client Invoice'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Change Order'), icon : 'fas fa-handshake'},
    {name : i18nTranslate('Final Account'), icon : 'fas fa-handshake'},
]

export const RoleList = {
    ADMIN : 'Administrator',
    PROJECT_DIRECTOR : 'Project Director',
    PROJECT_LEADER: 'Project Leader',
    COMMERCIAL_MANAGER : 'Commercial Manager',
    CONTRACT_MANAGER : 'Contract Manager',
    DESIGNER : 'Designer',
    CONSTRUCTION_MANAGER : 'Construction Manager',
    ACCOUNTING_TEAM : 'Accounting Team',
    CONTRACT_ACCOUNTANT : 'Contract Accountant',
    ENGINEER : 'Engineer',
    CLIENT : 'Client',
    CONSULTANT : 'Consultant',
    VENDOR : 'Vendor',
    SUBCONTRACTOR : 'Subcontractor',
    CONTRACTOR : 'Contractor',
    OTHER : 'Other',
}


export const RoleListInt = {
    ADMIN :1,
    PROJECT_DIRECTOR : 2,
    PROJECT_LEADER: 3,
    COMMERCIAL_MANAGER : 4,
    CONTRACT_MANAGER : 5,
    DESIGNER : 6,
    CONSTRUCTION_MANAGER : 7,
    ACCOUNTING_TEAM : 8,
    CONTRACT_ACCOUNTANT : 9,
    ENGINEER : 10,
    CLIENT : 11,
    CONSULTANT : 12,
    VENDOR : 13,
    SUBCONTRACTOR : 14,
    CONTRACTOR : 15,
    OTHER : 16,
}

export const StageActionList = {
    PENDING : 1,
    IN_PROGRESS : 2,
    COMPLETED : 3,
    SKIPPED  :4,
    CANCELED  :5
}

export const StageActionObj = [
    {value : 1, title : 'Pending'},
    {value : 2, title : 'In Progress'},
    {value : 3, title : 'Completed'},
    {value : 4, title : 'Skipped'},
    {value : 5, title : 'Canceled'}
]

export const CompanyTypeList = [
    { id : 1, value: 'MMoser'},
    { id : 2, value: i18nTranslate('Client')},
    { id : 3, value: i18nTranslate('Consultant')},
    { id : 4, value: i18nTranslate('Contractor')},
    { id : 5, value: i18nTranslate('Subcontractor')},
    { id : 6, value: i18nTranslate('Vendor')},
    { id : 7, value: i18nTranslate('Landlord')},
]

export const CompanyType = {
    MMOSER : 1,
    CLIENT : 2,
    CONSULTANT : 3,
    CONTRACTOR : 4,
    SUBCONTRACTOR : 5,
    VENDOR : 6,
    LANDLORD : 7,
}

export const LandMeasurements = [
    'm2', 'ft2', 'ping'
]

export const ProcessStageTypes = ['Review', 'Approval']

export const OfficeList = [
    'Amsterdam','Bangalore',
    'Beijing','Chengdu','Delhi',
    'Guangzhou','Hong Kong','Kuala Lumpur',
    'London','Manila',
    'Melbourne','Mumbai',
    'New York','Paris',
    'San Francisco','Shanghai',
    'Shenzhen','Singapore',
    'Taipei','Toronto','Vancouver'
]

export const CountryList = [
    'Australia','China',
    'France','Hong Kong',
    'India','Malaysia',
    'Netherlands','North America',
    'Philippines','Singapore',
    'Taipei','UK'
]


export const LogActionIcons = {
    'LOG IN' : {icon : 'fa-solid fa-right-to-bracket', color : 'info'},
    'LOG OUT' : {icon : 'fa-solid fa-right-from-bracket', color : 'info'},
    'CREATE' : {icon : 'fa-solid fa-floppy-disk', color : 'success'},
    'UPDATE': {icon : 'fa-solid fa-pen-to-square', color : 'primary'},
    'DELETE' : {icon : 'fa-solid fa-trash', color : 'warning'},
    'READ' : {icon : 'fa-solid fa-eye', color : 'info'},
    'ARCHIVE' : {icon : 'fa-solid fa-box-archive', color : 'danger'},
    'ADD TO DIRECTORY' : {icon : 'fa-solid fa-solid fa-floppy-disk', color : 'success'},
    'REMOVE TO DIRECTORY' : {icon : 'fa-solid fa-trash', color : 'danger'}, 
    'GRANT ADMIN ACCESS' : {icon : 'fa-solid fa-lock-open', color : 'danger'}, 
    'REVOKE ADMIN ACCESS' : {icon : 'fa-solid fa-lock', color : 'success'}, 
    'UPDATE BOQ INFO' : {icon : 'fa-solid fa-pen-to-square', color : 'primary'}, 
    'UPDATE BOQ ITEMS' : {icon : 'fa-solid fa-pen-to-square', color : 'primary'},
    'CHANGE USER PERMISSION' :  {icon : 'fa-solid fa-cogs', color : 'primary'},
    'SET FOR APPROVAL (BOQ)' :  {icon : 'fa-solid fa-file-pen', color : 'primary'},
    'SET FOR REVIEW (BOQ)' :  {icon : 'fa-solid fa-file-pen', color : 'primary'},
    'SET FOR APPROVAL (QUOTATION)' :  {icon : 'fa-solid fa-file-pen', color : 'success'},
    'PUBLISH QUOTATION' :  {icon : 'fa-solid fa-file-pen', color : 'success'},
    'CREATE RATE TEMPLATE' :  {icon : 'fa-solid fa-floppy-disk', color : 'success'},
    'UPDATE RATE TEMPLATE' :  {icon : 'fa-solid fa-pen-to-square', color : 'primary'},
}

export const LinkList = {
    SUMMARY : 1,
    DIRECTORY : 2,
    QUOTATIONS : 3,
    PO : 4,
    DOCUMENTS : 5,
    MAIN_DASHBOARD : 6,
    MAIN_COMPANY : 7,
    MAIN_COMPANY_USER : 8,
    MAIN_COMPANY_OFFICE : 9,
    MAIN_COMPANY_REGION : 10,
    PROJECT_DETAILS : 11,
    PROJECT_DIRECTORY : 12,
    PROJECT_PROCESS : 13,
    BOQ_MASTER_LIST : 14,
    COST_CODES : 15,
    APPROVAL_LIST : 16,
    CONSOLIDATED_LIST : 17,
    TRADE_PACKAGE_LIST : 18,
    BILL_OF_QUANTITIES : 19,
    BOQ_RATE_LIST : 20,
    QUOTATIONS_LIST : 21
}

export const ModuleActionList =  {
    NONE : 0,
    PROJECT_LOGS_VIEW : 1,
    PROJECT_DETAILS_EDIT : 2,
    DIRECTORY_VIEW : 3,
    DIRECTORY_CREATE :4,
    DIRECTORY_EDIT :5,
    DIRECTORY_REMOVE : 6,
    DIRECTORY_PERMISSION : 7,
    PROCESS_VIEW : 8,
    PROCESS_CREATE : 9,
    PROCESS_EDIT : 10,
    ITEM_MASTER_LIST_VIEW : 11,
    ITEM_MASTER_LIST_CREATE : 12,
    ITEM_MASTER_LIST_EDIT :13,
    ITEM_MASTER_LIST_REMOVE :14,
    BOQ_VIEW : 15,
    BOQ_CREATE : 16,
    BOQ_EDIT : 17,
    BOQ_ITEM_REMOVE : 18,
    BOQ_DUPLIDATE : 19,
    BOQ_HISTORY_VIEW : 20,
    BOQ_PUBLISH : 21,
    CONSOLIDATED_LIST_VIEW : 22,
    TRADE_PACKAGE_VIEW : 23,
    TRADE_PACKAGE_CREATE : 24,
    TRADE_PACKAGE_EDIT : 25,
    BOQ_QUOTATION_VIEW : 26,
    BOQ_QUOTATION_CREATE : 27,
    BOQ_QUOTATION_EDIT : 28,
    BOQ_QUOTATION_DELETE : 34,
    BOQ_QUOTATION_PUBLISH : 29,
    RATE_VIEW : 30,
    RATE_CREATE : 31,
    RATE_EDIT : 32,
    RATE_REMOVE : 33
}

export const ApprovalStageBoqObj = [
    {
        name : i18nTranslate('DRAFT'), 
        color : 'bg-primary text-white'
    },
    {
        name : i18nTranslate('PENDING'), 
        color : 'bg-info text-white'
    },
    {
        name : i18nTranslate('REVIEWED'), 
        color : 'bg-warning text-white'
    },
    {
        name : i18nTranslate('FOR APPROVAL'), 
        color : 'bg-info text-white'
    },
    {
        name : i18nTranslate('APPROVED'), 
        color : 'bg-success text-white'
    },
    {
        name : i18nTranslate('FOR REVISION'), 
        color : 'bg-danger text-white'
    },
    {
        name : i18nTranslate('SKIPPED'), 
        color : 'bg-light text-black'
    },
    {
        name : i18nTranslate('PUBLISHED'), 
        color : 'bg-success text-white'
    },
]


export const ModuleActionGroupings = [
    {
        module : 'Project Details',
        values : [ModuleActionList.PROJECT_LOGS_VIEW, ModuleActionList.PROJECT_DETAILS_EDIT],
        actions : [
            {
                id : ModuleActionList.PROJECT_LOGS_VIEW,
                name : 'View Logs'
            },
            {
                id : ModuleActionList.PROJECT_DETAILS_EDIT,
                name : 'Edit Project'
            },
        ]
    },
    {
        module : 'Project Directory',
        values : [
            ModuleActionList.DIRECTORY_VIEW, ModuleActionList.DIRECTORY_CREATE, ModuleActionList.DIRECTORY_EDIT,
            ModuleActionList.DIRECTORY_REMOVE, ModuleActionList.DIRECTORY_PERMISSION
        ],
        actions : [
            {
                id : ModuleActionList.DIRECTORY_VIEW,
                name : 'View Directory'
            },
            {
                id : ModuleActionList.DIRECTORY_CREATE,
                name : 'Add User to Directory'
            },
            {
                id : ModuleActionList.DIRECTORY_EDIT,
                name : 'Edit User from Directory'
            },
            {
                id : ModuleActionList.DIRECTORY_REMOVE,
                name : 'Remove User from Directory'
            },
            {
                id : ModuleActionList.DIRECTORY_PERMISSION,
                name : 'Change User permission'
            },
        ]
    },
    {
        module : 'Process List',
        values : [ModuleActionList.PROCESS_VIEW, ModuleActionList.PROCESS_CREATE,ModuleActionList.PROCESS_EDIT],
        actions : [
            {
                id : ModuleActionList.PROCESS_VIEW,
                name : 'View Process'
            },
            {
                id : ModuleActionList.PROCESS_CREATE,
                name : 'Add Process'
            },
            {
                id : ModuleActionList.PROCESS_EDIT,
                name : 'Edit Process'
            },
        ]
    },
    {
        module : 'Item Master List',
        values : [ModuleActionList.ITEM_MASTER_LIST_VIEW, ModuleActionList.ITEM_MASTER_LIST_CREATE,ModuleActionList.ITEM_MASTER_LIST_EDIT,ModuleActionList.ITEM_MASTER_LIST_REMOVE],
        actions : [
            {
                id : ModuleActionList.ITEM_MASTER_LIST_VIEW,
                name : 'View Item Master List'
            },
            {
                id : ModuleActionList.ITEM_MASTER_LIST_CREATE,
                name : 'Add Item to Master List'
            },
            {
                id : ModuleActionList.ITEM_MASTER_LIST_EDIT,
                name : 'Edit Item from Master List'
            },
            {
                id : ModuleActionList.ITEM_MASTER_LIST_REMOVE,
                name : 'Remove Item from Master List'
            },
        ]
    },
    {
        module : 'Bill of Quantities',
        values : [ModuleActionList.BOQ_VIEW, ModuleActionList.BOQ_CREATE,ModuleActionList.BOQ_EDIT, ModuleActionList.BOQ_ITEM_REMOVE,
            ModuleActionList.BOQ_DUPLIDATE, ModuleActionList.BOQ_HISTORY_VIEW, ModuleActionList.BOQ_PUBLISH],
        actions : [
            {
                id : ModuleActionList.BOQ_VIEW,
                name : 'View Bill of Quantities'
            },
            {
                id : ModuleActionList.BOQ_CREATE,
                name : 'Add Bill of Quantities'
            },
            {
                id : ModuleActionList.BOQ_EDIT,
                name : 'Edit Bill of Quantities'
            },
            {
                id : ModuleActionList.BOQ_ITEM_REMOVE,
                name : 'Remove Line Items from Bill of Quantities'
            },
            {
                id : ModuleActionList.BOQ_DUPLIDATE,
                name : 'Duplicate Bill of Quantities'
            },
            {
                id : ModuleActionList.BOQ_HISTORY_VIEW,
                name : 'View Bill of Quantities History'
            },
            {
                id : ModuleActionList.BOQ_PUBLISH,
                name : 'Publish Bill of Quantities'
            },
        ]
    },
    {
        module : 'Consolidated List',
        values : [ModuleActionList.CONSOLIDATED_LIST_VIEW],
        actions : [
            {
                id : ModuleActionList.CONSOLIDATED_LIST_VIEW,
                name : 'View Consolidated List'
            },
        ]
    },
    {
        module : 'Quotations',
        values : [ModuleActionList.BOQ_QUOTATION_VIEW, ModuleActionList.BOQ_QUOTATION_CREATE, ModuleActionList.BOQ_QUOTATION_EDIT, ModuleActionList.BOQ_QUOTATION_PUBLISH],
        actions : [
            {
                id : ModuleActionList.BOQ_QUOTATION_VIEW,
                name : 'View Quotation'
            },
            {
                id : ModuleActionList.BOQ_QUOTATION_CREATE,
                name : 'Add Quotation'
            },
            {
                id : ModuleActionList.BOQ_QUOTATION_EDIT,
                name : 'Edit Quotation'
            },
            {
                id : ModuleActionList.BOQ_QUOTATION_DELETE,
                name : 'Delete Quotation'
            },
            {
                id : ModuleActionList.BOQ_QUOTATION_PUBLISH,
                name : 'Publish Quotation'
            }
        ]
    },
    {
        module : 'Rate List',
        values : [ModuleActionList.RATE_VIEW,ModuleActionList.RATE_CREATE,ModuleActionList.RATE_EDIT,ModuleActionList.RATE_REMOVE],
        actions : [
            {
                id : ModuleActionList.RATE_VIEW,
                name : 'View Rate List'
            },
            {
                id : ModuleActionList.RATE_CREATE,
                name : 'Add Rate Templates'
            },
            {
                id : ModuleActionList.RATE_EDIT,
                name : 'Edit Rate Templates'
            },
            {
                id : ModuleActionList.RATE_REMOVE,
                name : 'Remove Rate Templates'
            },
        ]
    },
    {
        module : 'Trade Packages',
        values : [ModuleActionList.TRADE_PACKAGE_VIEW, ModuleActionList.TRADE_PACKAGE_CREATE, ModuleActionList.TRADE_PACKAGE_EDIT],
        actions : [
            {
                id : ModuleActionList.TRADE_PACKAGE_VIEW,
                name : 'View Trade Package'
            },
            {
                id : ModuleActionList.TRADE_PACKAGE_CREATE,
                name : 'Add Trade Package'
            },
            {
                id : ModuleActionList.TRADE_PACKAGE_EDIT,
                name : 'Edit Trade Package'
            }
        ]
    },
]

export const ProcessStepType =  {
    VIEW : 1,
    APPROVAL :2
}
export const ProcessStepTypeString =  [
    'REVIEW',
    'APPROVAL'
]

export const ProcessActionText = ['Pending', 'Viewed', 'Approved', 'Disapproved', 'Skipped']

export const NumberFormatterWidget = (value,sign = "") =>{
    if(!value){
        return 0
    }

    const absoluteValue = Math.abs(value)
    let returnValue = 0
    returnValue =  Number(parseFloat(absoluteValue).toFixed(2)).toLocaleString('en',{minimumFractionDigits: 2})
    return value > 0 ? sign + returnValue : "-" + sign + returnValue
     
        
}

export const IsValidEmail = (value)=>{
    if(!value){
        return false
    }
    return value.match(
        /^\S+@\S+\.\S+$/
      )
}



export const ContractType = ['',i18nTranslate("Closed Book"),i18nTranslate('Open Book'), i18nTranslate('Consultancy')]
export const ContractTypeObj = [
    { id : 1, value : i18nTranslate("Closed Book")},
    { id : 2, value : i18nTranslate("Open Book")},
    { id : 3, value : i18nTranslate("Consultancy")},
]


export const ReformatDate = (value, format = "DD-MMMM-YYYY", utc = false) => {
    if(!value){
        return ""
    }

    let getLocale = window.localStorage.getItem(LocaleNameVariable) || 'en'
    if(!utc){
        if (getLocale != 'en') {
            if (format == "DD-MMMM-YYYY") {
                return dayjs(value).format("YYYY年M月D日")
            } else {
                return dayjs(value).format(format)
            }
        }
        else {
            return dayjs(value).format(format)
        }
    }else{
        return dayjs.utc(value).format(format)
    }
    
}
export const ReformatNumber = (value, min =2, max =2) => {
    if(!value){
        if(min==0){
            return '0'
        }
        return '0.00'
    }

    const data = Math.abs(value).toLocaleString(undefined,{minimumFractionDigits : min, maximumFractionDigits : max}) 

    if(value<0)
        return '(' + data + ')'
    else
        return data

}

export const CleanSharePointFilename = (filename) => {
    // Define a regex pattern to match invalid characters
    const invalidCharsPattern = /[\\/:*?"<>|]/g;

    // Replace invalid characters with underscores
    const cleanedFilename = filename.replace(invalidCharsPattern, '_');

    return cleanedFilename;
}
