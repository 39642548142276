// @ is an alias to /src
import ProjectItem from '@/components/ProjectItem.vue'
import Modal from '@/components/layout/Modal.vue'
import Pagination from '@/components/layout/Pagination.vue'
import Select2 from '@/components/layout/Select2.vue'
import AutocompleteSelect from '@/components/layout/AutocompleteSelect.vue'
import Loader from '@/components/layout/Loader.vue'
import DatePicker from '@/components/layout/DatePicker.vue'
import { GetProjectFromProjectNo, GetMyProjects, AddProject, GetProject,UpdateProject,UpdateProjectStatus,ValidateUserFromDirectory, GetCurrencyList,SearchCompanyData } from '@/actions/projectActions.js'
import { SearchUserDirectory,SearchOfficeDirectory, SearchRegionDirectory } from '@/actions/directoryActions.js'
import { RoleListInt, LinkList, ContractTypeObj, LandMeasurements } from '@/helpers/utilities.js'
import { GetUserByName,GetOfficeByName } from '@/actions/userActions.js'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import 'dotenv/config'

export default {
  name: 'Home',
  data (){
    return {
      projectList : [], 
      userProject : {
        docs: [],
        totalPages : 0,
        totalDocs : 0
      },
      officeList : [],
      userListManager : [],
      userListLeader : [],
      timer: null,
      searchParams : {
        search : '',
        officeId : '',
        regionId : '',
        createdBy : '',
        clientId : '',
        page : 1
      },
      projectLoading : false,
      projectItemInfoId : '',
      projectItemInfo :{
        currency : '',
        contractType : '',
        sf : 0,
        sm: 0
      },
      projectDirectory : [],
      directoryLoading : false,
      projectItemInfoUpdate :{},
      saveErrorMessage : '',
      updateErrorMessage : '',
      arrayValidator : [],
      directory :{
        office : [],
        region : []
      },
      currencyList : [],
      ContractTypeObj : ContractTypeObj,
      LandMeasurements : LandMeasurements,
      ClientCompanyList : [],

    }
  },
  mounted : async function () {
    const self = this
    
    await self.RenderProjects()
    self.ChangeActiveLink(LinkList.MAIN_DASHBOARD)
    self.currencyList =  await GetCurrencyList().catch()
    
    self.$store.commit('setProjectInfo',{
      stage : 1,
      statusIcon :{
          name : '',
          icon : ''
      },
      stageProcess : []
  })

    
  },
  components: {
    ProjectItem,
    Modal,
    Pagination,
    Select2,
    Loader,
    DatePicker,
    AutocompleteSelect
  },
  methods : {
    ...mapActions(['ChangeActiveLink']),
    timerSetter(typed, callback, seachEmpty = false){
      const self = this
      if(!seachEmpty){
        if(!typed){
          return
        }
      }
      
      if(self.timer){
        clearTimeout(self.timer)
        self.timer = null
      }

      self.timer = setTimeout(callback,700)
    },
    timerSetterGeneral(callback){
      const self = this
      
      if(self.timer){
        clearTimeout(self.timer)
        self.timer = null
      }

      self.timer = setTimeout(callback,700)
    },
    AppendDataOfCompany(value){
      const self = this
      self.projectItemInfo.client_company_address = value.address
      self.projectItemInfo.company_phone = value.phone
      self.projectItemInfo.company_email = value.email
    },
    OnClientSearchClear(){
      const self = this
      self.projectItemInfo.client_company_address = ''
      self.projectItemInfo.company_phone = ''
      self.projectItemInfo.company_email = ''
    },
    SearchCompanyData(search){

      return SearchCompanyData(search)
      // const self = this

      // if(!search){
      //   self.ClientCompanyList = []
      //   return
      // }

      // if(search.length<2){
      //   self.ClientCompanyList = []
      //   return 
      // }
      // self.timerSetterGeneral(function(){
      //   SearchCompanyData(search).then((res)=>{
      //     self.ClientCompanyList = res
      //   })
      // })
    },
    SeachProject(){
      const self = this
      self.timerSetter(self.searchParams.search,function(){
        self.RenderProjects()
      }, true)
    },
    GetProjectAction(typed){
      return GetProjectFromProjectNo(typed)
    },
    GetOffice(typed){
      const self = this
      self.timerSetter(typed,function(){
        GetOfficeByName(typed).then((res)=>{
          self.officeList = res
        })
      }, true)
    },
    SfChange(){
      //change square meter field on change
      this.projectItemInfo.sm = Math.ceil(this.projectItemInfo.sf / 10.764)
    },
    SmChange(){
      //change square meter field on change
      this.projectItemInfo.sf = Math.ceil(this.projectItemInfo.sm * 10.764)
    },
    AppendProjectInfo(obj){
      const self = this
      if(obj){
        self.projectItemInfo = obj
        self.projectItemInfo.projectNo = obj.projectNo.trim()
        self.projectItemInfo.projectName = obj.projectName ? obj.projectName.trim() : ""
        self.projectItemInfo.projectAddress = obj.projectLocation ? obj.projectLocation.trim() : ""
        self.projectItemInfo.projectManager = obj.projectManager ? obj.projectManager.trim().match(/[A-Z][a-z]+|[0-9]+/g).join(" ") : ""
        self.projectItemInfo.projectLeader = obj.projectLeader ? obj.projectLeader.trim().match(/[A-Z][a-z]+|[0-9]+/g).join(" ") : ""
        self.projectItemInfo.officeId = obj.officeId ? obj.officeId.trim().match(/[A-Z][a-z]+|[0-9]+/g).join(" ") : ""
        self.projectItemInfo.country = obj.country ? obj.country.trim().match(/[A-Z][a-z]+|[0-9]+/g).join(" ") : ""
        self.projectItemInfo.projectOldId = obj.uProjectid.trim()
        self.projectItemInfo.projectConfirmDate = isNaN(Date.parse(obj.projectConfirmDate))==false ? dayjs(obj.projectConfirmDate).format("YYYY-MM-DD") : ""
        self.projectItemInfo.projectStartDate = isNaN(Date.parse(obj.projectStartDate))==false ? dayjs(obj.projectStartDate).format("YYYY-MM-DD") : ""
        self.projectItemInfo.projectHandOverDate = isNaN(Date.parse(obj.projectHandOverDate))==false ? dayjs(obj.projectHandOverDate).format("YYYY-MM-DD") : ""
        self.projectItemInfo.currency = ''
        self.projectItemInfo.contractType = ''
        self.projectItemInfo.client_company=  ''
        // self.projectItemInfo.cType = ContractTypeObj.find(u=>u.value.toLowerCase()==self.projectItemInfo.cType.toLowerCase().trim()).id || ''
        // self.projectItemInfo.cType = self.projectItemInfo.cType
        self.projectItemInfo.size = obj.sm
        self.projectItemInfo.unit_of_measure = 'm2'
        self.projectItemInfo.version = obj.version
        self.directoryLoading = true
        ValidateUserFromDirectory(obj.directory).then((res)=>{
          self.projectDirectory = res
          self.directoryLoading = false
        }).catch(()=>{ self.directoryLoading = false })
        // self.projectItemInfo.teamInfo = self.projectItemInfo.teamInfo.trim()
      }
      
    },
    CreateProject(btn){
      const self = this
      self.saveErrorMessage = ''
      
      const data  = self.projectItemInfo
      self.arrayValidator = []
      let valid = true
      if(!data.projectNo){
        self.arrayValidator.push("c1")
        valid = false
      }

      if(!data.projectName){
        self.arrayValidator.push("c2")
        valid = false
      }

      if(!data.projectAddress){
        self.arrayValidator.push("c3")
        valid = false
      }

      if(!data.currency){
        self.arrayValidator.push("c4")
        valid = false
      }

      if(!data.officeId){
        self.arrayValidator.push("c5")
        valid = false
      }

      if(!data.contractType){
        self.arrayValidator.push("c6")
        valid = false
      }

      if(!data.client_company_address){
        self.arrayValidator.push("c7")
        valid = false
      }

      if(!data.contractType){
        self.arrayValidator.push("c8")
        valid = false
      }

      
      if(!data.client_company){
        self.arrayValidator.push("c9")
        valid = false
      }
    
      if(!valid){
        return
      }

      btn.disabled = true
      GetProjectFromProjectNo(self.projectItemInfo.projectNo, true).then((res)=>{
          if(!res){
            btn.disabled = false
            self.saveErrorMessage = "Project Number Does not Exist"
          }else{
            self.projectItemInfo.directory = self.projectDirectory
            AddProject(self.projectItemInfo).then((res)=>{
              btn.disabled = false
              if(res.status==200){
                // self.$refs.closeAddModal.click()
                // self.RenderProjects()
                // self.$router.push({ name : 'Project', params : {id : res.data}})
                //now redirect to sharepoint authentication
                location.href = process.env.VUE_APP_REST_MAIN_API + '/api/auth/token/email?type=2&returnUrl='+encodeURIComponent(process.env.VUE_APP_PROJECT_URL + '/project/' + res.data)
              }
              else{
                self.saveErrorMessage = res
              } 
            })
          }
      })
    },
    RenderProjects(page = 1){
      const self = this
      self.searchParams.page = page
      self.projectLoading = true
      GetMyProjects(self.searchParams).then((res)=>{
        self.projectLoading = false
        self.userProject = res
      })
    },
    AdvanceFilterClick(e){
      let btn = e.target
      const self = this
      btn.disabled =true
      self.projectLoading = true
      self.searchParams.page = 1
      GetMyProjects(self.searchParams).then((res)=>{
        btn.disabled =false
        self.projectLoading = false
        self.$refs.closeSearchModal.click()
        self.userProject = res
        
      })
    },
    SearchUserByName(search, list){
      const self = this

      self.timerSetter(search,function(){
        GetUserByName(search, list==1 ? RoleListInt.PROJECT_MANAGER : RoleListInt.PROJECT_LEADER).then((res)=>{
          if(list==1){
            self.userListManager = res
          }else{
            self.userListLeader = res
          }
        })
      })
     
    },
    OpenUpdate(id){
      const self = this
      self.$refs.updateFieldSet.disabled = true
      GetProject(id).then((res)=>{
        self.projectItemInfoUpdate = res
        self.$refs.updateFieldSet.disabled = false
      })

    },
    UpdateProject(btn){
      const self = this
      self.updateErrorMessage = ""
      const data  = self.projectItemInfoUpdate
      self.arrayValidator = []
      let valid = true
      if(!data.projectNumber){
        self.arrayValidator.push("u1")
        valid = false
      }

      if(!data.projectName){
        self.arrayValidator.push("u2")
        valid = false
      }
      
      if(!data.projectAddress){
        self.arrayValidator.push("u3")
        valid = false
      }

      if(!valid){
        return
      }

      btn.disabled = true
      GetProjectFromProjectNo(self.projectItemInfoUpdate.projectNumber, true).then((res)=>{
        if(!res){
          btn.disabled = false
          self.updateErrorMessage = "Project Number Does not exist"
        }else{
          UpdateProject(self.projectItemInfoUpdate).then((res)=>{
            btn.disabled = false
            if(res=="OK"){  
              self.$refs.closeUpdateModal.click()
              self.RenderProjects()
            }else{
              self.updateErrorMessage = res
            }
          })
        }
        
      })

      
    },
    UpdateProjectStatus(id){
      const self = this
      self.$swal({
        title: 'Archive Project?',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Archive',
        showLoaderOnConfirm : true,
        allowOutsideClick: () => !self.$swal.isLoading(),
        preConfirm : ()=>{
          return UpdateProjectStatus(id).then(()=>{
            
          })
        }
      }).then((res)=>{
        if(res.isConfirmed){
          self.$swal('Project Archived', '', 'success')
        }
      })
    },
    PageChange(page){
      this.RenderProjects(page)
    },
    OpenModalCreate(){
      this.projectItemInfo={
        projectNo : '',
        currency : '',
        contractType : '',
        unit_of_measure : 'm2',
        sf : 0,
        sm: 0
      } 
      this.projectItemInfoId = ''
      this.saveErrorMessage=''
      this.projectDirectory = []
    },
    RemoveFromDirectory(name){
      const getIndex = this.projectDirectory.findIndex(u=>u.name==name)
      this.projectDirectory.splice(getIndex,1)
    },
    OpenFilterModal(){
      const self = this
      if(self.directory.office.length==0){
        SearchOfficeDirectory().then((res)=>{
          self.directory.office = res
        }).catch()
        SearchRegionDirectory().then((res)=>{
          self.directory.region = res
        }).catch()
      }
    },
    SearchSpecificUser(search){
      return SearchUserDirectory(search)
    },
    SearchSpecificClient(search){
      return SearchUserDirectory(search,RoleListInt.CLIENT)
    }
    
    
  },
}