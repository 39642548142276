<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : $t('Projects'),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id+ '/details',
                title : $t('Details'),
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div class="">
        <StepItem2></StepItem2>
    </div>
    <div class="row border-top">
        <Loader v-if="loaderProject"></Loader>
        <template v-else>
            <div class="col-md-12">
                <ProjectViewOnly
                v-if="viewMode"
                :ChangeView="ChangeView">
                </ProjectViewOnly>
                <ProjectGeneralInfo
                v-else
                :ChangeView="ChangeView"
                :ContractTypeObj="ContractTypeObj"
                :LandMeasurements="LandMeasurements"
                :currencyList="currencyList"
                ></ProjectGeneralInfo>
            </div>
            <div class="col-md-12 pt-2">
                <!-- <ActivitiesProject v-if="logsList!=401" ></ActivitiesProject> -->
            </div>
        </template>
        
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ProjectGeneralInfo from '../components/projectInformation/ProjectGeneralInfo.vue'
import ProjectViewOnly from '../components/projectInformation/ProjectViewOnly.vue'
import BreadcrumbsProject from '../components/projectInformation/BreadcrumbsProject.vue'
// import ActivitiesProject from '@/components/projectInformation/ActivitiesProject.vue'
import Loader from '../components/layout/Loader.vue'
import StepItem2 from '../components/layout/StepItem2.vue'
import { stageIcons, LinkList, ContractTypeObj, LandMeasurements } from '../helpers/utilities.js'
import {GetCurrencyList} from '@/actions/projectActions.js' 
import 'dotenv/config'
export default {
    name : 'ProjectDetails',
    components : {
        Loader,
        StepItem2,
        BreadcrumbsProject,
        ProjectGeneralInfo,
        ProjectViewOnly,
        // ActivitiesProject
    },
    data(){
        return{
            loaderProject: true,
            stages : stageIcons,
            viewMode : true, 
            ContractTypeObj : ContractTypeObj,
            LandMeasurements : LandMeasurements,
            currencyList : []
        }
    },
    methods :{
        ...mapActions(['GetProjectInfo','GetProjectDirectory','ChangeActiveLink','GetProjectLogList']),
        ChangeView(){
            this.viewMode = !this.viewMode
        }
    },
    computed : mapGetters(['projectInfo','projectUpdateObject','logsList']),
    mounted :async function () {
        const self = this
        self.ChangeActiveLink(LinkList.PROJECT_DETAILS)
        const projectId = self.$route.params.id
        self.loaderProject = true
        await self.GetProjectInfo(projectId).catch()
        // await self.GetProjectLogList().catch()
        self.currencyList =  await GetCurrencyList().catch()
        self.loaderProject = false
        
    }
}
</script>