import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API + '/api/directory'
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true


export const SearchUserDirectory = async (search ="", role ="", companyType = "") =>{
    return axios.get(`${MAIN_API}/search/user`
    ,{
        params : {
            search : search,
            role : role,
            companyType : companyType
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const GetUserProjectDirectory = async (id) =>{
    return axios.get(`${MAIN_API}/search/user/project/directory/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const SearchUserFromProjectDirectory = async (search ="", role ="", projectId = "") =>{
    return axios.get(`${MAIN_API}/search/user/project`
    ,{
        params : {
            search : search,
            role : role,
            projectId : projectId
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const SearchOfficeDirectory  = async () =>{
    return axios.get(`${MAIN_API}/search/office`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const SearchRegionDirectory  = async () =>{
    return axios.get(`${MAIN_API}/search/region`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}


export const SearchRolesDirectory  = async () =>{
    return axios.get(`${MAIN_API}/search/roles`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const SearchUomDirectory  = async () =>{
    return axios.get(`${MAIN_API}/search/uom`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const SearchCompanies = async (data) => {
    return axios.get(`${MAIN_API}/search/company`,
    {
        params : {
            search :  data.search || '',
            officeId : data.officeId || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetCompanyTypes = async () => {
    return axios.get(`${MAIN_API}/search/company/types`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}