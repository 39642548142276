import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Auth from '../middleware/auth.js'
import Redirect from '../views/Redirect.vue'
import Design from '../views/Design.vue'
import Overview from '../views/Overview.vue'
import ProjectDetails from '../views/ProjectDetails.vue'
import ProjectDirectory from '../views/ProjectDirectory.vue'
import ProcessList from '../views/ProcessList.vue'
import ProcessSetup from '../views/ProcessSetup.vue'
import ApprovalsList from '../views/ApprovalsList.vue'
import Unauthorized from '../views/Unauthorized.vue'
import NotificationList from '../views/NotificationList.vue'
import ActionableList from '../views/ActionableList.vue'
import SignOut from '../views/SignOut.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id',
    name: 'Project',
    component: Overview,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/notification',
    name: 'NotificationList',
    component: NotificationList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/actionables',
    name: 'ActionableList',
    component: ActionableList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/details',
    name: 'Overview',
    component: ProjectDetails,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/directory',
    name: 'Directory',
    component: ProjectDirectory,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/process/:processId',
    name: 'ProcessSetup',
    component: ProcessSetup,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/process',
    name: 'ProcessList',
    component: ProcessList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Project/:id/approvals',
    name: 'ApprovalList',
    component: ApprovalsList,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Redirect,
  },
  {
    path: '/test/design',
    name: 'Design',
    component: Design,
  },
  {
    path: '/Unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  {
    path: '/Signout',
    name: 'SignOut',
    component: SignOut,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function nextFactory (context, middleware, index){
  const subsequentMiddleware = middleware[index]

  if(!subsequentMiddleware){
    return context.next
  }

  return(...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({...context, nextMiddleware})
  }
}

router.beforeEach((to,from,next) =>{
  if(to.meta.middleware){
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = {from, next, router, to}
    const nextMiddleware = nextFactory(context,middleware,1)
    return middleware[0]({...context, nextMiddleware})
  }

  return next()
})

export default router
