<template>
    <BreadcrumbsProject
        :breadcrumbs="[
            {
                link : '/',
                title : $t('Projects'),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id,
                title : (projectInfo.projectNumber || '-') +' ' + (projectInfo.projectName || ''),
                isRouterLink: true
            },
            {
                link : '/Project/' + projectInfo._id + '/directory' ,
                title : $t('Directory'),
                isRouterLink: true
            }
        ]"
    ></BreadcrumbsProject>
    <div>
        <StepItem2></StepItem2>
    </div>
    <div >
        <Loader v-if="loaderProject"></Loader>  
        <DirectoryInfo :openPermission="OpenPermissionModal" v-else></DirectoryInfo>
    </div>
    <Modal idModal="PermissionModal" :headerTitle="$t('Change User Permission')">
        <div>
            <table class="table">
                <tbody>
                    <template v-for="(group,key) in ModuleActionGroupings" :key="key">
                        <tr>
                            <td colspan="2" @click="CLickGroupLabel(key,group.values)" class="border-bottom-0 bg-primary text-white">
                                <input type="checkbox" class="me-1"  v-model="selectedGroup" :value="key" /> 
                                <label class=" fs-6">{{$t(group.module) }}</label>
                            </td>
                        </tr>
                        <tr v-for="(action,actionKey) in  group.actions" :key="actionKey" @click="ClickTr(action.id)">
                            <td><label class="ms-2">{{$t(action.name)}}</label></td>
                            <td><input type="checkbox" class="ms-5" :value="action.id"  v-model="selectedPermission" /> </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closePermissionModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="UpdateUserAccess"  ><i class="fa-solid fa-save"></i>  {{ $t('Save Changes') }}</button>
      </template>
    </Modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Modal from '@/components/layout/Modal.vue'
import BreadcrumbsProject from '../components/projectInformation/BreadcrumbsProject.vue'
import DirectoryInfo from '../components/projectInformation/DirectoryInfo.vue'
import Loader from '../components/layout/Loader.vue'
import StepItem2 from '../components/layout/StepItem2.vue'
import { LinkList,ModuleActionGroupings } from '../helpers/utilities.js'
import {UpdateUserProjectAccess} from '../actions/projectActions.js'
import 'dotenv/config'
import  i18n from '../translations/i18n.js'
const i18nTranslate = i18n.global.t

export default {
    name : 'ProjectDetails',
    components : {
        Loader,
        StepItem2,
        BreadcrumbsProject,
        DirectoryInfo,
        Modal
    },
    data(){
        return{
            loaderProject: true,
            viewMode : true,
            ModuleActionGroupings : ModuleActionGroupings,
            selectedUser : '',
            selectedPermission : [],
            selectedGroup : []
        }
    },
    methods :{
        ...mapActions(['GetProjectInfo','GetProjectDirectory','ChangeActiveLink']),
        OpenPermissionModal(data){
            this.selectedUser = data._id
            this.selectedGroup = []
            this.selectedPermission = []
            if(data.accessList)
                if(data.accessList.length!=0)
                    this.selectedPermission.push(...data.accessList)
        },
        CLickGroupLabel(key, value){
            const self = this
            const getIndex = this.selectedGroup.indexOf(key)
            let isAdded = false
            if(getIndex==-1){
                this.selectedGroup.push(key)
                isAdded = true
            }else{
                this.selectedGroup.splice(getIndex,1)
            }
            self.selectedPermission = self.selectedPermission.filter(u=>value.indexOf(u)==-1)
            if(isAdded){
                self.selectedPermission.push(...value)
            }
        },
        ClickTr(value){
            const getIndex = this.selectedPermission.indexOf(value)
            if(getIndex==-1){
                this.selectedPermission.push(value)
            }else{
                this.selectedPermission.splice(getIndex,1)
            }
        },
        UpdateUserAccess(e){
            const self = this
            const btn = e.target
            btn.disabled =true
            UpdateUserProjectAccess(self.selectedUser,self.selectedPermission).then((res)=>{
                btn.disabled =false
                if(res!='OK'){
                    self.$swal(i18nTranslate('Change User permission'), res, 'warning')
                }else{
                    self.GetProjectDirectory(1).then()
                    self.$refs.closePermissionModal.click()
                }
            })
        }
    },
    computed : mapGetters(['projectInfo']),
    mounted :async function () {
        const self = this
        self.ChangeActiveLink(LinkList.PROJECT_DIRECTORY)
        const projectId = self.$route.params.id
        self.loaderProject = true
        await self.GetProjectInfo(projectId).catch()
        const directory = await self.GetProjectDirectory().catch()
        if(directory==401){
            self.$router.push('/unauthorized')
        }
        self.loaderProject = false
        
    }
}
</script>